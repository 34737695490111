import React, { useEffect, useState } from 'react'
import { PieChart } from '@mui/x-charts/PieChart';
import { Box} from '@mui/material';
import 'tailwindcss/tailwind.css';
import './TaskReport.css'
import MUIDataTable from "mui-datatables";
import { createTheme } from "@mui/material/styles"
import { ThemeProvider } from '@mui/styles';
import { styled } from '@mui/material/styles';
import Switch from '@mui/material/Switch';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import AddTaskReport from './AddTaskReport';
function TaskReport() {

    const [users, setUsers] = useState([])
 
    const AntSwitch = styled(Switch)(({ theme }) => ({
        width: 28,
        height: 16,
        padding: 0,
        display: 'flex',
        '&:active': {
          '& .MuiSwitch-thumb': {
            width: 15,
          },
          '& .MuiSwitch-switchBase.Mui-checked': {
            transform: 'translateX(9px)',
          },
        },
        '& .MuiSwitch-switchBase': {
          padding: 2,
          '&.Mui-checked': {
            transform: 'translateX(12px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
              opacity: 1,
              backgroundColor: theme.palette.mode === 'dark' ? '#177ddc' : '#1890ff',
            },
          },
        },
        '& .MuiSwitch-thumb': {
          boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
          width: 12,
          height: 12,
          borderRadius: 6,
          transition: theme.transitions.create(['width'], {
            duration: 200,
          }),
        },
        '& .MuiSwitch-track': {
          borderRadius: 16 / 2,
          opacity: 1,
          backgroundColor:
            theme.palette.mode === 'dark' ? 'rgba(255,255,255,.35)' : 'rgba(0,0,0,.25)',
          boxSizing: 'border-box',
        },
      }));
    const columns = [
          {
              name:"",
              label:"Code"  // to rename column
          },
          {
              name:"",
              label:"Task"
          },
        {
            name:"",
            label:"Project"
          },
          {
            name:"",
            label:"Due Date"
          },
          {
            name:"",
            label:"Assigned To"
          },
          {
            name:"", //use this for status active or inactive
            label:"Status"
          },
        {
            name: "status",
            label: "Status",
            options: {
                customBodyRender: (value, tableMeta) => (
                 <Stack value={value} direction="row" spacing={1} alignItems="center" onChange={(event) => handleStatusChange(event, tableMeta.rowIndex)}>
        
        <AntSwitch defaultChecked inputProps={{ 'aria-label': 'ant design' }} />
        <Typography value="on">Complete</Typography>
      </Stack>   
                )
            }
        }
    ];

    const handleStatusChange = (event, rowIndex) => {
        const newUsers = [...users];
        newUsers[rowIndex].status = event.target.value;
        setUsers(newUsers);
    };

// const handleStatusChange = (event, rowIndex) => {
//     const { value } = event.target;
//     setUsers(prevUsers => {
//         const updatedUsers = [...prevUsers];
//         updatedUsers[rowIndex].status = value;
//         return updatedUsers;
//     });
// };

    useEffect(() => {
        fetch('https://dummyjson.com/users')
            .then(res => res.json())
            .then((data) => {
                let local = data?.users?.map((user) => ({
                    ...user,
                    name: user?.firstName + " " + user?.lastName,
                    // status: "inactive"
                }))
                console.log(local)
                setUsers(local);
            })
    })

    const options = {
          filterType: 'checkbox',
        //selectableRows: false,
        elevation: 0,
        rowsPerPage: 5,
        rowsPerPageOptions: [5, 10, 20, 30],
    };

    const getMuiTheme = () =>
        createTheme({
            typography: {
                fontFamily: "Poppins",
            },
            palette: {
                background: {
                    paper: "#1e293b",
                    default: "0f172a",
                },
                mode: "dark",
            },
            components: {
                MuiTableCell: {
                    styleOverrides: {
                        head: {
                            padding: "10px 4px",
                        },
                        body: {
                            padding: "7px 15px",
                            color: "#e2e8f0",
                        },
                    }
                }
            }
        })

    return (
        <div className='py-10 min-h-screen grid place-items-center'>
            <Box component="section" sx={{ p: 2, backgroundColor: "#121212" }}>
            <PieChart 
      series={[
        {
          data: [
            { id: 0, value: 10, label: 'Incomplete' },
            { id: 1, value: 15, label: 'To Do' },
            { id: 2, value: 20, label: 'Doing' },
            { id: 3, value: 30, label: 'Completed' },
          ],
        },
      ]}
      width={400}
      height={200}
      
    />

            </Box>
            <br></br>
            <AddTaskReport></AddTaskReport>
            <br></br>
            <div className='w-10/12 max-w-4xl'>
                <ThemeProvider theme={getMuiTheme()}>
                    <MUIDataTable
                        title={"Task Report List"}
                        data={users}
                        columns={columns}
                        options={options}
                    />
                </ThemeProvider>
            </div>

        </div>
    )
}

export default TaskReport;