import React, { useState } from 'react';
import { Sidebar, Menu, MenuItem, SubMenu } from 'react-pro-sidebar';
import { Box, IconButton, Typography, useTheme } from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import ApartmentIcon from '@mui/icons-material/Apartment';
import AppSettingsAltIcon from '@mui/icons-material/AppSettingsAlt';
import BusinessIcon from '@mui/icons-material/Business';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import PaymentIcon from '@mui/icons-material/Payment';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import GavelIcon from '@mui/icons-material/Gavel';
import AddCardIcon from '@mui/icons-material/AddCard';
import BugReportIcon from '@mui/icons-material/BugReport';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import PermIdentityIcon from '@mui/icons-material/PermIdentity';
import EmojiPeopleIcon from '@mui/icons-material/EmojiPeople';
import PointOfSaleIcon from '@mui/icons-material/PointOfSale';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import AddModeratorIcon from '@mui/icons-material/AddModerator';
import InventoryIcon from '@mui/icons-material/Inventory';
import WebAssetIcon from '@mui/icons-material/WebAsset';
import WorkIcon from '@mui/icons-material/Work';
import MeetingRoomIcon from '@mui/icons-material/MeetingRoom';
import BrowserUpdatedIcon from '@mui/icons-material/BrowserUpdated';

function SettingsSidebar() {
  const theme = useTheme(); // Access the current theme
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [selected, setSelected] = useState("");
  const location = useLocation();

  const handleMenuItemClick = (itemName) => {
    setSelected(itemName);
    if (isCollapsed) {
      setIsCollapsed(false); // Close the sidebar if it's collapsed
    }
  };

  return (
    <Box>
      <Sidebar
        collapsed={isCollapsed}
        onMouseEnter={() => setIsCollapsed(false)}
        onMouseLeave={() => setIsCollapsed(true)}
        style={{background:"#ffffff"}}
      >
        <Menu>
          <MenuItem
            onClick={() => setIsCollapsed(!isCollapsed)}
            icon={<MenuOutlinedIcon style={{ color: theme.palette.mode === 'light' ? 'black' : 'black' }} />} // Adjust icon color based on theme mode
            style={{ margin: "10px 0 20px 0", color: "#000000" }}
          >
            {!isCollapsed && (
              <Box display="flex" justifyContent="space-between" alignItems="center" ml="15px">
                <Typography  color="black">Admin Settings</Typography>
                <IconButton onClick={() => setIsCollapsed(!isCollapsed)}>
                  <MenuOutlinedIcon style={{ color: theme.palette.mode === 'light' ? 'black' : 'black' }} />
                </IconButton>
              </Box>
            )}
          </MenuItem>
          <Box paddingLeft={isCollapsed ? undefined : "10%"}>
          <MenuItem 
              style={{ 
                display: 'flex', 
                alignItems: 'center', 
                color: selected === 'Company_settings' ? 'white' : 'black', 
                backgroundColor: selected === 'Company_settings' ? '#C0B476' : 'transparent' ,
                marginLeft: '10px'
              }} 
              component={<Link to="/settings" />}
              onClick={() => handleMenuItemClick('Company_settings')}
            >
              <ApartmentIcon style={{ marginRight: '15px' }} />
              Company Settings
            </MenuItem>

            <hr></hr>

            <MenuItem 
              style={{ 
                display: 'flex', 
                alignItems: 'center', 
                color: selected === 'App_settings' ? 'white' : 'black', 
                backgroundColor: selected === 'App_settings' ? '#C0B476' : 'transparent' ,
                marginLeft: '10px'
              }} 
              component={<Link to="/App_settings" />}
              onClick={() => handleMenuItemClick('App_settings')}
            >
              <AppSettingsAltIcon style={{ marginRight: '15px' }} />
              App Settings
            </MenuItem>

            <hr></hr>

            <MenuItem 
              style={{ 
                display: 'flex', 
                alignItems: 'center', 
                color: selected === 'Business_settings' ? 'white' : 'black', 
                backgroundColor: selected === 'Business_settings' ? '#C0B476' : 'transparent' ,
                marginLeft: '10px'
              }} 
              component={<Link to="/Business_settings" />}
              onClick={() => handleMenuItemClick('Business_settings')}
            >
              <BusinessIcon style={{ marginRight: '15px' }} />
              Business Settings
            </MenuItem>

            <hr></hr>

            <MenuItem 
              style={{ 
                display: 'flex', 
                alignItems: 'center', 
                color: selected === 'Profile_settings' ? 'white' : 'black', 
                backgroundColor: selected === 'Profile_settings' ? '#C0B476' : 'transparent' ,
                marginLeft: '10px'
              }} 
              component={<Link to="/Profile_settings" />}
              onClick={() => handleMenuItemClick('Profile_settings')}
            >
              <AssignmentIndIcon style={{ marginRight: '15px' }} />
              Profile Settings
            </MenuItem>

            <hr></hr>

            {/* <MenuItem 
              style={{ 
                display: 'flex', 
                alignItems: 'center', 
                color: selected === 'Notification_Settings' ? 'white' : 'black', 
                backgroundColor: selected === 'Notification_Settings' ? '#C0B476' : 'transparent' ,
                marginLeft: '10px'
              }} 
              component={<Link to="/Notification_Settings" />}
              onClick={() => handleMenuItemClick('Notification_Settings')}
            >
              <NotificationsActiveIcon style={{ marginRight: '15px' }} />
              Notification Settings
            </MenuItem>

            <hr></hr> 

           <MenuItem 
              style={{ 
                display: 'flex', 
                alignItems: 'center', 
                color: selected === 'Payment_Settings' ? 'white' : 'black', 
                backgroundColor: selected === 'Payment_Settings' ? '#C0B476' : 'transparent' ,
                marginLeft: '10px'
              }} 
              component={<Link to="/payment_settings" />}
              onClick={() => handleMenuItemClick('Payment_Settings')}
            >
              <PaymentIcon style={{ marginRight: '15px' }} />
              Payment Settings
            </MenuItem>

            <hr></hr>

            <MenuItem 
              style={{ 
                display: 'flex', 
                alignItems: 'center', 
                color: selected === 'Finance_Settings' ? 'white' : 'black', 
                backgroundColor: selected === 'Finance_Settings' ? '#C0B476' : 'transparent' ,
                marginLeft: '10px'
              }} 
              component={<Link to="/Finance_Settings" />}
              onClick={() => handleMenuItemClick('Finance_Settings')}
            >
              <CurrencyRupeeIcon style={{ marginRight: '15px' }} />
              Finance Settings
            </MenuItem>*/}

            <hr></hr>

            <MenuItem 
              style={{ 
                display: 'flex', 
                alignItems: 'center', 
                color: selected === 'Leads_settings' ? 'white' : 'black', 
                backgroundColor: selected === 'Leads_settings' ? '#C0B476' : 'transparent' ,
                marginLeft: '10px'
              }} 
              component={<Link to="/Leads_settings" />}
              onClick={() => handleMenuItemClick('Leads_settings')}
            >
              <GavelIcon style={{ marginRight: '15px' }} />
              Leads Settings
            </MenuItem>

            <hr></hr> 


            <MenuItem 
              style={{ 
                display: 'flex', 
                alignItems: 'center', 
                color: selected === 'tax_settings' ? 'white' : 'black', 
                backgroundColor: selected === 'tax_settings' ? '#C0B476' : 'transparent' ,
                marginLeft: '10px'
              }} 
              component={<Link to="/tax_settings" />}
              onClick={() => handleMenuItemClick('tax_settings')}
            >
              <AddCardIcon style={{ marginRight: '15px' }} />
              Tax Settings
            </MenuItem>

            <hr></hr>

            <MenuItem 
              style={{ 
                display: 'flex', 
                alignItems: 'center', 
                color: selected === 'Ticket_settings' ? 'white' : 'black', 
                backgroundColor: selected === 'Ticket_settings' ? '#C0B476' : 'transparent' ,
                marginLeft: '10px'
              }} 
              component={<Link to="/Ticket_settings" />}
              onClick={() => handleMenuItemClick('Ticket_settings')}
            >
              <BugReportIcon style={{ marginRight: '15px' }} />
              Ticket Settings
            </MenuItem>

            <hr></hr>

            <MenuItem 
              style={{ 
                display: 'flex', 
                alignItems: 'center', 
                color: selected === 'project_settings' ? 'white' : 'black', 
                backgroundColor: selected === 'project_settings' ? '#C0B476' : 'transparent' ,
                marginLeft: '10px'
              }} 
              component={<Link to="/project_settings" />}
              onClick={() => handleMenuItemClick('project_settings')}
            >
              <AccountTreeIcon style={{ marginRight: '15px' }} />
              Project Settings
            </MenuItem>

            <hr></hr>

            <MenuItem 
              style={{ 
                display: 'flex', 
                alignItems: 'center', 
                color: selected === 'rp_settings' ? 'white' : 'black', 
                backgroundColor: selected === 'rp_settings' ? '#C0B476' : 'transparent' ,
                marginLeft: '10px'
              }} 
              component={<Link to="/rp_settings" />}
              onClick={() => handleMenuItemClick('rp_settings')}
            >
              <PermIdentityIcon style={{ marginRight: '15px' }} />
              Roles and Permissions
            </MenuItem>

            <hr></hr>

            <MenuItem 
              style={{ 
                display: 'flex', 
                alignItems: 'center', 
                color: selected === 'Attendance_settings' ? 'white' : 'black', 
                backgroundColor: selected === 'Attendance_settings' ? '#C0B476' : 'transparent' ,
                marginLeft: '10px'
              }} 
              component={<Link to="/Attendance_settings" />}
              onClick={() => handleMenuItemClick('Attendance_settings')}
            >
              <EmojiPeopleIcon style={{ marginRight: '15px' }} />
              Attendance Settings
            </MenuItem>

            <hr></hr>

            <MenuItem 
              style={{ 
                display: 'flex', 
                alignItems: 'center', 
                color: selected === 'Payroll_settings' ? 'white' : 'black', 
                backgroundColor: selected === 'Payroll_settings' ? '#C0B476' : 'transparent' ,
                marginLeft: '10px'
              }} 
              component={<Link to="/Payroll_settings" />}
              onClick={() => handleMenuItemClick('Payroll_settings')}
            >
              <PointOfSaleIcon style={{ marginRight: '15px' }} />
              Payroll Settings
            </MenuItem>

            <hr></hr>

            <MenuItem 
              style={{ 
                display: 'flex', 
                alignItems: 'center', 
                color: selected === 'Leave_settings' ? 'white' : 'black', 
                backgroundColor: selected === 'Leave_settings' ? '#C0B476' : 'transparent' ,
                marginLeft: '10px'
              }} 
              component={<Link to="/Leave_settings" />}
              onClick={() => handleMenuItemClick('Leave_settings')}
            >
              <ExitToAppIcon style={{ marginRight: '15px' }} />
              Leave Settings
            </MenuItem>

            <hr></hr>

            <MenuItem 
              style={{ 
                display: 'flex', 
                alignItems: 'center', 
                color: selected === 'time_log_settings' ? 'white' : 'black', 
                backgroundColor: selected === 'time_log_settings' ? '#C0B476' : 'transparent' ,
                marginLeft: '10px'
              }} 
              component={<Link to="/time_log_settings" />}
              onClick={() => handleMenuItemClick('time_log_settings')}
            >
              <AccessTimeIcon style={{ marginRight: '15px' }} />
              Time Log Settings
            </MenuItem>

            <hr></hr>

            <MenuItem 
              style={{ 
                display: 'flex', 
                alignItems: 'center', 
                color: selected === 'Task_settings' ? 'white' : 'black', 
                backgroundColor: selected === 'Task_settings' ? '#C0B476' : 'transparent' ,
                marginLeft: '10px'
              }} 
              component={<Link to="/Task_settings" />}
              onClick={() => handleMenuItemClick('Task_settings')}
            >
              <TaskAltIcon style={{ marginRight: '15px' }} />
              Task Settings
            </MenuItem>

            <hr></hr>

            <MenuItem 
              style={{ 
                display: 'flex', 
                alignItems: 'center', 
                color: selected === 'security_settings' ? 'white' : 'black', 
                backgroundColor: selected === 'security_settings' ? '#C0B476' : 'transparent' ,
                marginLeft: '10px'
              }} 
              component={<Link to="/security_settings" />}
              onClick={() => handleMenuItemClick('security_settings')}
            >
              <AddModeratorIcon style={{ marginRight: '15px' }} />
              Security Settings
            </MenuItem>

            <hr></hr>

            

            <MenuItem 
              style={{ 
                display: 'flex', 
                alignItems: 'center', 
                color: selected === 'purchase_settings' ? 'white' : 'black', 
                backgroundColor: selected === 'purchase_settings' ? '#C0B476' : 'transparent' ,
                marginLeft: '10px'
              }} 
              component={<Link to="/purchase_settings" />}
              onClick={() => handleMenuItemClick('purchase_settings')}
            >
              <InventoryIcon style={{ marginRight: '15px' }} />
              Purchase Settings
            </MenuItem>

            <hr></hr>

          

            <MenuItem 
              style={{ 
                display: 'flex', 
                alignItems: 'center', 
                color: selected === 'assets_settings' ? 'white' : 'black', 
                backgroundColor: selected === 'assets_settings' ? '#C0B476' : 'transparent' ,
                marginLeft: '10px'
              }} 
              component={<Link to="/assets_settings" />}
              onClick={() => handleMenuItemClick('assets_settings')}
            >
              <WebAssetIcon style={{ marginRight: '15px' }} />
              Assets Settings
            </MenuItem>

            <hr></hr>

            <MenuItem 
              style={{ 
                display: 'flex', 
                alignItems: 'center', 
                color: selected === 'recruit_settings' ? 'white' : 'black', 
                backgroundColor: selected === 'recruit_settings' ? '#C0B476' : 'transparent' ,
                marginLeft: '10px'
              }} 
              component={<Link to="/recruit_settings" />}
              onClick={() => handleMenuItemClick('recruit_settings')}
            >
              <WorkIcon style={{ marginRight: '15px' }} />
              Recruit Settings
            </MenuItem>

            <hr></hr>

            <MenuItem 
              style={{ 
                display: 'flex', 
                alignItems: 'center', 
                color: selected === 'subscriptoin_settings' ? 'white' : 'black', 
                backgroundColor: selected === 'subscriptoin_settings' ? '#C0B476' : 'transparent' ,
                marginLeft: '10px'
              }} 
              component={<Link to="/subscriptoin_settings" />}
              onClick={() => handleMenuItemClick('subscriptoin_settings')}
            >
              <MeetingRoomIcon style={{ marginRight: '15px' }} />
              Subscriptoin Settings
              </MenuItem>

            <hr></hr>

            {/* <MenuItem 
              style={{ 
                display: 'flex', 
                alignItems: 'center', 
                color: selected === 'Update' ? 'white' : 'black', 
                backgroundColor: selected === 'Update' ? '#C0B476' : 'transparent' ,
                marginLeft: '10px'
              }} 
              component={<Link to="/system_Update" />}
              onClick={() => handleMenuItemClick('Update')}
            >
              <BrowserUpdatedIcon style={{ marginRight: '15px' }} />
              Update 
            </MenuItem> */}

            <hr></hr>
          </Box>
        </Menu>
      </Sidebar>
    </Box>
  );
}

export default SettingsSidebar;
