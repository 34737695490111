import React, { useEffect, useState } from 'react'
import { ScatterChart } from '@mui/x-charts/ScatterChart';
import { Box } from '@mui/material';
import 'tailwindcss/tailwind.css';
import './TimelogReport.css'
import MUIDataTable from "mui-datatables";
import { createTheme } from "@mui/material/styles"
import { ThemeProvider } from '@mui/styles';
import { styled } from '@mui/material/styles';
import Switch from '@mui/material/Switch';
import AddTimelogReport from './AddTimelogReport';
function TimelogReport() {

    const [users, setUsers] = useState([])
     // State to manage dropdown menu
  
  // const handleCloseMenu = () => {
  //   setAnchorEl(null);
  // };

  
    const columns = [
          {
              name:"",
              label:"Code"  // to rename column
          },
          {
              name:"",
              label:"Tasks"
          },
        {
            name:"name",
            label:"Employee"
          },
          {
            name:"email",
            label:"Email"
          },
          {
            name:"",
            label:"Start Time"
          },
          {
            name:"", //use this for status active or inactive
            label:"End Time"
          },
          {
            name: "",
            label: "Total Hours"
        },
        {
            name: "",
            label: "Earnings"
        }
    ];

    // const handleStatusChange = (event, rowIndex) => {
    //     const newUsers = [...users];
    //     newUsers[rowIndex].status = event.target.value;
    //     setUsers(newUsers);
    // };

// const handleStatusChange = (event, rowIndex) => {
//     const { value } = event.target;
//     setUsers(prevUsers => {
//         const updatedUsers = [...prevUsers];
//         updatedUsers[rowIndex].status = value;
//         return updatedUsers;
//     });
// };

    useEffect(() => {
        fetch('https://dummyjson.com/users')
            .then(res => res.json())
            .then((data) => {
                let local = data?.users?.map((user) => ({
                    ...user,
                    name: user?.firstName + " " + user?.lastName,
                    // status: "inactive"
                }))
                console.log(local)
                setUsers(local);
            })
    })

    const options = {
          filterType: 'checkbox',
        //selectableRows: false,
        elevation: 0,
        rowsPerPage: 5,
        rowsPerPageOptions: [5, 10, 20, 30],
    };

    const getMuiTheme = () =>
        createTheme({
            typography: {
                fontFamily: "Poppins",
            },
            palette: {
                background: {
                    paper: "#1e293b",
                    default: "0f172a",
                },
                mode: "dark",
            },
            components: {
                MuiTableCell: {
                    styleOverrides: {
                        head: {
                            padding: "10px 4px",
                        },
                        body: {
                            padding: "7px 15px",
                            color: "#e2e8f0",
                        },
                    }
                }
            }
        })

        const data = [
            {
              id: 'data-0',
              x1: 15,
              y1: 9,
            },
            {
              id: 'data-1',
              x1: 9,
              y1: 7,
            }
        ];

    return (
        <div className='py-10 min-h-screen grid place-items-center'>
               <Box component="section" sx={{ p: 2, backgroundColor: "#121212" }}>
               <ScatterChart
      width={600}
      height={300}
      series={[
        {
          
          data: data.map((v) => ({ x: v.x1, y: v.y1, id: v.id })),
          label: 'Series A',
        },
      ]}
    />

            </Box>
            <AddTimelogReport></AddTimelogReport>
            <br></br>
            <div className='w-10/12 max-w-4xl'>
                <ThemeProvider theme={getMuiTheme()}>
                    <MUIDataTable
                        title={"Timelog Report List"}
                        data={users}
                        columns={columns}
                        options={options}
                    />
                </ThemeProvider>
            </div>

        </div>
    )
}

export default TimelogReport;