import React, { useEffect, useState } from 'react';
import 'tailwindcss/tailwind.css';
import './Sales.css';
import MUIDataTable from 'mui-datatables';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { IconButton, Menu, MenuItem } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import FormControl from '@mui/material/FormControl';
import AddSales from './AddSales';

function Sales() {
    const [sales, setSales] = useState([]);
  const [leads, setLeads] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleViewClick = () => {
    // Implement logic to handle view action
    handleCloseMenu();
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
  
  };

  const columns = [
    { name: "id", label: "ID" },
    { name: "username", label: "Name" },
    { name: "phone", label: "Phone" },
    { name: "address", label: "Location" },
    { name: "role", label: "Role" },
    { name: "status", label: "Status" },
    {
      name: "id",
      label: "Action",
      options: {
        customBodyRender: (value, tableMeta) => (
          <FormControl>
            <IconButton onClick={handleMenuClick}>
              <MoreVertIcon />
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleCloseMenu}
            >
              <MenuItem onClick={handleCloseMenu}>View</MenuItem>
              <MenuItem onClick={handleCloseMenu}>Edit</MenuItem>
              <MenuItem onClick={handleCloseMenu}>Delete</MenuItem>
              <MenuItem onClick={handleCloseMenu}>Change to Client</MenuItem>
              <MenuItem onClick={handleCloseMenu}>Add Follow up</MenuItem>
            </Menu>
          </FormControl>
        ),
      },
    },
  ];


  const options = {
    //selectableRows: false,
    elevation: 0,
    rowsPerPage: 5,
    rowsPerPageOptions: [5, 10, 25, 100],
    tableBody: {
      style: {
        maxHeight: '500px',
        overflowY: 'scroll',
        marginLeft: '5px',
      },
    },
    tableHead: {
      style: {
        backgroundColor: '#f2f2f2',
        color: 'black',
      },
    },
    rows: {
      style: {
        marginLeft: '5px',
        textAlign: 'center',
      },
      selectableRowsOnClick: true,
    },
  };

  const getTheme = () =>
    createTheme({
      palette: {
        mode: 'light',
        primary: {
          main: '#2196f3',
        },
        secondary: {
          main: '#03a9f4',
        },
      },
      typography: {
        fontFamily: ['Roboto', 'Helvetica', 'Arial', 'Sans-serif'].join(','),
      },
      components: {
        MuiButton: {
          styleOverrides: {
            root: {
              textTransform: 'none',
            },
          },
        },
      },
      tableBody: {
        style: {
          maxHeight: '500px',
          overflowY: 'scroll',
        },
      },
      tableHead: {
        style: {
          backgroundColor: '#f2f2f2',
          color: 'black',
        },
      },
      rows: {
        style: {
          '&:hover': {
            backgroundColor: '#f2f2f2',
          },
        },
      },
    });

  return (
    <div className='py-10 min-h-screen grid place-items-center'>
      <AddSales />
      <br/>
      <div className='w-10/12 max-w-4xl'>
        <ThemeProvider theme={getTheme()}>
          <MUIDataTable
            title='Sales'
            data={sales}
            columns={columns}
            options={options}
          />
        </ThemeProvider>
      </div>
    </div>
  );
}

export default Sales;
