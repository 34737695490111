import * as React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import {  Grid } from '@mui/material';
import Button from '@mui/material/Button';


const dateFormats = [
    {
      value: 'YYYY-MM-DD',
      label: 'YYYY-MM-DD',
    },
    {
      value: 'DD-MM-YYYY',
      label: 'DD-MM-YYYY',
    },
    {
      value: 'MM-DD-YYYY',
      label: 'MM-DD-YYYY',
    },
    {
      value: 'YYYY/MM/DD',
      label: 'YYYY/MM/DD',
    },
    {
      value: 'DD/MM/YYYY',
      label: 'DD/MM/YYYY',
    },
    {
      value: 'MM/DD/YYYY',
      label: 'MM/DD/YYYY',
    },
    {
      value: 'YYYY.MM.DD',
      label: 'YYYY.MM.DD',
    },
    {
      value: 'DD.MM.YYYY',
      label: 'DD.MM.YYYY',
    },
    {
      value: 'MM.DD.YYYY',
      label: 'MM.DD.YYYY',
    },
  ];

  const timeFormats = [
    {
      value: 'HH:mm:ss',
      label: 'HH:mm:ss',
    },
    {
      value: 'HH:mm',
      label: 'HH:mm',
    },
    {
      value: 'h:mm:ss A',
      label: 'h:mm:ss A',
    },
    {
      value: 'h:mm A',
      label: 'h:mm A',
    },
  ];

  const timeZones = [
    {
      value: 'IDLW',
      label: 'IDLW (International Date Line West)',
    },
    {
      value: 'NUT',
      label: 'NUT (Niue Time)',
    },
    {
      value: 'SST',
      label: 'SST (Samoa Standard Time)',
    },
    {
      value: 'CKT',
      label: 'CKT (Cook Island Time)',
    },
    {
      value: 'HST',
      label: 'HST (Hawaii Standard Time)',
    },
    {
      value: 'AKST',
      label: 'AKST (Alaska Standard Time)',
    },
    {
      value: 'PST',
      label: 'PST (Pacific Standard Time)',
    },
    {
      value: 'MST',
      label: 'MST (Mountain Standard Time)',
    },
    {
      value: 'CST',
      label: 'CST (Central Standard Time)',
    },
    {
      value: 'EST',
      label: 'EST (Eastern Standard Time)',
    },
    {
      value: 'AST',
      label: 'AST (Atlantic Standard Time)',
    },
    {
      value: 'NST',
      label: 'NST (Newfoundland Standard Time)',
    },
    {
      value: 'GMT',
      label: 'GMT (Greenwich Mean Time)',
    },
    {
      value: 'CET',
      label: 'CET (Central European Time)',
    },
    {
      value: 'EET',
      label: 'EET (Eastern European Time)',
    },
    {
      value: 'MSK',
      label: 'MSK (Moscow Standard Time)',
    },
    {
      value: 'IST',
      label: 'IST (India Standard Time)',
    },
    {
      value: 'AWST',
      label: 'AWST (Australian Western Standard Time)',
    },
    {
      value: 'JST',
      label: 'JST (Japan Standard Time)',
    },
    {
      value: 'ACST',
      label: 'ACST (Australian Central Standard Time)',
    },
    {
      value: 'AEST',
      label: 'AEST (Australian Eastern Standard Time)',
    },
    {
      value: 'NZST',
      label: 'NZST (New Zealand Standard Time)',
    },
  ];
  

  const rowlimit = [
    {
      value: '0',
      label: '0',
    },
    {
      value: '10',
      label: '10',
    },
    {
      value: '25',
      label: '25',
    },
    {
        value: '50',
        label: '50',
      },
      {
        value: '100',
        label: '100',
      },
  ];
  
  

export default function SelectTextFields() {
  const [isFocused, setIsFocused] = React.useState(false);

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };

  return (
    <Box
      component="form"
      sx={{
        '& .MuiTextField-root': { m: 1, width: '25ch', color: '#000000' }, // Set text color to black when focused, gray otherwise
        '& .MuiMenuItem-root': { color: '#666' }, // Set text color of MenuItem options to gray
        '& #outlined-select-currency': { color: '#000000' }, // Set text color of outlined-select-currency-native to black when focused, gray otherwise
        '& .MuiPaper-root': { backgroundColor: '#fff' }, // Set background color of the popup to white
      }}
      noValidate
      autoComplete="off"
    >
      <div>
                <TextField
            id="outlined-select-currency"
            select
            label="Date Formate"
            defaultValue="DD-MM-YYYY"
            helperText="Date Formate"
            onFocus={handleFocus}
            onBlur={handleBlur}
            style={{ color: '#000', borderColor: '#000' }} // Set text color and border color to black
            InputProps={{ style: { color: '#000' } }} // Set default text color to black
            InputLabelProps={{ style: { color: '#000' } }} // Set label color to black
            SelectProps={{ style: { color: '#000' } }} // Set select option color to black
            menuProps={{ PaperProps: { style: { backgroundColor: '#fff' } } }} // Set background color of the popup to white
            >
            {dateFormats.map((date) => (
                <MenuItem key={date.value} value={date.value}>
                {date.label}
                </MenuItem>
            ))}
            </TextField>


            <TextField
            id="outlined-select-currency"
            select
            label="Time Formate"
            defaultValue="HH:mm:ss"
            helperText="Time Formate"
            onFocus={handleFocus}
            onBlur={handleBlur}
            style={{ color: '#000', borderColor: '#000' }} // Set text color and border color to black
            InputLabelProps={{ style: { color: '#000' } }} // Set label color to black
            InputProps={{ style: { color: '#000' } }} // Set default text color to black
            SelectProps={{ style: { color: '#000' } }} // Set select option color to black
            menuProps={{ PaperProps: { style: { backgroundColor: '#fff' } } }} // Set background color of the popup to white
            >
            {timeFormats.map((time) => (
                <MenuItem key={time.value} value={time.value}>
                {time.label}
                </MenuItem>
            ))}
            </TextField>

            <TextField
            id="outlined-select-currency"
            select
            label="Default TimeZone"
            defaultValue="IST"
            helperText="Default TimeZone"
            onFocus={handleFocus}
            onBlur={handleBlur}
            style={{ color: '#000', borderColor: '#000' }} // Set text color and border color to black
            InputLabelProps={{ style: { color: '#000' } }} // Set label color to black
            InputProps={{ style: { color: '#000' } }} // Set default text color to black
            SelectProps={{ style: { color: '#000' } }} // Set select option color to black
            menuProps={{ PaperProps: { style: { backgroundColor: '#fff' } } }} // Set background color of the popup to white
            >
            {timeZones.map((zone) => (
                <MenuItem key={zone.value} value={zone.value}>
                {zone.label}
                </MenuItem>
            ))}
            </TextField>

            <TextField
            id="outlined-select-currency"
            select
            label="Datatable Row Limit"
            defaultValue="0"
            helperText="Max Employes can Export"
            onFocus={handleFocus}
            onBlur={handleBlur}
            style={{ color: '#000', borderColor: '#000' }} // Set text color and border color to black
            InputLabelProps={{ style: { color: '#000' } }} // Set label color to black
            InputProps={{ style: { color: '#000' } }} // Set default text color to black
            SelectProps={{ style: { color: '#000' } }} // Set select option color to black
            menuProps={{ PaperProps: { style: { backgroundColor: '#fff' } } }} // Set background color of the popup to white
            >
            {rowlimit.map((limit) => (
                <MenuItem key={limit.value} value={limit.value}>
                {limit.label}
                </MenuItem>
            ))}
            </TextField>

            <FormControlLabel
                 control={<Checkbox defaultChecked style={{ color: 'red' }} />}
                 label="Employee can export Data"
                 style={{ color: 'black' }}
            />

        <Grid item xs={12}>
          <Button variant="contained" sx={{ marginTop: '10px', marginLeft: '10px', backgroundColor: 'blue', '&:hover': { backgroundColor: 'orange' } }}>
            Save
          </Button>
        </Grid>
      </div>
    </Box>
  );
}
