import React, { useEffect, useState } from 'react'
import 'tailwindcss/tailwind.css';
import './Vendor.css'
import MUIDataTable from "mui-datatables";
import { createTheme } from "@mui/material/styles"
import { ThemeProvider } from '@mui/styles';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem'; 
import FormControl from '@mui/material/FormControl';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {IconButton, Menu } from '@mui/material';
import { styled } from '@mui/material/styles';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import AddVendor from './AddVendor';
function Vendor() {

    const [users, setUsers] = useState([])
    const [value, setValue] = React.useState('active');
     // State to manage dropdown menu
   const [anchorEl, setAnchorEl] = useState(null);
   const [showPopup, setShowPopup] = useState(false);
  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleViewClick = () => {
    setShowPopup(true); // Open the pop-up window when "View" is clicked
    handleCloseMenu(); // Close the menu
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

    const handleChange = (event) => {
      setValue(event.target.value);
    };
    const AntSwitch = styled(Switch)(({ theme }) => ({
        width: 28,
        height: 16,
        padding: 0,
        display: 'flex',
        '&:active': {
          '& .MuiSwitch-thumb': {
            width: 15,
          },
          '& .MuiSwitch-switchBase.Mui-checked': {
            transform: 'translateX(9px)',
          },
        },
        '& .MuiSwitch-switchBase': {
          padding: 2,
          '&.Mui-checked': {
            transform: 'translateX(12px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
              opacity: 1,
              backgroundColor: theme.palette.mode === 'dark' ? '#177ddc' : '#1890ff',
            },
          },
        },
        '& .MuiSwitch-thumb': {
          boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
          width: 12,
          height: 12,
          borderRadius: 6,
          transition: theme.transitions.create(['width'], {
            duration: 200,
          }),
        },
        '& .MuiSwitch-track': {
          borderRadius: 16 / 2,
          opacity: 1,
          backgroundColor:
            theme.palette.mode === 'dark' ? 'rgba(255,255,255,.35)' : 'rgba(0,0,0,.25)',
          boxSizing: 'border-box',
        },
      }));
    const columns = [
          // {
          //     name:"id",
          //     label:"ID"  // to rename column
          // },
          {
              name:"firstName",
              label:"Primary Name"
          },
        {
            name:"name",
            label:"Company Name"
          },
          {
            name:"email",
            label:"Email"
          },
          {
            name:"phone",
            label:"Phone"
          },
        {
            name: "action",
            label: "Action",
            options: {
                customBodyRender: (value, tableMeta) => (
                    <FormControl>
                        <IconButton onClick={handleMenuClick}>
                      <MoreVertIcon />
                    </IconButton>
                    <Menu
                      anchorEl={anchorEl}
                      open={Boolean(anchorEl)}
                      onClose={handleCloseMenu}
                    >
                      <MenuItem onClick={handleViewClick}>View</MenuItem>
                      <MenuItem onClick={handleCloseMenu}>Edit</MenuItem>
                      <MenuItem onClick={handleCloseMenu}>Delete</MenuItem>
                    </Menu>
                    </FormControl>
                ) 
            } 
        }
    ];

    const handleStatusChange = (event, rowIndex) => {
        const newUsers = [...users];
        newUsers[rowIndex].status = event.target.value;
        setUsers(newUsers);
    };

// const handleStatusChange = (event, rowIndex) => {
//     const { value } = event.target;
//     setUsers(prevUsers => {
//         const updatedUsers = [...prevUsers];
//         updatedUsers[rowIndex].status = value;
//         return updatedUsers;
//     });
// };

    useEffect(() => {
        fetch('https://dummyjson.com/users')
            .then(res => res.json())
            .then((data) => {
                let local = data?.users?.map((user) => ({
                    ...user,
                    name: user?.firstName + " " + user?.lastName,
                    // status: "inactive"
                }))
                console.log(local)
                setUsers(local);
            })
    })

    const options = {
          filterType: 'checkbox',
        //selectableRows: false,
        elevation: 0,
        rowsPerPage: 5,
        rowsPerPageOptions: [5, 10, 20, 30],
    };

    const getMuiTheme = () =>
        createTheme({
            typography: {
                fontFamily: "Poppins",
            },
            palette: {
                background: {
                    paper: "#1e293b",
                    default: "0f172a",
                },
                mode: "dark",
            },
            components: {
                MuiTableCell: {
                    styleOverrides: {
                        head: {
                            padding: "10px 4px",
                        },
                        body: {
                            padding: "7px 15px",
                            color: "#e2e8f0",
                        },
                    }
                }
            }
        })

    return (
        <div className='py-10 min-h-screen grid place-items-center'>
            <AddVendor></AddVendor>
            <br></br>
            <div className='w-10/12 max-w-4xl'>
                <ThemeProvider theme={getMuiTheme()}>
                    <MUIDataTable
                        title={"Vendor List"}
                        data={users}
                        columns={columns}
                        options={options}
                    />
                </ThemeProvider>
            </div>

        </div>
    )
}

export default Vendor;