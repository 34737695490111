import React, { useEffect, useState } from 'react'
import 'tailwindcss/tailwind.css';
import './StaffSalary.css'
import MUIDataTable from "mui-datatables";
import { createTheme } from "@mui/material/styles"
import { ThemeProvider } from '@mui/styles';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem'; 
import FormControl from '@mui/material/FormControl';
import {Button} from '@mui/material';
import AddStaffSalary from './AddStaffSalary';


function StaffSalary() {

    const [users, setUsers] = useState([])
  
    const columns = [
          {
              name:"id",
              label:"ID"  // to rename column
          },
        {
            name:"name",
            label:"Name"
          },
          {
            name:"empSalcyc",
            label:"Employee Salary Cycle"
          },
          {
            name:"salGrp",
            label:"Salary Group"
          },
          {
            name:"payGen",
            label:"Payroll Generate",
            options: {
              customBodyRender: (value, tableMeta) => (
                  <FormControl>
                     <Select
                            value={value||''}
                            onChange={(event) => handleStatusChange(event, tableMeta.rowIndex)}
                            labelId="status-label"
                            id="status-select"
                            label="User Role"
                        >   
                             <MenuItem value="">---</MenuItem> {/* Add a default option */}
                            <MenuItem value="Yes">Yes</MenuItem>
                            <MenuItem value="No">No</MenuItem>
                        </Select>
                  </FormControl>
              ) 
          } 
          },
          {
            name:"netSalary",
            label:"Net Salary"
          },
        {
            name: "action",
            label: "Action",
            options: {
                customBodyRender: (value, tableMeta) => (
                  <Button variant="outlined" style={{color:"white", borderColor: "green"}}>Add Salary</Button>
                ) 
            } 
        }
    ];

    const handleStatusChange = (event, rowIndex) => {
        const newUsers = [...users];
        newUsers[rowIndex].status = event.target.value;
        setUsers(newUsers);
    };

// const handleStatusChange = (event, rowIndex) => {
//     const { value } = event.target;
//     setUsers(prevUsers => {
//         const updatedUsers = [...prevUsers];
//         updatedUsers[rowIndex].status = value;
//         return updatedUsers;
//     });
// };

    useEffect(() => {
        fetch('https://dummyjson.com/users')
            .then(res => res.json())
            .then((data) => {
                let local = data?.users?.map((user) => ({
                    ...user,
                    name: user?.firstName + " " + user?.lastName,
                    // status: "inactive"
                }))
                console.log(local)
                setUsers(local);
            })
    })

    const options = {
          filterType: 'checkbox',
        //selectableRows: false,
        elevation: 0,
        rowsPerPage: 5,
        rowsPerPageOptions: [5, 10, 20, 30],
    };

    const getMuiTheme = () =>
        createTheme({
            typography: {
                fontFamily: "Poppins",
            },
            palette: {
                background: {
                    paper: "#1e293b",
                    default: "0f172a",
                },
                mode: "dark",
            },
            components: {
                MuiTableCell: {
                    styleOverrides: {
                        head: {
                            padding: "10px 4px",
                        },
                        body: {
                            padding: "7px 15px",
                            color: "#e2e8f0",
                        },
                    }
                }
            }
        })

    return (
        <div className='py-10 min-h-screen grid place-items-center'>
          <AddStaffSalary></AddStaffSalary>
            <br></br>
            <div className='w-10/12 max-w-4xl'>
                <ThemeProvider theme={getMuiTheme()}>
                    <MUIDataTable
                        title={"Crane Owner List"}
                        data={users}
                        columns={columns}
                        options={options}
                    />
                </ThemeProvider>
            </div>

        </div>
    )
}

export default StaffSalary;