import * as React from 'react';
import Stack from '@mui/material/Stack';
import AddIcon from '@mui/icons-material/Add';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import { Box, Button, TextField, Grid } from '@mui/material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useState } from 'react';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { format, isAfter, isValid } from 'date-fns';



const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function AddTimeSheet() {
    const user = JSON.parse(localStorage.getItem('user-info'));
    const [open, setOpen] = React.useState(false);
    //const [date, setDate] = useState(null); // Initialize state for the date
    const [startDate, setStartDate] = useState(null);
    const [startTime, setStartTime] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [endTime, setEndTime] = useState(null);
    const [totalHours, setTotalHours] = useState('');
    const [project, setProject] = useState('');
    const [projectError, setProjectError] = useState('');
    const [task, setTask] = useState('');
    const [memo, setMemo] = useState('');

    const handleStartDateChange = (date) => {
        setStartDate(date);
        //calculateTotalHours(date, startTime, endDate, endTime);
    };

    const handleStartTimeChange = (time) => {
        setStartTime(time);
        //calculateTotalHours(startDate, time, endDate, endTime);
    };

    const handleEndDateChange = (date) => {
        setEndDate(date);
        //calculateTotalHours(startDate, startTime, date, endTime);
    };

    const handleEndTimeChange = (time) => {
        setEndTime(time);
        calculateTotalHours(startDate, startTime, endDate, time);
    };

    const calculateTotalHours = (startDate, startTime, endDate, endTime) => {
        if (!startDate || !startTime || !endDate || !endTime) {
            setTotalHours('');
            return;
        }

        const startDateTime = new Date(startDate);
        const endDateTime = new Date(endDate);

        // Check if startTime and endTime are valid dates
        if (!isValid(startTime) || !isValid(endTime)) {
            setTotalHours('NaN Hrs NaN Mins');
            return;
        }

        startDateTime.setHours(startTime.getHours(), startTime.getMinutes());
        endDateTime.setHours(endTime.getHours(), endTime.getMinutes());

        if (!isValid(startDateTime) || !isValid(endDateTime) || isAfter(startDateTime, endDateTime)) {
            setTotalHours('NaN Hrs NaN Mins');
            return;
        }


        const timeDifferenceMs = endDateTime - startDateTime;
        const hours = Math.floor(timeDifferenceMs / (1000 * 60 * 60));
        const minutes = Math.floor((timeDifferenceMs % (1000 * 60 * 60)) / (1000 * 60));
        const total = hours + 'Hrs ' + minutes + 'Mins';
        setTotalHours(total);
    };

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
    
    const handleSave = () => {
        const list = { project, task, startDate,startTime,endDate,totalHours,endTime,memo }
        console.log(list)
        if (project === '') {
            setProjectError(true);
        } else {
            const list = { project, task, startDate,startTime,endDate,totalHours,endTime,memo };
            console.log(list);
            setProjectError(false); // Reset the error state
            handleClose(); // Close the dialog
        }
    };
    // const handleDateChange = (newDate) => {
    //     setDate(newDate); // Update the date state
    //     console.log(newDate); // Log the new date to the console
    // };

    return (
        <div>
            <React.Fragment>
                <Stack direction="row" spacing={2}>
                    <Button variant="contained" onClick={handleClickOpen} sx={{ backgroundColor: 'blue', '&:hover': { backgroundColor: 'orange' }, textAlign: 'center', marginLeft: '10px' }}><AddIcon />Add New Time Sheet</Button>
                    {/* <Button variant="contained" disabled>
        Disabled
      </Button>
      <Button variant="contained" href="#contained-buttons">
        Link
      </Button> */}
                </Stack>
                <Dialog
                    fullScreen
                    open={open}
                    onClose={handleClose}
                    TransitionComponent={Transition}
                    PaperProps={{
                        style: {
                            backgroundColor: 'white',
                        },
                    }}
                >

                    <AppBar sx={{ position: 'relative' }}>
                        <Toolbar>
                            <IconButton
                                edge="start"
                                color="inherit"
                                onClick={handleClose}
                                aria-label="close"
                            >
                                <CloseIcon />
                            </IconButton>
                            <Typography sx={{ ml: 2, flex: 1 }} component="div">
                                Log Time
                            </Typography>

                        </Toolbar>

                    </AppBar>
                    <Box>
                        <Typography sx={{ ml: 2, flex: 1, color: 'black', fontSize: "20px"}} component="div">
                            Time Log Details
                        </Typography>
                        <hr sx={{ color: 'gray', }} />
                        {/* <Grid container spacing={2} sx={{ marginTop: "20px", marginLeft: "40px" }}>
                                <Stack direction="column" spacing={2} sx={{ width: '100%' }}> */}
                        <Grid container spacing={2} sx={{ marginLeft: "20px", marginTop: "20px" }}>
                            <Stack direction="row" spacing={1} sx={{ width: "100%" }}>
                                <Grid item xs={12} md={4} sm={4} >
                                    <Stack direction="column">
                                        <Typography variant="p" gutterBottom style={{ color: 'black' }}>
                                            Project
                                        </Typography>
                                        <Select
                                            value={project}
                                            autoFocus
                                            onChange={(e) => setProject(e.target.value)}
                                            displayEmpty
                                            inputProps={{ 'aria-label': 'Without label' }}
                                            sx={{
                                                border: '1px solid black',
                                                boxSizing: 'border-box',
                                                '& .MuiOutlinedInput-root': {
                                                    borderColor: 'black !important',
                                                    '&:hover fieldset': {
                                                        borderColor: 'black !important',
                                                    },
                                                    '&.Mui-focused fieldset': {
                                                        borderColor: 'black !important',
                                                    },
                                                },
                                                color: "black"
                                            }}
                                        >
                                            <MenuItem disabled value="">
                                                <em>---</em>
                                            </MenuItem>
                                            <MenuItem value={'AndroidApplication'}>Android Application</MenuItem>
                                            <MenuItem value={'ReactApplication'}>React Application</MenuItem>
                                        </Select>
                                        {projectError && <Typography variant="caption" style={{ color: 'red' }}>Please select a project</Typography>}
                                    </Stack>
                                </Grid>
                                <Grid item xs={12} md={4} sm={4} >
                                    <Stack direction="column">
                                        <Typography variant="p" gutterBottom style={{ color: 'black' }}>
                                            Task<span style={{ color: 'red' }}>*</span>
                                        </Typography>
                                        <Select
                                            value={task}
                                            autoFocus
                                            onChange={(e) => setTask(e.target.value)}
                                            displayEmpty
                                            inputProps={{ 'aria-label': 'Without label' }}
                                            sx={{
                                                border: '1px solid black',
                                                boxSizing: 'border-box',
                                                '& .MuiOutlinedInput-root': {
                                                    borderColor: 'black !important',
                                                    '&:hover fieldset': {
                                                        borderColor: 'black !important',
                                                    },
                                                    '&.Mui-focused fieldset': {
                                                        borderColor: 'black !important',
                                                    },
                                                },
                                                color: "black"
                                            }}
                                        >
                                            <MenuItem disabled value="">
                                                <em>---</em>
                                            </MenuItem>
                                            <MenuItem value={'AndroidApplication'}>Android Application</MenuItem>
                                            <MenuItem value={'ReactApplication'}>React Application</MenuItem>
                                        </Select>
                                    </Stack>
                                </Grid>
                                <Grid item xs={12} md={4} sm={4} >
                                    <Stack direction="row">
                                        <AccountCircleIcon sx={{ color: "grey", fontSize: "30px", marginTop: "25px" }} />
                                        <Typography component="div" sx={{ marginTop: "25px" }}>
                                            <h1 style={{ color: "black", fontSize: "20px" }}>{user.name}</h1>
                                        </Typography>
                                    </Stack>
                                </Grid>
                            </Stack>
                        </Grid>
                        <br></br>
                        <Grid container spacing={2} sx={{ marginLeft: "20px" }}>
                            <Stack direction="row" spacing={1} sx={{ width: "80%" }}>
                                {/* <Grid item xs={12} md={4} sm={4} >
                                    <Stack direction="column">
                                        <Typography variant="p" gutterBottom style={{ color: 'black' }}>
                                            Start Date<span style={{ color: 'red' }}>*</span>
                                        </Typography>
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DemoContainer
                                                components={['DatePicker']}
                                                onChange={(e) => handleStartDateChange(e.target.value)}
                                                sx={{ marginTop: "-10px" }}
                                            >
                                                <DatePicker
                                                    value={startDate}
                                                    onChange={handleStartDateChange}
                                                    renderInput={(params) => <TextField {...params} />}
                                                    sx={{
                                                        border: '1px solid black',
                                                        '& .MuiOutlinedInput-root': {
                                                            borderColor: 'black !important',
                                                            color: "black !important",
                                                            '&:hover fieldset': {
                                                                borderColor: 'black !important',
                                                            },
                                                            '&.Mui-focused fieldset': {
                                                                borderColor: 'black !important',
                                                            },
                                                        },
                                                        '& .MuiSvgIcon-root': {
                                                            color: 'black',
                                                        },
                                                    }}
                                                />
                                            </DemoContainer>

                                        </LocalizationProvider>
                                    </Stack>
                                </Grid> */}
                                <Grid item xs={12} md={6} >

                                    <Stack direction="column">
                                        <Typography variant="p" gutterBottom style={{ color: 'black' }}>
                                            Start Date<span style={{ color: 'red' }}>*</span>
                                        </Typography>
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DemoContainer
                                                components={['DatePicker']}
                                                onChange={(e) => handleStartDateChange(e.target.value)}
                                                sx={{ marginTop: "-10px" }}
                                            >
                                                <DatePicker
                                                    value={startDate}
                                                    onChange={handleStartDateChange}
                                                    sx={{
                                                        border: '1px solid black',
                                                        '& .MuiOutlinedInput-root': {
                                                            borderColor: 'black !important',
                                                            color: "black !important",
                                                            '&:hover fieldset': {
                                                                borderColor: 'black !important',
                                                            },
                                                            '&.Mui-focused fieldset': {
                                                                borderColor: 'black !important',
                                                            },
                                                        },
                                                        '& .MuiSvgIcon-root': {
                                                            color: 'black',
                                                        },
                                                    }}
                                                >
                                                        {/* TextField component now becomes a child of DatePicker */}
                                                        {(params) => (
                                                        <TextField
                                                            {...params}
                                                            variant="outlined"
                                                        // Add any additional TextField props if needed
                                                        />
                                                    )}
                                                </DatePicker>
                                            </DemoContainer>

                                        </LocalizationProvider>
                                    </Stack>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Stack direction="column">
                                        <Typography variant="p" gutterBottom style={{ color: 'black' }}>
                                            Start Time<span style={{ color: 'red' }}>*</span>
                                        </Typography>
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DemoContainer
                                                components={['DatePicker']}
                                                onChange={(e) => handleStartTimeChange(e.target.value)}
                                                sx={{ marginTop: "-10px" }}
                                            >
                                                <TimePicker
                                                    value={startTime}
                                                    onChange={handleStartTimeChange}
                                                    sx={{
                                                        border: '1px solid black',
                                                        '& .MuiOutlinedInput-root': {
                                                            borderColor: 'black !important',
                                                            color: "black !important",
                                                            '&:hover fieldset': {
                                                                borderColor: 'black !important',
                                                            },
                                                            '&.Mui-focused fieldset': {
                                                                borderColor: 'black !important',
                                                            },
                                                        },
                                                        '& .MuiSvgIcon-root': {
                                                            color: 'black',
                                                        },
                                                    }}
                                                >
                                                        {/* TextField component now becomes a child of DatePicker */}
                                                        {(params) => (
                                                        <TextField
                                                            {...params}
                                                            variant="outlined"
                                                        // Add any additional TextField props if needed
                                                        />
                                                    )}
                                                </TimePicker>
                                            </DemoContainer>

                                        </LocalizationProvider>
                                    </Stack>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Stack direction="column">
                                        <Typography variant="p" gutterBottom style={{ color: 'black' }}>
                                            End Date<span style={{ color: 'red' }}>*</span>
                                        </Typography>
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DemoContainer
                                                components={['DatePicker']}
                                                onChange={(e) => handleEndDateChange(e.target.value)}
                                                sx={{ marginTop: "-10px" }}
                                            >
                                                <DatePicker
                                                    value={endDate}
                                                    onChange={handleEndDateChange}
                                                    sx={{
                                                        border: '1px solid black',
                                                        '& .MuiOutlinedInput-root': {
                                                            borderColor: 'black !important',
                                                            color: "black !important",
                                                            '&:hover fieldset': {
                                                                borderColor: 'black !important',
                                                            },
                                                            '&.Mui-focused fieldset': {
                                                                borderColor: 'black !important',
                                                            },
                                                        },
                                                        '& .MuiSvgIcon-root': {
                                                            color: 'black',
                                                        },
                                                    }}
                                                >
                                                        {/* TextField component now becomes a child of DatePicker */}
                                                        {(params) => (
                                                        <TextField
                                                            {...params}
                                                            variant="outlined"
                                                        // Add any additional TextField props if needed
                                                        />
                                                    )}
                                                </DatePicker>
                                            </DemoContainer>

                                        </LocalizationProvider>
                                    </Stack>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Stack direction="column">
                                        <Typography variant="p" gutterBottom style={{ color: 'black' }}>
                                            End Time<span style={{ color: 'red' }}>*</span>
                                        </Typography>
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DemoContainer
                                                components={['DatePicker']}
                                                onChange={(e) => handleEndTimeChange(e.target.value)}
                                                sx={{ marginTop: "-10px" }}
                                            >
                                                <TimePicker
                                                    value={endTime}
                                                    onChange={handleEndTimeChange}
                                                    sx={{
                                                        border: '1px solid black',
                                                        '& .MuiOutlinedInput-root': {
                                                            borderColor: 'black !important',
                                                            color: "black !important",
                                                            '&:hover fieldset': {
                                                                borderColor: 'black !important',
                                                            },
                                                            '&.Mui-focused fieldset': {
                                                                borderColor: 'black !important',
                                                            },
                                                        },
                                                        '& .MuiSvgIcon-root': {
                                                            color: 'black',
                                                        },
                                                    }}
                                                >
                                                        {/* TextField component now becomes a child of DatePicker */}
                                                        {(params) => (
                                                        <TextField
                                                            {...params}
                                                            variant="outlined"
                                                        // Add any additional TextField props if needed
                                                        />
                                                    )}
                                                </TimePicker>
                                            </DemoContainer>

                                        </LocalizationProvider>
                                    </Stack>
                                </Grid>
                            </Stack>
                        </Grid>
                        <Grid container spacing={2} sx={{ marginLeft: "20px", marginTop: "20px" }}>
                            <Stack direction="row" spacing={2} sx={{ width: "100%" }}>
                                <Grid item xs={12} md={4} sm={4} >
                                    <Stack direction="column">
                                        <Typography variant="p" gutterBottom style={{ color: 'black' }}>
                                            Memo<span style={{ color: 'red' }}>*</span>
                                        </Typography>
                                        <TextField id="outlined-basic" variant="outlined" placeholder='eg.Working on new logo'
                                            value={memo}
                                            onChange={(e) => setMemo(e.target.value)}
                                            inputProps={{
                                                style: { color: 'black' }
                                            }}
                                            sx={{
                                                border: '1px solid black',
                                                boxSizing: 'border-box',
                                                '& .MuiOutlinedInput-root': {
                                                    borderColor: 'black !important',
                                                    '&:hover fieldset': {
                                                        borderColor: 'black !important',
                                                    },
                                                    '&.Mui-focused fieldset': {
                                                        borderColor: 'black !important',
                                                    },
                                                },
                                                color: "black"
                                            }} />
                                    </Stack>
                                </Grid>
                                <Grid item xs={12} md={4} sm={4} >
                                    <Stack direction="column">
                                        <Typography variant="p" gutterBottom style={{ color: 'black' }}>
                                            Total Hours<span style={{ color: 'red' }}>*</span>
                                        </Typography>
                                        <Typography variant="p" gutterBottom 
                                        value={totalHours}
                                        onChange={(e) => setTotalHours(e.target.value)}
                                        style={{ color: '#912BBC' ,fontSize:"20px",fontWeight:"40"}}>
                                            {totalHours}
                                        </Typography>
                                    </Stack>
                                </Grid>
                            </Stack>
                        </Grid>

                        {/* </Stack>
                            </Grid> */}



                        <Button variant="contained" autoFocus color="inherit" onClick={handleSave} sx={{ backgroundColor: 'blue', '&:hover': { backgroundColor: 'orange' }, textAlign: 'center', margin: '25px' }}>
                            Save
                        </Button>

                    </Box>
                </Dialog>

            </React.Fragment>

        </div>


    );
}

