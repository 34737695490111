import React from 'react';
import { Typography, TextField, Grid, Link } from '@mui/material';
import Button from '@mui/material/Button';
function GoogleSettingsTab() {
  return (
    <div>
    <Typography variant="h5" gutterBottom style={{ color: 'black' }}>
      Google map api
    </Typography>
    <Grid container spacing={2} alignItems="center">
      <Grid item xs={12}>
        <TextField
          label="map api"
          variant="outlined"
          fullWidth
          placeholder="e.g. AsjasdAkjl**********"
          inputProps={{ style: { color: 'black' } }}
          InputLabelProps={{ style: { color: 'black' } }}
          sx={{
            '& .MuiOutlinedInput-root': { 
              borderColor: 'black',
              '&:hover fieldset': {
                borderColor: 'black',
              },
              '&.Mui-focused fieldset': {
                borderColor: 'black',
              },
            },
            '& .MuiOutlinedInput-input': {
              '&::placeholder': { color: 'gray' }
            }
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body1" style={{ color: 'black' }}>
          Visit <Link href="https://console.cloud.google.com" target="_blank" rel="noopener noreferrer">Google Cloud Console</Link> to get the keys
        </Typography>
      </Grid>

      <Grid item xs={12}>
          <Button variant="contained" sx={{ marginTop: '10px', marginLeft: '10px', backgroundColor: 'blue', '&:hover': { backgroundColor: 'orange' } }}>
            Save Map Api
          </Button>
        </Grid>

    </Grid>



    <Typography variant="h5" gutterBottom style={{ color: 'black' , marginTop: '20px'}}>
      Google Calander api
    </Typography>
    <Grid container spacing={2} alignItems="center">
      <Grid item xs={12}>
        <TextField
          label="Company ID"
          variant="outlined"
          fullWidth
          placeholder="e.g. AsjasdAkjl**********"
          inputProps={{ style: { color: 'black' } }}
          InputLabelProps={{ style: { color: 'black' } }}
          sx={{
            '& .MuiOutlinedInput-root': { 
              borderColor: 'black',
              '&:hover fieldset': {
                borderColor: 'black',
              },
              '&.Mui-focused fieldset': {
                borderColor: 'black',
              },
            },
            '& .MuiOutlinedInput-input': {
              '&::placeholder': { color: 'gray' }
            }
          }}
        />
      </Grid>
      <Grid item xs={12}>
      <TextField
          label="Company Secret"
          variant="outlined"
          fullWidth
          placeholder="e.g. AsjasdAkjl**********"
          inputProps={{ style: { color: 'black' } }}
          InputLabelProps={{ style: { color: 'black' } }}
          sx={{
            '& .MuiOutlinedInput-root': { 
              borderColor: 'black',
              '&:hover fieldset': {
                borderColor: 'black',
              },
              '&.Mui-focused fieldset': {
                borderColor: 'black',
              },
            },
            '& .MuiOutlinedInput-input': {
              '&::placeholder': { color: 'gray' }
            }
          }}
        />
      </Grid>
      
      <Grid item xs={12}>
          <Button variant="contained" sx={{ marginTop: '10px', marginLeft: '10px', backgroundColor: 'blue', '&:hover': { backgroundColor: 'orange' } }}>
            Save Calander Api
          </Button>
        </Grid>
    </Grid>






  </div>
  )
}

export default GoogleSettingsTab
