import React from 'react'
import MUIDataTable from "mui-datatables";
import {createTheme, ThemeProvider, Grid} from '@mui/material';

function TimeSheetView() {



    const columns = [
        {
            name: "",
            label: "Name"  // to rename column
        },
        {
            name: "",
            label: "Id"
        },
        {
            name: "",
            label: "Department"
        },
        {
            name: "",
            label: "Phone Number"
        },
        {
            name: "",
            label: "enable_disable"
        }
    ];


    const options = {
        //selectableRows: false,
        elevation: 0,
        rowsPerPage: 5,
        rowsPerPageOptions: [5, 10, 25, 100],
        tableBody: {
            style: {
                maxHeight: '500px',
                overflowY: 'scroll',
                marginLeft:'5PX'
            }
        },
        tableHead: {
            style: {
                backgroundColor: '#f2f2f2',
                color: 'black'
            }
        },
        rows: {
            style: {
                marginLeft: '5px',
                textAlign: 'center'
            },
            selectableRowsOnClick: true
        }
    };
    
       const getTheme = () =>
       createTheme({
         palette: {
           mode: 'light',
           primary: {
             main: '#2196f3',
           },
           secondary: {
             main: '#03a9f4',
           },
         },
         typography: {
           fontFamily: [
             'Roboto',
             'Helvetica',
             'Arial',
             'Sans-serif',
           ].join(','),
         },
         components: {
           MuiButton: {
             styleOverrides: {
               root: {
                 textTransform: 'none',
               },
             },
           },
         },
         tableBody: {
          style: {
              maxHeight: '500px',
              overflowY: 'scroll'
          }
      },
      tableHead: {
          style: {
              backgroundColor: '#f2f2f2',
              color: 'black'
          }
      },
      rows: {
          style: {
              '&:hover': {
                  backgroundColor: '#f2f2f2'
              }
          }
      }
       });
    




  return (
    <Grid style={{backgroundColor:"#fff",color:"black"}}>
        {/* <div style={{marginBottom:"10px"}}></div> */}
        {/* <Typography style={{paddingTop:"20px",paddingLeft:"15px",fontSize:"26px"}}>Drivers added in subscription will list here<CheckCircleIcon color="success"/></Typography> */}
        <ThemeProvider theme={getTheme()}>
       
        <Grid>
        <MUIDataTable
        fullScreen={true}
        columns={columns}
        options={options}/>
   
        </Grid>
        </ThemeProvider>
    
   </Grid>
  )
}

export default TimeSheetView