import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import loginbackground from '../../assets/loginbackground.png';
import backloginfo from '../../assets/backlog.png';
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import { useState, forwardRef , useEffect} from "react";
import Snackbar from "@mui/material/Snackbar";
import Stack from "@mui/material/Stack";
import MuiAlert from "@mui/material/Alert";
import Slide from "@mui/material/Slide";
import {  useNavigate } from "react-router-dom";


const Alert = forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });
  
  const darkTheme = createTheme({
    palette: {
      mode: "dark",
    },
  });
  
  const boxstyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "75%",
    height: "70%",
    bgcolor: "background.paper",
    boxShadow: 24,
  };
  
  const center = {
    position: "relative",
    top: "50%",
    left: "30%",
  };


  // Delete it once the project is complately done from here 




// till here

  

function LoginHelp() {

  const navigate = useNavigate();

 // Delete it once the project is complately done from here 

 const [name,setName] = useState("");
 const [password,setPassword] = useState("");
 const [email,setEmail] = useState("");

async function signUp(){
  let item={name,email,password}
  console.log(item)

 let result = await fetch("https://roadpilot.co.in/rptpl/public/api/register",{
    method:"POST",
    headers:{
      "Content-Type":"application/json",
      "Accept":"application/json"
    },
    body:JSON.stringify(item)
  });

  result = await result.json();
  localStorage.setItem("user-info",JSON.stringify(result));
  navigate("/rptpl");

}


 // till here

 useEffect(() =>{
  if(localStorage.getItem("user-info")){
    navigate("/rptpl");
  }
 });



    const [open, setOpen] = useState(false);
    const [remember, setRemember] = useState(false);
    const vertical = "top";
    const horizontal = "right";
  
    const handleSubmit = async (event) => {
      setOpen(true);
      event.preventDefault();
      const data = new FormData(event.currentTarget);
    };
  
    const handleClose = (event, reason) => {
      if (reason === "clickaway") {
        return;
      }
      setOpen(false);
    };

    function TransitionLeft(props) {
        return <Slide {...props} direction="left" />;
      }
    
  

  return (
    <>
    <Snackbar
      open={open}
      autoHideDuration={3000}
      onClose={handleClose}
      TransitionComponent={TransitionLeft}
      anchorOrigin={{ vertical, horizontal }}
    >
      <Alert onClose={handleClose} severity="error" sx={{ width: "100%" }}>
        {/* Failed! Enter correct username and password. */}
      </Alert>
    </Snackbar>
    <div
      style={{
        backgroundImage: `url(${loginbackground})`,
        backgroundSize: "cover",
        height: "100vh",
        color: "#f5f5f5",
      }}
    >
      <Box sx={boxstyle}>
        <Grid container>
          <Grid item xs={12} sm={12} lg={6}>
            <Box
              style={{
                backgroundImage: `url(${backloginfo})`,
                backgroundSize: "cover",
                marginTop: "40px",
                marginLeft: "15px",
                marginRight: "15px",
                height: "63vh",
                color: "#f5f5f5",
              }}
            ></Box>
          </Grid>
          <Grid item xs={12} sm={12} lg={6}>
            <Box
              style={{
                backgroundSize: "cover",
                height: "70vh",
                minHeight: "500px",
                backgroundColor: "#C0B476",
              }}
            >
              <ThemeProvider theme={darkTheme}>
                <Container>
                  <Box height={35} />
                  <Box sx={center}>
                    <Avatar
                      sx={{ ml: "85px", mb: "4px", bgcolor: "#ffffff" }}
                    >
                      <LockOutlinedIcon />
                    </Avatar>
                    <Typography component="h1" variant="h4">
                      Rise a Ticket
                    </Typography>
                  </Box>
                  <Box
                    component="form"
                    noValidate
                    onSubmit={handleSubmit}
                    sx={{ mt: 2 }}
                  >
                    <Grid container spacing={1}>

                    <Grid item xs={12} sx={{ ml: "3em", mr: "3em" }}>
                        <TextField
                          required
                          fullWidth
                          name="name"
                          label="Name"
                          type="text"
                          id="name"
                          autoComplete="text"
                          value={name}
                          onChange={(e)=>setName(e.target.value)}
                        />
                         </Grid>
                      <Grid item xs={12} sx={{ ml: "3em", mr: "3em" }}>
                        <TextField
                          required
                          fullWidth
                          id="email"
                          label="email"
                          name="email"
                          autoComplete="email"
                          value={email}
                          onChange={(e)=>setEmail(e.target.value)}
                        />
                      </Grid>
                      
                     
                      <Grid item xs={12} sx={{ ml: "3em", mr: "3em" }}>
                        <TextField
                          required
                          fullWidth
                          name="subject"
                          label="Subject"
                          type="Subject"
                          id="Subject"
                          value={password}
                          autoComplete="text"
                          onChange={(e)=>setPassword(e.target.value)}
                        />
                      </Grid>
                      <Grid item xs={12} sx={{ ml: "5em", mr: "5em" }}>
                        <Button
                          type="submit"
                          variant="contained"
                          fullWidth="true"
                          size="large"
                          sx={{
                            mt: "15px",
                            mr: "20px",
                            borderRadius: 28,
                            color: "#ffffff",
                            minWidth: "170px",
                            backgroundColor: "#FF9A01",
                          }}
                          // onClick={signUp}
                          onClick={signUp}
                        
                        >
                          Submit
                        </Button>
                      </Grid>
                      <Grid item xs={12} sx={{ ml: "3em", mr: "3em" }}>
                        <Stack direction="row" spacing={2}>
                          <Typography
                            variant="body1"
                            component="span"
                            style={{ marginTop: "10px" }}
                          >
                            Already have an Account?{" "}
                            <span
                              style={{ color: "#ECEC40", cursor: "pointer" }}
                              onClick={() => {
                                  navigate("/");
                                }}
                            >
                              Sign In
                            </span>
                          </Typography>
                        </Stack>
                      </Grid>
                    </Grid>
                  </Box>
                </Container>
              </ThemeProvider>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </div>
  </>
  )
}

export default LoginHelp
