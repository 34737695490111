import React, {useState} from 'react';
import {Routes, Route} from "react-router-dom";
import LoginPag from './scenes/Login/LoginPag';
import LoginHelp from './scenes/Login/LoginHelp';
import ForgotPassword from './scenes/Login/ForgotPassword';
import MainSettings from './scenes/Settings/MainSettings';
import { ColorModeContext, useMode } from './theme';
import { Box, CssBaseline, ThemeProvider } from '@mui/material';
import Events from './scenes/Events/events';
import Topbar from './scenes/global/Topbar';
import MainSidebar from './scenes/global/MainSidebar';
import AdminDashboard from './scenes/Dashboard/AdminDash/AdminDashboard';
import Sales from './scenes/Leads/Sales';
import Leads from './scenes/Leads/Leads';
import MainCompanySettings from './scenes/Settings/MainCompanySettings';
import MainBusiness from './scenes/Settings/MainBusiness';
import MainProfileSettings from './scenes/Settings/MainProfileSettings';
import DriverUser from './scenes/Users/Driver User/DriverUser';
import FleetOwnerUser from './scenes/Users/Fleet Owner User/FleetOwnerUser';
import Protected from './Data/Protected';
import Employee from './scenes/HR/Employee/Employee';
import MainTaxSettings from './scenes/Settings/MainTaxSettings';
import MainProjectSettings from './scenes/Settings/MainProjectSettings';
import MainRolesSettings from './scenes/Settings/MainRolesSettings';
import MainTicketSettings from './scenes/Settings/MainTicketSettings';
import MainAttendanceSettings from './scenes/Settings/MainAttendanceSettings';
import MainLeadsSettings from './scenes/Settings/MainLeadsSettings';
import MainPayrollSettings from './scenes/Settings/MainPayrollSettings';
import MainAssetsSettings from './scenes/Settings/MainAssetsSettings';
import MainRecruitSettings from './scenes/Settings/MainRecruitSettings';
import MainPurchaseSettings from './scenes/Settings/MainPurchaseSettings';
import MainSecuritySettings from './scenes/Settings/MainSecuritySettings';
import MainTaskSettings from './scenes/Settings/MainTaskSettings';
import MainTImeLogSetttings from './scenes/Settings/MainTImeLogSetttings';
import Dhaba from './scenes/Users/Dhaba/Dhaba';
import Mechanic from './scenes/Users/Mechanic/Mechanic';
import CraneOwner from './scenes/Users/CraneOwner/CraneOwner';
import Jobs from './scenes/Jobs/Jobs';
import Application from './scenes/Jobs/Application';
// import PayrollReport from './scenes/Payroll/PayrollReport/PayrollReport';
import StaffSalary from './scenes/Payroll/SaffSalary/StaffSalary';
import Asset from './scenes/Assets/Asset/Asset';
import Bills from './scenes/Assets/Bills/Bills';
import Products from './scenes/Assets/Products/Products';
import PurOrder from './scenes/Assets/PurOrder/PurOrder';
import Vendor from './scenes/Assets/Vendor/Vendor';
import Orders from './scenes/Assets/Orders/Orders';
import VendorPay from './scenes/Assets/VendorPay/VendorPay';
import VendorCredits from './scenes/Assets/VendorCredits/VendorCredits';
import Invento from './scenes/Assets/Inventory/Inventory';
import Proposal from './scenes/Finance/Proposal/Proposal';
import Estimates from './scenes/Finance/Estimates/Estimates';
import Invoices from './scenes/Finance/Invoices/Invoices';
import Payments from './scenes/Finance/Payments/Payments';
import CreditNote from './scenes/Finance/CreditNote/CreditNote';
import Expenses from './scenes/Finance/Expenses/Expenses';
import BankAcoount from './scenes/Finance/BankAccount/BankAccount';
import Settlement from './scenes/Finance/Settlement/Settlement';
import Wallet from './scenes/Finance/Wallet/Wallet';
import Contract from './scenes/Work/Contract/Contract';
import Projects from './scenes/Work/Projects/Projects';
import Tasks from './scenes/Work/Tasks/Tasks';
import TimeSheet from './scenes/Work/TimeSheet/TimeSheet';
import Generate from './scenes/Letter/Generate/Generate';
import Template from './scenes/Letter/Template/Template';
import Skills from './scenes/Recruit/Skills/Skills';
import RecJobs from './scenes/Recruit/RecJobs/RecJobs';
import RecApp from './scenes/Recruit/RecApp/RecApp';
import OffLetter from './scenes/Recruit/OffLetter/OffLetter';
import Jitsi from './scenes/Meetings/Jitsi/Jitsi';
import Teams from './scenes/Meetings/Teams/Teams';
import Zoom from './scenes/Meetings/Zoom/Zoom';
import Department from './scenes/HR/Department/Department';
import Designation from './scenes/HR/Designation/Designation';
import NoticeBoard from './scenes/NoticeBoard/NoticeBoard';
import KnowledgeBase from './scenes/KnowledgeBase/KnowledgeBase';
import AssetReport from './scenes/Assets/Report/AssetReport';
import TaskReport from './scenes/MainReport/TaskReport/TaskReport';
import TimelogReport from './scenes/MainReport/TimelogReport/TimelogReport';
import FinanceReport from './scenes/MainReport/FinanceReport/FinanceReport';
import IncVsExp from './scenes/MainReport/IncVsExp/IncVsExp';
import LeaveReport from './scenes/MainReport/LeaveReport/LeaveReport';
import DealReport from './scenes/MainReport/DealReport/DealReport';
import SalesReport from './scenes/MainReport/SalesReport/SalesReport';
import OtherReport from './scenes/MainReport/OtherReport/OtherReport';
import CraneOperator from './scenes/Users/CraneOperator/CraneOperator';
import Leaves from './scenes/HR/Leaves/Leaves';
import Appreciation from './scenes/HR/Appreciation/Appreciation';
import Tickets from './scenes/Tickets/Tickets';
import MainHelp from './scenes/MainHelp';
import Attendance from './scenes/HR/Attendance/Attendance';
import CanDb from './scenes/Recruit/CanDb/CanDb';
import Reports from './scenes/Recruit/Reports/Reports';
import InterSchedule from './scenes/Recruit/InterSchedule/InterSchedule';
import ShiftRoaster from './scenes/HR/ShiftRoaster/ShiftRoaster';
import Holiday from './scenes/HR/Holiday/Holiday';
import RecruitDash from './scenes/Recruit/RecruitDash/RecruitDash';
import DashboardTopBar from './scenes/Dashboard/DashboardTopBar';
import OnFieldTeam from './scenes/OnFieldTeam/OnFieldTeam';
import MainVehiclebrand from './scenes/Vehicelbrand/MainVehiclebrand';
import Convoy from './scenes/Convoy/Convoy';
import CraneBooking from './scenes/CraneBooking/CraneBooking';
import AppPosts from './scenes/AppPosts/AppPosts';
import { useNavigate } from "react-router-dom";
import Companies from './scenes/Companies/Companies';
import PayrollReport from './scenes/Payroll/PayrollReport/PayrollReport';
import EmployeeView from './scenes/HR/Employee/EmployeeView/EmployeeView';


//  const userInfo = localStorage.getItem('user-info');

function App() {

  const [theme = {}, colorMode] = useMode();
  const [isCollapsed, setIsCollapsed] = useState(false);
  // const [openMenu, setOpenMenu] = useState(null);

  // const handleMenuItemClick = (menuName) => {
  //   setOpenMenu(menuName === openMenu ? null : menuName);
  // };


    const [employee, setEmployee] = useState([
    {
      id: 1,
      name: "John Doe",
      email: "john.doe@example.com",
      age: 30,
      gender: "male",
      userRole: "Employee",
      status: "Active"
    }
  ]);

  const navigate = useNavigate();

  return (

    <div>
      {
        localStorage.getItem('user-info') ? <>
        <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />

        <div className="app">
      

          <MainSidebar  collapsed={isCollapsed} />
          <main className="content">
            <Topbar />
            <Box m="20px">

              <Routes>

               
                <Route path="/rptpl" element={<Protected cmp = {DashboardTopBar} />} />
                <Route path="/admin-dashboard" element={<Protected cmp = {AdminDashboard} />} />
                <Route path="/field_team" element={<Protected cmp = {OnFieldTeam} />} />
                <Route path="/leads/sales" element={<Protected cmp = {Sales }/>} />
                <Route path="/leads" element={<Protected cmp = {Leads} />} />
                <Route path="/companies" element={<Protected cmp = {Companies} />} />
                <Route path="/Users/Crane_Owners" element={<Protected cmp = {CraneOwner} />} />
                <Route path="/Users/Crane_Ope" element={<Protected cmp = {CraneOperator} />} />
                <Route path="/Users/mech_user" element={<Protected cmp = {Mechanic} />} />
                <Route path="/Users/fleet_Owners" element={<Protected cmp = {FleetOwnerUser} />} />
                <Route path="/Users/dhaba" element={<Protected cmp = {Dhaba} />} />
                <Route path="/Convoy" element={<Protected cmp = {Convoy} />} />
                <Route path="/Crane_booking" element={<Protected cmp = {CraneBooking} />} />
                <Route path="/Jobs/jobs" element={<Protected cmp = {Jobs} />} />
                <Route path="/Jobs/applications" element={<Protected cmp ={Application} />} />
                <Route path="/payroll/staff" element={< Protected cmp ={StaffSalary} />} />
                <Route path="/payroll/payroll" element={< Protected cmp ={PayrollReport} />} />
                <Route path="/Users/Drivers" element={<Protected cmp = {DriverUser }/>} />
                <Route path="/App_Posts" element={<Protected cmp = {AppPosts }/>} />
                <Route path="/assets" element={<Protected cmp = {Asset }/>} />
                <Route path="/assets/bills" element={<Protected cmp = {Bills}/>} />
                <Route path="/assets/products" element={<Protected cmp = {Products}/>} />
                <Route path="/assets/purchase_order" element={<Protected cmp = {PurOrder }/>} />
                <Route path="/assets/vendor" element={<Protected cmp = {Vendor }/>} />
                <Route path="/assets/orders" element={<Protected cmp = {Orders }/>} />
                <Route path="/assets/vendor_payments" element={<Protected cmp = {VendorPay }/>} />
                <Route path="/assets/vendor_credits" element={<Protected cmp = {VendorCredits }/>} />
                <Route path="/assets/Inventory" element={<Protected cmp = {Invento }/>} />
                <Route path="/assets/report" element={<Protected cmp = {AssetReport }/>} />
                <Route path="/work/contracts" element={<Protected cmp = {Contract }/>} />
                <Route path="/work/projects" element={<Protected cmp = {Projects }/>} />
                <Route path="/work/tasks" element={<Protected cmp = {Tasks }/>} />
                <Route path="/work/time_Sheet" element={<Protected cmp = {TimeSheet }/>} />
                <Route path="/finance/proposal" element={<Protected cmp = {Proposal }/>} />
                <Route path="/finance/estimate" element={<Protected cmp = {Estimates }/>} />
                <Route path="/finance/invoice" element={<Protected cmp = {Invoices }/>} />
                <Route path="/finance/payments" element={<Protected cmp = {Payments }/>} />
                <Route path="/finance/credit" element={<Protected cmp = {CreditNote }/>} />
                <Route path="/finance/expenses" element={<Protected cmp = {Expenses }/>} />
                <Route path="/finance/bank" element={<Protected cmp = {BankAcoount }/>} />
                <Route path="/finance/settlements" element={<Protected cmp = {Settlement }/>} />
                <Route path="/finance/Wallet" element={<Protected cmp = {Wallet }/>} />
                <Route path="/letter/generate" element={<Protected cmp = {Generate }/>} />
                <Route path="/letter/templete" element={<Protected cmp = {Template }/>} />
                <Route path="/recruit/skills" element={<Protected cmp = {Skills }/>} />
                <Route path="/recruit/jobs" element={<Protected cmp = {RecJobs }/>} />
                <Route path="/recruit/applications" element={<Protected cmp = {RecApp }/>} />
                <Route path="/recruit/offer" element={<Protected cmp = {OffLetter }/>} />
                <Route path="/recruit/reports" element={<Protected cmp = {Reports }/>} />
                <Route path="/recruit/interview" element={<Protected cmp = {InterSchedule }/>} />
                <Route path="/recruit/dashboard" element={<Protected cmp = {RecruitDash }/>} />
                <Route path="/recruit/candDb" element={<Protected cmp = {CanDb}/>} />
                <Route path="/hr/department" element={<Protected cmp = {Department} />} />
                <Route path="/hr/shift" element={<Protected cmp = {ShiftRoaster} />} />
                <Route path="/hr/holiday" element={<Protected cmp = {Holiday} />} />
                <Route path="/hr/designation" element={<Protected cmp = {Designation} />} />
                <Route path="/hr/employees" element={<Protected cmp = {Employee}  employee={employee} />} />
                <Route path="/hr/employees/employeesView" element={<Protected cmp = {EmployeeView} />} />
                <Route path="/hr/leaves" element={<Protected cmp = {Leaves} />} />
                <Route path="/hr/appreciation" element={<Protected cmp = {Appreciation} />} />
                <Route path="/hr/attendance" element={<Protected cmp = {Attendance} />} />
                <Route path="/tickets" element={<Protected cmp = {Tickets} />} />
                <Route path="/meetings/jitsi" element={<Protected cmp = {Jitsi }/>} />
                <Route path="/meetings/teams" element={<Protected cmp = {Teams }/>} />
                <Route path="/meetings/zoom" element={<Protected cmp = {Zoom }/>} />
                <Route path="/notice" element={<Protected cmp = {NoticeBoard }/>} />
                <Route path="/events" element={<Protected cmp = {Events} />} />
                <Route path="/knowledge" element={<Protected cmp = {KnowledgeBase }/>} />
                <Route path="/report/task" element={<Protected cmp = {TaskReport }/>} />
                <Route path="/report/time_log" element={<Protected cmp = {TimelogReport }/>} />
                <Route path="/report/finance" element={<Protected cmp = {FinanceReport }/>} />
                <Route path="/report/income" element={<Protected cmp = {IncVsExp }/>} />
                <Route path="/report/leave" element={<Protected cmp = {LeaveReport }/>} />
                <Route path="/report/deal" element={<Protected cmp = {DealReport }/>} />
                <Route path="/report/sales" element={<Protected cmp = {SalesReport }/>} />
                <Route path="/report/others" element={<Protected cmp = {OtherReport }/>} />
                <Route path="/help" element={<Protected cmp = {MainHelp} />} />
                <Route path="/App_settings" element={<Protected cmp = {MainSettings} />} />
                <Route path="/settings" element={<Protected cmp = {MainCompanySettings }/>} />
                <Route path="/Business_settings" element={<Protected cmp = {MainBusiness }/>} />   
                <Route path="/Vehicals_Data" element={<Protected cmp = {MainVehiclebrand }/>} />   
 {/* HEAD */}
                <Route path="/Profile_settings" element={<Protected cmp = {MainProfileSettings }/>} />   
                <Route path="/tax_settings" element={<Protected cmp = {MainTaxSettings }/>} />   
                <Route path="/project_settings" element={<Protected cmp = {MainProjectSettings }/>} />   
                <Route path="/rp_settings" element={<Protected cmp = {MainRolesSettings }/>} />   
                <Route path="/Ticket_settings" element={<Protected cmp = {MainTicketSettings }/>} />   
                <Route path="/Attendance_settings" element={<Protected cmp = {MainAttendanceSettings }/>} />   
                <Route path="/Leads_settings" element={<Protected cmp = {MainLeadsSettings }/>} />   
                <Route path="/Payroll_settings" element={<Protected cmp = {MainPayrollSettings }/>} />   
                <Route path="/Leave_settings" element={<Protected cmp = {MainLeadsSettings }/>} />   
                <Route path="/recruit_settings" element={<Protected cmp = {MainRecruitSettings }/>} />   
                <Route path="/assets_settings" element={<Protected cmp = {MainAssetsSettings }/>} />   
                <Route path="/purchase_settings" element={<Protected cmp = {MainPurchaseSettings }/>} />   
                <Route path="/security_settings" element={<Protected cmp = {MainSecuritySettings }/>} />   
                <Route path="/Task_settings" element={<Protected cmp = {MainTaskSettings }/>} />   
                <Route path="/time_log_settings" element={<Protected cmp = {MainTImeLogSetttings }/>} />   



                <Route path="/Profile_settings" element={<Protected cmp = {MainProfileSettings }/>} />   






              </Routes> 

             
            </Box>
          </main>
        </div>
      </ThemeProvider>
    </ColorModeContext.Provider>


        </>
       :

        <Routes>
        <Route path="/" exact element={<LoginPag />}/>
        <Route path="/Login-help" exact element={<LoginHelp />}/>
        <Route path="/reset-password" exact element={<ForgotPassword />}/>
      </Routes> 

      }

      
    </div>


    

  );
}

export default App;
