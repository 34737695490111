import React, { useState, forwardRef, useEffect } from 'react';
import { ThemeProvider, createTheme } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import loginbackground from '../../assets/loginbackground.png';
import backloginfo from '../../assets/backlog.png';
import {
  Box,
  Grid,
  Button,
  TextField,
  Typography,
  Container,
  Avatar,
  Checkbox,
  FormControlLabel,
  Snackbar,
  Slide,
  Stack,
} from '@mui/material';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import MuiAlert from '@mui/material/Alert';
import AuthUser from '../../Data/AuthUser';
import axios from '../../Data/axios';



const Alert = forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
const darktheme = createTheme({
  palette: {
    mode: 'dark',
  },
});

const boxStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '75%',
  height: '70%',
  bgcolor: 'background.paper',
  boxShadow: 24,
};

const center = {
  position: 'relative',
  top: '50%',
  left: '37%',
};

function LoginPag() {
  const [open, setOpen] = useState(false);
  const [remember, setRemember] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [validationError, setValidationError] = useState(false);
  const vertical = 'top';
  const horizontal = 'right';
  const navigate = useNavigate();
  const [invalidData, setInvalidData] = useState(false);
  const handleSubmit = (event) => {
    event.preventDefault();
    if (!email || !password) {
      setValidationError(true);
      setOpen(true);
      return;
    }else {
      setValidationError(false);
    }
    setOpen(true); // Open Snackbar for validating request
    // Proceed with login process
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  function TransitionLeft(props) {
    return <Slide {...props} direction="left" />;
  }


  useEffect(() =>{
    if(localStorage.getItem("user-info")){
      navigate("/rptpl");
    }
   });
     // Login Progress Starts here

  async function submitForm () {

    console.log(email,password);
    let item = {email,password};
    let result = await fetch("https://roadpilot.co.in/rptpl/public/api/login",{
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        "Accept":"application/json"
      },
      body: JSON.stringify(item)
    });

    result = await result.json();
    // localStorage.setItem("user-info",JSON.stringify(result));
    // navigate("/rptpl");

    if (result.error === "Email and Password is not matched") {
      // Handle the error, for example, show an error message to the user
      console.log("Login failed: Email and password do not match");
      setInvalidData(true);
    } else {
      // Login successful, store user info and navigate
      localStorage.setItem("user-info", JSON.stringify(result));
      navigate("/rptpl");
      setInvalidData(false);
    }


  };
  // Login Progress Ends here


 

  return (
    <>
      <Snackbar
        open={open}
        autoHideDuration={3000}
        onClose={handleClose}
        TransitionComponent={TransitionLeft}
        anchorOrigin={{ vertical, horizontal }}
      >
        {/* {validationError && (
          <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
            Enter the email and password
          </Alert>
        )} */}
         {validationError ? (
        <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
          Enter the email or password
        </Alert>
      ) : invalidData ? (
        <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
          Invalid data. Please check your credentials.
        </Alert>
      ) : (
        <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
          Validating your request
        </Alert>
      )}
      </Snackbar>
      <div
        style={{
          backgroundImage: `url(${loginbackground})`,
          backgroundSize: 'cover',
          height: '100vh',
          color: '#f5f5f5',
        }}
      >
        <Box sx={boxStyle}>
          <Grid container>
            <Grid item xs={12} sm={12} lg={6}>
              <Box
                style={{
                  backgroundImage: `url(${backloginfo})`,
                  backgroundSize: 'cover',
                  marginTop: '40px',
                  marginLeft: '15px',
                  marginRight: '15px',
                  height: '63vh',
                  color: '#f5f5f5',
                }}
              ></Box>
            </Grid>
            <Grid item xs={12} sm={12} lg={6}>
              <Box
                style={{
                  backgroundSize: 'cover',
                  height: '70vh',
                  minHeight: '500px',
                  backgroundColor: '#C0B476',
                }}
              >
                <ThemeProvider theme={darktheme}>
                  <Container>
                    <Box height={35} />
                    <Box sx={center}>
                      <Avatar sx={{ ml: '35px', mb: '4px', bgcolor: '#ffffff' }}>
                        <LockOpenIcon />
                      </Avatar>
                      <Typography component="h1" variant="h4">
                        Sign In
                      </Typography>
                    </Box>
                    <Box
                      component="form"
                      noValidate
                      onSubmit={handleSubmit}
                      sx={{ mt: 2 }}
                    >
                      <Grid container spacing={1}>
                        <Grid item xs={12} sx={{ ml: '3em', mr: '3em' }}>
                          <TextField
                            required
                            fullWidth
                            id="email"
                            label="Username"
                            name="email"
                            autoComplete="email"
                            onChange={(e) => setEmail(e.target.value)}
                          />
                        </Grid>
                        <Grid item xs={12} sx={{ ml: '3em', mr: '3em' }}>
                          <TextField
                            required
                            fullWidth
                            name="password"
                            label="Password"
                            type="password"
                            id="password"
                            autoComplete="new-password"
                            onChange={(e) => setPassword(e.target.value)}
                          />
                        </Grid>
                        <Grid item xs={12} sx={{ ml: '3em', mr: '3em' }}>
                          <Stack direction="row" spacing={2}>
                            <FormControlLabel
                              sx={{ width: '60%' }}
                              onClick={() => setRemember(!remember)}
                              control={<Checkbox checked={remember} />}
                              label="Remember me"
                            />
                            <Typography
                              variant="body1"
                              component="span"
                              onClick={() => {
                                navigate('/reset-password');
                              }}
                              style={{ marginTop: '10px', cursor: 'pointer' }}
                            >
                              Forgot password?
                            </Typography>
                          </Stack>
                        </Grid>
                        <Grid item xs={12} sx={{ ml: '5em', mr: '5em' }}>
                          <Button
                            type="submit"
                            variant="contained"
                            fullWidth
                            size="large"
                            sx={{
                              mt: '10px',
                              mr: '20px',
                              borderRadius: 28,
                              color: '#ffffff',
                              minWidth: '170px',
                              backgroundColor: '#FF9A01',
                            }}
                            onClick={submitForm}
                          >
                            Sign in
                          </Button>
                        </Grid>
                        <Grid item xs={12} sx={{ ml: '3em', mr: '3em' }}>
                          <Stack direction="row" spacing={2}>
                            <Typography
                              variant="body1"
                              component="span"
                              style={{ marginTop: '10px' }}
                            >
                              Any Problem in Login?{' '}
                              <span
                                style={{ color: '#ECEC40', cursor: 'pointer' }}
                                onClick={() => {
                                  navigate('/Login-help');
                                }}
                              >
                                Click Here
                              </span>
                            </Typography>
                          </Stack>
                        </Grid>
                      </Grid>
                    </Box>
                  </Container>
                </ThemeProvider>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </div>
    </>
  );
}

export default LoginPag;
