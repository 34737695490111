import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';
import { TextField, Button, TextareaAutosize } from '@mui/material';
import styled from "styled-components";
import './MainHelp.css';

export const MainHelp = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm('service_q416dvm',
        'template_zdbsk4r',
        form.current, {
        publicKey: 'hLW5mvaJ92FfHfn1T',
      })
      .then(
        (result) => {
          console.log(result.text);
          console.log("message sent");
        },
        (error) => {
          console.log('FAILED...', error.text);
        },
      );
  };

  return (
    <StyledMailToForm style={{alignContent:"center",width:"800px"}}>
      {/* <div className='py-20 min-h-screen grid place-items-center w-10/12 max-w-4xl' > */}
    
        <form className='form' ref={form} onSubmit={sendEmail}>
          <TextField className='input' name="user_name" label="Your Name" variant="outlined" focused placeholder='Your Name' fullWidth /><br />
          <TextField type="email" name="user_email" label="Your Email" variant="outlined" focused placeholder='Your emailID' fullWidth /><br />
          <TextareaAutosize name="message" placeholder="Type your queries here" autoFocus minRows={3} style={{ width: '100%' }} />
          <Button type="submit" variant="contained" color="primary">Send</Button>
        </form>
      
    </StyledMailToForm>
  );
};

export default MainHelp;

const StyledMailToForm = styled.div`
  width: 400px;
  container{
    background-image: url("../Assets/red_truck5.jpg");
    background-size: cover;
    min-height: 100vh;
    min-width: 210.5vh;
    background-position: center;
    display: flex;
    justify-content: center;
    align-items: center;
}

  form {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    height: 400px;
    width: 420px;
    font-size: 16px;
    border-radius: 10px;
    padding: 30px 40px;
    border: 2px solid rgba(255, 255, 255, .2);
    backdrop-filter: blur(20px);
    margin-left: 700px;
    margin-top: 40px;

    .MuiTextField-root {
      margin-bottom: 1rem;
    }

    .MuiButton-root {
      margin-top: 2rem;
    }
  }
`;
