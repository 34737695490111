import * as React from 'react';
import { useState } from 'react';
import Stack from '@mui/material/Stack';
import AddIcon from '@mui/icons-material/Add';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import { Box, Button, Grid } from '@mui/material';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import TextField from '@mui/material/TextField';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function Separator({ color = "black", height = 1 }) {
    return (
        <hr
            style={{
                backgroundColor: color,
                height: height,
                border: "none"
            }}
        />
    );
}
export default function AddHoliday() {
    const [open, setOpen] = React.useState(false);


    const [date, setDate] = useState(null); // Initialize state for the date
    const [occasion, setOccasion] = React.useState('');
    const [dateError, setDateError] = React.useState('');
    const [occasionError, setOccasionError] = React.useState('');

    const handleDateChange = (newDate) => {
        setDate(newDate); // Update the date state
        console.log(newDate); // Log the new date to the console
    };

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
    const handleSave = () => {
        const list = { date, occasion }
        console.log(list)
        if (date === '' && occasion === '') {
            setDateError(true);
            setOccasionError(true);
        } else {
            const list = { date, occasion };
            console.log(list);
            setDateError(false); // Reset the error state
            setOccasionError(false);
            handleClose(); // Close the dialog
        }
    };

    const [fields, setFields] = useState([{ leaveType: '', date: null }]);

    const handleAddField = () => {
        setFields([...fields, { leaveType: '', date: null }]);
    };

    const handleLeaveTypeChange = (index, value) => {
        const newFields = [...fields];
        newFields[index].leaveType = value;
        setFields(newFields);
    };

    // const handleDateChange = (index, value) => {
    //     const newFields = [...fields];
    //     newFields[index].date = value;
    //     setFields(newFields);
    // };
    return (
        <div>
            <React.Fragment>
                <Stack direction="row" spacing={2}>
                    <Button variant="contained" onClick={handleClickOpen} sx={{ backgroundColor: 'blue', '&:hover': { backgroundColor: 'orange' }, textAlign: 'center', marginLeft: '10px' }}><AddIcon />Add Holiday</Button>
                </Stack>
                <Dialog
                    fullScreen
                    open={open}
                    onClose={handleClose}
                    TransitionComponent={Transition}
                    PaperProps={{
                        style: {
                            backgroundColor: 'grey',
                        },
                    }}
                >

                    <AppBar sx={{ position: 'relative' }}>
                        <Toolbar>
                            <IconButton
                                edge="start"
                                color="inherit"
                                onClick={handleClose}
                                aria-label="close"
                            >
                                <CloseIcon />
                            </IconButton>
                            <Typography sx={{ ml: 2, flex: 1 }} component="div">
                                Holiday
                            </Typography>

                        </Toolbar>

                    </AppBar>
                    <Box sx={{ padding: "30px", margin: "20px", backgroundColor: "white" }}>

                        <Typography sx={{ ml: 2, flex: 1, color: 'black', fontSize: "20px" }} component="div" >
                            Add Holiday
                        </Typography>
                        <hr sx={{ color: 'grey' }} />

                        {/* <Separator color="grey" height="2px" /> */}
                        <Grid container spacing={2} sx={{ marginTop: "20px", padding: "20px" }}>
                            {/* {fields.map((field, index) => ( */}
                                <Stack direction="row" sx={{ width: '100%' }}>
                                    <Grid item xs={12} md={4} sm={4} sx={{ paddingRight: { xs: 0, sm: 2 } }}>
                                        <Stack direction="column">
                                            <Typography variant="p" gutterBottom style={{ color: 'black' }}>
                                                Occasion<span style={{ color: 'red' }}>*</span>
                                            </Typography>
                                            <TextField
                                                id="outlined-basic"
                                                variant="outlined"
                                                onChange={(e) => setOccasion(e.target.value)}
                                                sx={{
                                                    border: '1px solid black',
                                                    boxSizing: 'border-box',
                                                    '& .MuiOutlinedInput-root': {
                                                        borderColor: 'black !important',
                                                        '&:hover fieldset': {
                                                            borderColor: 'black !important',
                                                        },
                                                        '&.Mui-focused fieldset': {
                                                            borderColor: 'black !important',
                                                        },
                                                    },
                                                    color: "black"
                                                }} />
                                        </Stack>
                                    </Grid>
                                    <Grid item xs={12} md={4} sm={4} sx={{ paddingRight: { xs: 0, sm: 2 } }}>
                                        <Stack direction="column">
                                            <Typography variant="p" gutterBottom style={{ color: 'black' }}>
                                                Date
                                            </Typography>
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <DemoContainer
                                                    components={['DatePicker']}
                                                    onChange={(e) => handleDateChange(e.target.value)}
                                                    sx={{ marginTop: "-10px" }}
                                                >
                                                    <DatePicker
                                                        value={date}
                                                        onChange={handleDateChange}
                                                        sx={{
                                                            border: '1px solid black',
                                                            '& .MuiOutlinedInput-root': {
                                                                borderColor: 'black !important',
                                                                color: "black !important",
                                                                '&:hover fieldset': {
                                                                    borderColor: 'black !important',
                                                                },
                                                                '&.Mui-focused fieldset': {
                                                                    borderColor: 'black !important',
                                                                },
                                                            },
                                                            '& .MuiSvgIcon-root': {
                                                                color: 'black',
                                                            },
                                                        }}
                                                    >
                                                                                                            {/* TextField component now becomes a child of DatePicker */}
                                                    {(params) => (
                                                        <TextField
                                                            {...params}
                                                            variant="outlined"
                                                        // Add any additional TextField props if needed
                                                        />
                                                    )}
                                                    </DatePicker>
                                                </DemoContainer>

                                            </LocalizationProvider>
                                        </Stack>
                                    </Grid>
                                </Stack>
                            {/* ))} */}
                            <Grid item xs={12} sx={{ display: 'flex' }}>
                                <Stack direction="row">

                                    <IconButton onClick={handleAddField}>
                                        <AddIcon style={{ color: 'blue' }} />
                                    </IconButton>
                                    <Typography variant="p" gutterBottom style={{ color: 'blue', marginTop: '7px', fontSize: "15px" }}>
                                        Leave Type
                                    </Typography>
                                </Stack>
                            </Grid>
                        </Grid>

                        <Button variant="contained" autoFocus color="inherit" onClick={handleSave} sx={{ backgroundColor: 'blue', '&:hover': { backgroundColor: 'orange' }, textAlign: 'center', margin: '25px' }}>
                            Save
                        </Button>

                    </Box>
                </Dialog>

            </React.Fragment>

        </div >


    );
}

