import * as React from 'react';
import Stack from '@mui/material/Stack';
import AddIcon from '@mui/icons-material/Add';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import { Box, Button, TextField, Grid, createTheme, ThemeProvider } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';




const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function AddDepartment() {
    const [open, setOpen] = React.useState(false);
    const [name, setName] = React.useState('');
    const [parent, setParent] = React.useState('');
    const [nameError, setNameError] = React.useState('');
    
    const handleName = (event) => {
        setName(event.target.value);
      };
    
    const handleParent = (event) => {
        setParent(event.target.value);
      };

    
    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
   
    const handleSave = () => {
        const list = { name,parent }
        console.log(list)
        if (name === '') {
            setNameError(true);
        } else {
            const list = { name,parent };
            console.log(list);
            setNameError(false); // Reset the error state
            handleClose(); // Close the dialog
        }
    };

    return (
        <div>
            <React.Fragment>
                <Stack direction="row" spacing={2}>
                    <Button variant="contained" onClick={handleClickOpen} sx={{ backgroundColor: 'blue', '&:hover': { backgroundColor: 'orange' }, textAlign: 'center', marginLeft: '10px' }}><AddIcon />Add Department</Button>
                    {/* <Button variant="contained" disabled>
        Disabled
      </Button>
      <Button variant="contained" href="#contained-buttons">
        Link
      </Button> */}
                </Stack>
                <Dialog
                    fullScreen
                    open={open}
                    onClose={handleClose}
                    TransitionComponent={Transition}
                    PaperProps={{
                        style: {
                            backgroundColor: 'white',
                        },
                    }}
                >

<AppBar sx={{ position: 'relative' }}>
                        <Toolbar>
                            <IconButton
                                edge="start"
                                color="inherit"
                                onClick={handleClose}
                                aria-label="close"
                            >
                                <CloseIcon />
                            </IconButton>
                            <Typography sx={{ ml: 2, flex: 1 }} component="div">
                                Departemnt
                            </Typography>

                        </Toolbar>

                    </AppBar>
                    <Box sx={{ padding: "30px", margin: "20px", backgroundColor: "white" }}>

                        <Typography sx={{ ml: 2, flex: 1, color: 'black', fontSize: "20px" }} component="div">
                            Add Department
                        </Typography>
                        <hr sx={{ color: 'gray', }} />

                        <Grid container spacing={2} sx={{ marginTop: "20px", padding: "20px" }}>
                            <Stack direction="row" sx={{ width: '100%' }}>
                                <Grid item xs={12} md={4} sm={4} sx={{ paddingRight: { xs: 0, sm: 2 } }}>
                                    <Stack direction="column">
                                        <Typography variant="p" gutterBottom style={{ color: 'black' }}>
                                            Name<span style={{ color: 'red' }}>*</span>
                                        </Typography>
                                        <TextField id="outlined-basic"  variant="outlined" value={name} 
                                       onChange={(e) => setName(e.target.value)}
                                        placeholder='eg.Human Resource' 
                                        inputProps={{ 
                                            style: { color: 'black' } 
                                        }}
                                        sx={{
                                            border: '1px solid black',
                                            boxSizing: 'border-box',
                                            '& .MuiOutlinedInput-root': {
                                                borderColor: 'black !important',
                                                '&:hover fieldset': {
                                                    borderColor: 'black !important',
                                                },
                                                '&.Mui-focused fieldset': {
                                                    borderColor: 'black !important',
                                                },
                                            },
                                            color: "black"
                                        }} />
                                        {nameError && <Typography variant="caption" style={{ color: 'red' }}>Please select a name</Typography>}
                                    </Stack>
                                </Grid>
                                <Grid item xs={12} md={4} sm={4} sx={{ paddingRight: { xs: 0, sm: 2 } }}>
                                    <Stack direction="column">
                                        <Typography variant="p" gutterBottom style={{ color: 'black' }}>
                                            Parent
                                        </Typography>
                                        <Select
                    value={parent}
                    autoFocus
                    onChange={(e) => setParent(e.target.value)}
                    displayEmpty
                    inputProps={{ 'aria-label': 'Without label' }}
                    sx={{
                        border: '1px solid black',
                        boxSizing: 'border-box',
                        '& .MuiOutlinedInput-root': {
                            borderColor: 'black !important',
                            '&:hover fieldset': {
                                borderColor: 'black !important',
                            },
                            '&.Mui-focused fieldset': {
                                borderColor: 'black !important',
                            },
                        },
                        color: "black"
                    }}
                >
                    <MenuItem disabled value="">
                        <em>---</em>
                    </MenuItem>
                    <MenuItem value={'CEO'}>CEO</MenuItem>
                    <MenuItem value={'BD Manager'}>BD Manager</MenuItem>
                    <MenuItem value={'BD Associate'}>BD Associate</MenuItem>
                    <MenuItem value={'BD Developer'}>BD Developer</MenuItem>
                    <MenuItem value={'BD Executive'}>BD Executive</MenuItem>
                    <MenuItem value={'BD Intern'}>BD Intern</MenuItem>
                </Select>
                                    </Stack>
                                </Grid>
                            </Stack>
                        </Grid>
                        <Button variant="contained" autoFocus color="inherit" onClick={handleSave} sx={{ backgroundColor: 'blue', '&:hover': { backgroundColor: 'orange' }, textAlign: 'center', margin: '25px' }}>
                            Save
                        </Button>


                    </Box>
                </Dialog>

            </React.Fragment>

        </div>


    );
}

