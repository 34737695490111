import React, { useState } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import listPlugin from '@fullcalendar/list';
import { Box, List, ListItem, ListItemText, Typography, useTheme } from '@mui/material';
import { tokens } from '../.././../theme';
import { formatDate } from '@fullcalendar/core';
import AddHoliday from './AddHoliday';


function Holiday() {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [currentEvents, setCurrentEvents] = useState([]);
  
    const handleDateClick = (selected) => {
      const title = prompt('Please enter a new holiday for you');
      const calendarApi = selected.view.calendar;
      calendarApi.unselect();
  
      if (title) {
        calendarApi.addEvent({
          id: `${selected.dateStr}-${title}`,
          title,
          start: selected.startStr,
          end: selected.endStr,
          allDay: selected.allDay,
        });
      }
    };
  
    const handleEventClick = (selected) => {
      if (
        window.confirm(
          `Are you sure you want to delete the holiday '${selected.event.title}'`
        )
      ) {
        selected.event.remove();
      }
    };
  
    return (
      <Box>
          <Box flex="1 1 20%" backgroundColor={colors.primary[400]} p="15px" borderRadius="4px">
            <AddHoliday></AddHoliday>
          </Box>
          <Box flex="1 1 100%" ml="15px">
            <FullCalendar
              height="75vh"
              plugins={[
                dayGridPlugin,
                timeGridPlugin,
                interactionPlugin,
                listPlugin,
              ]}
              headerToolbar={{
                left: "prev,next today",
                center: "title",
                right: "dayGridMonth,timeGridWeek,timeGridDay,listMonth",
              }}
              initialView="dayGridMonth"
              editable={true}
              selectable={true}
              selectMirror={true}
              dayMaxEvents={true}
              select={handleDateClick}
              //eventClick={handleEventClick}
              // eventsSet={(events) => setCurrentEvents(events)}
              // initialEvents={[
              //   {
              //     id: "12315",
              //     title: "All-day event",
              //     date: "2022-09-14",
              //   },
              //   {
              //     id: "5123",
              //     title: "Timed event",
              //     date: "2022-09-28",
              //   },
              // ]}
            />
          </Box>
          
        </Box>
    );
  }
  
  export default Holiday;