import React from 'react'
import ProfileTabs from './ProfileTabs'

function ProfileSettings() {
  return (
    <div>
      <ProfileTabs />
    </div>
  )
}

export default ProfileSettings
