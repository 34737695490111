import React from 'react';
import { Box, Button , TextField, Grid, createTheme, ThemeProvider} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import ListItemText from '@mui/material/ListItemText';
import MUIDataTable from "mui-datatables";
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const columns = ["Name", "Relation", "Number", "Action"];

function Contracts() {

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  // Table Starts

  const data = [
    ["Joe James", "Test Corp", "Yonkers", "NY"],
    ["John Walsh", "Test Corp", "Hartford", "CT"],
    ["Bob Herm", "Test Corp", "Tampa", "FL"],
    ["James Houston", "Test Corp", "Dallas", "TX"],
   ];
   
   const options = {
    //selectableRows: false,
    elevation: 0,
    rowsPerPage: 5,
    rowsPerPageOptions: [5, 10, 25, 100],
    tableBody: {
        style: {
            maxHeight: '500px',
            overflowY: 'scroll',
            marginLeft:'5PX'
        }
    },
    tableHead: {
        style: {
            backgroundColor: '#f2f2f2',
            color: 'black'
        }
    },
    rows: {
        style: {
            marginLeft: '5px',
            textAlign: 'center'
        },
        selectableRowsOnClick: true
    }
};

   const getTheme = () =>
   createTheme({
     palette: {
       mode: 'light',
       primary: {
         main: '#2196f3',
       },
       secondary: {
         main: '#03a9f4',
       },
     },
     typography: {
       fontFamily: [
         'Roboto',
         'Helvetica',
         'Arial',
         'Sans-serif',
       ].join(','),
     },
     components: {
       MuiButton: {
         styleOverrides: {
           root: {
             textTransform: 'none',
           },
         },
       },
     },
     tableBody: {
      style: {
          maxHeight: '500px',
          overflowY: 'scroll'
      }
  },
  tableHead: {
      style: {
          backgroundColor: '#f2f2f2',
          color: 'black'
      }
  },
  rows: {
      style: {
          '&:hover': {
              backgroundColor: '#f2f2f2'
          }
      }
  }
   });


  // Table Ends

  return (
    <div>

    <List style={{ background: 'none', padding: 0 }}>
          <ListItemText style={{ background: 'none', margin: 5 }}>
          <React.Fragment>
   
   <Button variant="contained" onClick={handleClickOpen} sx={{ backgroundColor: 'blue', '&:hover': { backgroundColor: 'orange' }, textAlign: 'center', marginLeft: '10px' }}>
     <AddIcon/> Add Contacts
 </Button>
   <Dialog
     fullScreen
     open={open}
     onClose={handleClose}
     TransitionComponent={Transition}
     PaperProps={{
      style: {
        backgroundColor: 'white',
      },
    }}
   >
     <AppBar sx={{ position: 'relative' }}>
       <Toolbar>
         <IconButton
           edge="start"
           color="inherit"
           onClick={handleClose}
           aria-label="close"
         >
           <CloseIcon />
         </IconButton>
         <Typography sx={{ ml: 2, flex: 1 }} component="div">
          Add New Emergency Contact 
         </Typography>
         
       </Toolbar>
     </AppBar>
    
    <Box>

    <Grid container spacing={2} style={{ color: 'black', margin:"10px" }}>
         
         <Grid item xs={8} sm={4}>
         <Typography variant="p" gutterBottom style={{ color: 'black' }}>
   Name
 </Typography>
 <Grid container spacing={2} alignItems="center">
   <Grid item xs={12}>
     <TextField
       label="enter name"
       variant="outlined"
       fullWidth
       placeholder="e.g.Name"
       inputProps={{ style: { color: 'black' } }}
       InputLabelProps={{ style: { color: 'black' } }}
       sx={{
         '& .MuiOutlinedInput-root': { 
           borderColor: 'black',
           '&:hover fieldset': {
             borderColor: 'black',
           },
           '&.Mui-focused fieldset': {
             borderColor: 'black',
           },
         },
         '& .MuiOutlinedInput-input': {
           '&::placeholder': { color: 'gray' }
         }
       }}
     />
   </Grid>

 </Grid>

         </Grid>
         <Grid item xs={12} sm={6}>
         <Typography variant="p" gutterBottom style={{ color: 'black' }}>
   Personal Email
 </Typography>
 <Grid container spacing={2} alignItems="center">
   <Grid item xs={12}>
     <TextField
       label="enter email"
       variant="outlined"
       fullWidth
       placeholder="eg@example.com"
       type="email"
       inputProps={{ style: { color: 'black' } }}
       InputLabelProps={{ style: { color: 'black' } }}
       sx={{
         '& .MuiOutlinedInput-root': { 
           borderColor: 'black',
           '&:hover fieldset': {
             borderColor: 'black',
           },
           '&.Mui-focused fieldset': {
             borderColor: 'black',
           },
         },
         '& .MuiOutlinedInput-input': {
           '&::placeholder': { color: 'gray' }
         }
       }}
     />
   </Grid>

 </Grid>

         </Grid>
        
       </Grid>

       <Grid container spacing={2} style={{ color: 'black', margin:"10px" }}>
         
         <Grid item xs={8} sm={4}>
         <Typography variant="p" gutterBottom style={{ color: 'black' }}>
   Mobile No
 </Typography>
 <Grid container spacing={2} alignItems="center">
   <Grid item xs={12}>
     <TextField
       label="enter Mobile No"
       variant="outlined"
       fullWidth
       placeholder="e.g.+91 12345689"
       inputProps={{ style: { color: 'black' } }}
       InputLabelProps={{ style: { color: 'black' } }}
       sx={{
         '& .MuiOutlinedInput-root': { 
           borderColor: 'black',
           '&:hover fieldset': {
             borderColor: 'black',
           },
           '&.Mui-focused fieldset': {
             borderColor: 'black',
           },
         },
         '& .MuiOutlinedInput-input': {
           '&::placeholder': { color: 'gray' }
         }
       }}
     />
   </Grid>

 </Grid>

         </Grid>
         <Grid item xs={12} sm={6}>
         <Typography variant="p" gutterBottom style={{ color: 'black' }}>
   Relaction
 </Typography>
 <Grid container spacing={2} alignItems="center">
   <Grid item xs={12}>
     <TextField
       label="enter Relaction"
       variant="outlined"
       fullWidth
       placeholder="e.g. Father/Mother"
       type="email"
       inputProps={{ style: { color: 'black' } }}
       InputLabelProps={{ style: { color: 'black' } }}
       sx={{
         '& .MuiOutlinedInput-root': { 
           borderColor: 'black',
           '&:hover fieldset': {
             borderColor: 'black',
           },
           '&.Mui-focused fieldset': {
             borderColor: 'black',
           },
         },
         '& .MuiOutlinedInput-input': {
           '&::placeholder': { color: 'gray' }
         }
       }}
     />
   </Grid>

 </Grid>

         </Grid>
        
       </Grid>


       <Grid container spacing={2} style={{ color: 'black', margin:"10px" }}>
         
         <Grid item xs={10} sm={10}>
         <Typography variant="p" gutterBottom style={{ color: 'black' }}>
   Address
 </Typography>
 <Grid container spacing={2} alignItems="center">
   <Grid item xs={12}>
     <TextField
       label="enter Address"
       variant="outlined"
       fullWidth
       placeholder="e.g.Address"
       inputProps={{ style: { color: 'black' } }}
       InputLabelProps={{ style: { color: 'black' } }}
       sx={{
         '& .MuiOutlinedInput-root': { 
           borderColor: 'black',
           '&:hover fieldset': {
             borderColor: 'black',
           },
           '&.Mui-focused fieldset': {
             borderColor: 'black',
           },
         },
         '& .MuiOutlinedInput-input': {
           '&::placeholder': { color: 'gray' }
         }
       }}
     />
   </Grid>

 </Grid>

         </Grid>
        
       </Grid>

         <Button variant="contained" autoFocus color="inherit" onClick={handleClose} sx={{ backgroundColor: 'blue', '&:hover': { backgroundColor: 'orange' }, textAlign: 'center', margin: '25px' }}>
      Save
 </Button>

    </Box>


   </Dialog>

          </React.Fragment>
          </ListItemText>
          <Divider />
          <ListItemText style={{ background: 'none', margin: 5 }}> 
            
          <ThemeProvider theme={getTheme()}>
                <MUIDataTable
                  title={"Employee List"}
                  data={data}
                  columns={columns}
                  options={options}
                />
          </ThemeProvider>

          </ListItemText>
        </List>
      
    </div>
  )
}

export default Contracts
