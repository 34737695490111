import React, { useEffect, useState } from 'react'
import 'tailwindcss/tailwind.css';
import './Ticket.css'
import MUIDataTable from "mui-datatables";
import { createTheme } from "@mui/material/styles"
import { ThemeProvider } from '@mui/styles';
import MenuItem from '@mui/material/MenuItem'; 
import FormControl from '@mui/material/FormControl';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {IconButton, Menu } from '@mui/material';
import { styled } from '@mui/material/styles';
import Switch from '@mui/material/Switch';
import AddTicket from './AddTicket';
import Grid from '@mui/material/Grid';
import { Box, Typography } from '@mui/material';
import Paper from '@mui/material/Paper';
import LocalActivityIcon from '@mui/icons-material/LocalActivity';
function Tickets() {

    const [users, setUsers] = useState([])
    const [value, setValue] = React.useState('active');
     // State to manage dropdown menu
   const [anchorEl, setAnchorEl] = useState(null);
   const [showPopup, setShowPopup] = useState(false);
  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleViewClick = () => {
    setShowPopup(true); // Open the pop-up window when "View" is clicked
    handleCloseMenu(); // Close the menu
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

    const handleChange = (event) => {
      setValue(event.target.value);
    };
    const AntSwitch = styled(Switch)(({ theme }) => ({
        width: 28,
        height: 16,
        padding: 0,
        display: 'flex',
        '&:active': {
          '& .MuiSwitch-thumb': {
            width: 15,
          },
          '& .MuiSwitch-switchBase.Mui-checked': {
            transform: 'translateX(9px)',
          },
        },
        '& .MuiSwitch-switchBase': {
          padding: 2,
          '&.Mui-checked': {
            transform: 'translateX(12px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
              opacity: 1,
              backgroundColor: theme.palette.mode === 'dark' ? '#177ddc' : '#1890ff',
            },
          },
        },
        '& .MuiSwitch-thumb': {
          boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
          width: 12,
          height: 12,
          borderRadius: 6,
          transition: theme.transitions.create(['width'], {
            duration: 200,
          }),
        },
        '& .MuiSwitch-track': {
          borderRadius: 16 / 2,
          opacity: 1,
          backgroundColor:
            theme.palette.mode === 'dark' ? 'rgba(255,255,255,.35)' : 'rgba(0,0,0,.25)',
          boxSizing: 'border-box',
        },
      }));
    const columns = [
          {
              name:"",
              label:"Ticket"  // to rename column
          },
        {
            name:"",
            label:"Ticket subject"
          },
          {
            name:"",
            label:"Requestor Name"
          },
          {
            name:"",
            label:"Requested On"
          },
          {
            name:"", //use this for status active or inactive
            label:"Others"
          },
        {
            name: "status",
            label: "Status"
        },
        {
            name: "action",
            label: "Action",
            options: {
                customBodyRender: (value, tableMeta) => (
                    <FormControl>
                        <IconButton onClick={handleMenuClick}>
                      <MoreVertIcon />
                    </IconButton>
                    <Menu
                      anchorEl={anchorEl}
                      open={Boolean(anchorEl)}
                      onClose={handleCloseMenu}
                    >
                      <MenuItem onClick={handleViewClick}>View</MenuItem>
                      <MenuItem onClick={handleCloseMenu}>Edit</MenuItem>
                      <MenuItem onClick={handleCloseMenu}>Delete</MenuItem>
                      {/* <MenuItem onClick={handleCloseMenu}>Change to Client</MenuItem> */}
                      <MenuItem onClick={handleCloseMenu}>Add Follow up</MenuItem>
                    </Menu>
                    </FormControl>
                ) 
            } 
        }
    ];

    const handleStatusChange = (event, rowIndex) => {
        const newUsers = [...users];
        newUsers[rowIndex].status = event.target.value;
        setUsers(newUsers);
    };
    const Item = styled(Paper)(({ theme }) => ({
      backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
      ...theme.typography.body2,
      padding: theme.spacing(1),
      textAlign: 'center',
      color: theme.palette.text.secondary,
  }));

// const handleStatusChange = (event, rowIndex) => {
//     const { value } = event.target;
//     setUsers(prevUsers => {
//         const updatedUsers = [...prevUsers];
//         updatedUsers[rowIndex].status = value;
//         return updatedUsers;
//     });
// };

    useEffect(() => {
        fetch('https://dummyjson.com/users')
            .then(res => res.json())
            .then((data) => {
                let local = data?.users?.map((user) => ({
                    ...user,
                    name: user?.firstName + " " + user?.lastName,
                    // status: "inactive"
                }))

                

                // console.log(local)
// >>>>>>> ecca5bc8c9627b5dcdda097fb8db0f5c2d6e3231
                setUsers(local);
            })
    })

    const options = {
        //   filterType: 'checkbox',
        //selectableRows: false,
        elevation: 0,
        rowsPerPage: 5,
        rowsPerPageOptions: [5, 10, 20, 30],
    };

    const getMuiTheme = () =>
        createTheme({
            typography: {
                fontFamily: "Poppins",
            },
            palette: {
                background: {
                    paper: "#1e293b",
                    default: "0f172a",
                },
                mode: "dark",
            },
            components: {
                MuiTableCell: {
                    styleOverrides: {
                        head: {
                            padding: "10px 4px",
                        },
                        body: {
                            padding: "7px 15px",
                            color: "#e2e8f0",
                        },
                    }
                }
            }
        })

    return (
        <div className='py-10 min-h-screen grid place-items-center'>
            <Grid container spacing={6}>
                        <Grid item xs={6} md={2.4}>
                            <Item style={{ height: '120px', backgroundColor: "#121212" }}>
                                <Typography gutterBottom variant="subtitle1" style={{ fontSize: "20px" }}>
                                    Total Tickets
                                </Typography>
                                <Typography variant="body2" gutterBottom style={{ textAlign: "right" }}>
                                <LocalActivityIcon></LocalActivityIcon>
                                </Typography>
                                <Typography variant="body2" style={{ fontSize: "16px" }} color="blue">
                                    275
                                </Typography>
                            </Item>
                        </Grid>
                        <Grid item xs={6} md={2.4}>
                            <Item style={{ height: '120px', backgroundColor: "#121212" }}>
                            <Typography gutterBottom variant="subtitle1" style={{ fontSize: "20px" }}>
                                    Open Tickets
                                </Typography>
                                <Typography variant="body2" gutterBottom style={{ textAlign: "right" }}>
                                <LocalActivityIcon></LocalActivityIcon>
                                </Typography>
                                <Typography variant="body2" style={{ fontSize: "16px" }} color="blue">
                                    42
                                </Typography>
                            </Item>
                        </Grid>
                        <Grid item xs={6} md={2.4}>
                            <Item style={{ height: '120px', backgroundColor: "#121212" }}>
                            <Typography gutterBottom variant="subtitle1" style={{ fontSize: "20px" }}>
                                    Closed Tickets
                                </Typography>
                                <Typography variant="body2" gutterBottom style={{ textAlign: "right" }}>
                                <LocalActivityIcon></LocalActivityIcon>
                                </Typography>
                                <Typography variant="body2" style={{ fontSize: "16px" }} color="blue">
                                    140
                                </Typography>
                            </Item>
                        </Grid>
                        <Grid item xs={6} md={2.4}>
                            <Item style={{ height: '120px', backgroundColor: "#121212" }}>
                            <Typography gutterBottom variant="subtitle1" style={{ fontSize: "20px" }}>
                                    Pending Tickets
                                </Typography>
                                <Typography variant="body2" gutterBottom style={{ textAlign: "right" }}>
                                <LocalActivityIcon></LocalActivityIcon>
                                </Typography>
                                <Typography variant="body2" style={{ fontSize: "16px" }} color="blue">
                                    140
                                </Typography>
                            </Item>
                        </Grid>
                        <Grid item xs={6} md={2.4}>
                            <Item style={{ height: '120px', backgroundColor: "#121212" }}>
                            <Typography gutterBottom variant="subtitle1" style={{ fontSize: "20px" }}>
                                    Resolved Tickets
                                </Typography>
                                <Typography variant="body2" gutterBottom style={{ textAlign: "right" }}>
                                <LocalActivityIcon></LocalActivityIcon>
                                </Typography>
                                <Typography variant="body2" style={{ fontSize: "16px" }} color="blue">
                                    12
                                </Typography>
                            </Item>
                        </Grid>
                    </Grid>
                    <br></br>
            <AddTicket></AddTicket>
            <br></br>
            <div className='w-10/12 max-w-4xl'>
                <ThemeProvider theme={getMuiTheme()}>
                    <MUIDataTable
                        title={"Users List"}
                        data={users}
                        columns={columns}
                        options={options}
                    />
                </ThemeProvider>
            </div>

        </div>
    )
}

export default Tickets;