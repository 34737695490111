import * as React from 'react';
import Stack from '@mui/material/Stack';
import AddIcon from '@mui/icons-material/Add';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import { Box, Button, TextField, Grid } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import FormControlLabel from '@mui/material/FormControlLabel';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import HelpIcon from '@mui/icons-material/Help';
import 'quill-emoji/dist/quill-emoji.css'; // Import Quill emoji CSS
import Quill from 'quill';
import { emojiIcon } from 'quill-emoji';
import FormGroup from '@mui/material/FormGroup';
import Checkbox from '@mui/material/Checkbox';


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function AddContract() {
    const [open, setOpen] = React.useState(false);
    const [contractNo, setContractNo] = React.useState('');
    const [subject, setSubject] = React.useState('');
    const [project, setProject] = React.useState('');
    const [description, setDescription] = React.useState('');
    const [startDate, setStartDate] = React.useState(null);
    const [endDate, setEndDate] = React.useState(null);
    const [contractType, setContractType] = React.useState('');
    const [contractValue, setContractValue] = React.useState('');
    const [currency, setCurrency] = React.useState('');
    const [clients, setClients] = React.useState('');
    const [cell, setCell] = React.useState('');
    const [offcPhone, setOffcPhone] = React.useState('');
    const [city, setCity] = React.useState('');
    const [state, setState] = React.useState('');
    const [country, setCountry] = React.useState('');
    const [postalCode, setPostalCode] = React.useState('');
    const [address, setAddress] = React.useState('');
    const [notes, setNotes] = React.useState('');
    const [contractNoError, setContractNoError] = React.useState('');

    const Monthly = 'Monthly';
    const Yearly = 'Yearly';
    const androidApplication = 'Android Application';
    const reactApplication = 'React Application';
    const inr = 'INR (Rs.)';
    const us = 'US($)';
    const euro = 'EURO';
    const david = 'David';
    const chrissy = 'Chrissy';

    // const handleContractNo = (event) => {
    //     setContractNo(event.target.value);
    // };

    const handleStartDateChange = (newStartDate) => {
        setStartDate(newStartDate); // Update the date state
        console.log(newStartDate); // Log the new date to the console
    };

    const handleEndDateChange = (newEndDate) => {
        setEndDate(newEndDate); // Update the date state
        console.log(newEndDate); // Log the new date to the console
    };

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleSave = () => {
        const list = { contractNo,subject,project,description,startDate,endDate,contractType,contractValue,currency,clients,cell,offcPhone,city,state,country,postalCode }
        console.log(list)
        if (contractNo === '') {
            setContractNoError(true);
        } else {
            const list = { contractNo,subject,project,description,startDate,endDate,contractType,contractValue,currency,clients,cell,offcPhone,city,state,country,postalCode };
            console.log(list);
            setContractNoError(false); // Reset the error state
            handleClose(); // Close the dialog
        }
    };

    // const CustomToolbar = () => (
    //     <div id="toolbar">
    //         <button className="ql-emoji">
    //             {emojiIcon}
    //         </button>
    //     </div>
    // );

    // Register emoji module
    //Quill.register('modules/emoji', require('quill-emoji').default);

    return (
        <div>
            <React.Fragment>
                <Stack direction="row" spacing={2}>
                    <Button variant="contained" onClick={handleClickOpen} sx={{ backgroundColor: 'blue', '&:hover': { backgroundColor: 'orange' }, textAlign: 'center', marginLeft: '10px' }}><AddIcon />Add New Contract</Button>
                    {/* <Button variant="contained" disabled>
        Disabled
      </Button>
      <Button variant="contained" href="#contained-buttons">
        Link
      </Button> */}
                </Stack>
                <Dialog
                    fullScreen
                    open={open}
                    onClose={handleClose}
                    TransitionComponent={Transition}
                    PaperProps={{
                        style: {
                            backgroundColor: 'white',
                        },
                    }}
                >

                    <AppBar sx={{ position: 'relative' }}>
                        <Toolbar>
                            <IconButton
                                edge="start"
                                color="inherit"
                                onClick={handleClose}
                                aria-label="close"
                            >
                                <CloseIcon />
                            </IconButton>
                            <Typography sx={{ ml: 2, flex: 1 }} component="div">
                                Add Contact
                            </Typography>

                        </Toolbar>

                    </AppBar>
                    <Box sx={{ padding: "30px", margin: "20px", backgroundColor: "white" }}>

                        <Typography sx={{ ml: 2, flex: 1, color: 'black', fontSize: "20px" }} component="div">
                            Contract Details
                        </Typography>
                        <hr sx={{ color: 'gray', }} />

                        <Grid container spacing={2} sx={{ marginTop: "20px", padding: "20px" }}>
                            <Stack direction="row" sx={{ width: '100%' }}>
                                <Grid item xs={12} md={4} sm={4} sx={{ paddingRight: { xs: 0, sm: 2 } }}>
                                    <Stack direction="column">
                                        <Typography variant="p" gutterBottom style={{ color: 'black' }}>
                                            Contract Number<span style={{ color: 'red' }}>*</span>
                                        </Typography>
                                        <TextField id="outlined-basic" variant="outlined" value={contractNo} 
                                        onChange={(e) => setContractNo(e.target.value)} 
                                        placeholder='eg.Human Resource'
                                            inputProps={{
                                                style: { color: 'black' }
                                            }}
                                            sx={{
                                                border: '1px solid black',
                                                boxSizing: 'border-box',
                                                '& .MuiOutlinedInput-root': {
                                                    borderColor: 'black !important',
                                                    '&:hover fieldset': {
                                                        borderColor: 'black !important',
                                                    },
                                                    '&.Mui-focused fieldset': {
                                                        borderColor: 'black !important',
                                                    },
                                                },
                                                color: "black"
                                            }} />
                                    </Stack>
                                </Grid>
                                <Grid item xs={12} md={4} sm={4} sx={{ paddingRight: { xs: 0, sm: 2 } }}>
                                    <Stack direction="column">
                                        <Typography variant="p" gutterBottom style={{ color: 'black' }}>
                                            Subject<span style={{ color: 'red' }}>*</span>
                                        </Typography>
                                        <TextField id="outlined-basic" variant="outlined" 
                                        onChange={(e) => setSubject(e.target.value)}
                                        placeholder='eg.Human Resource'
                                            inputProps={{
                                                style: { color: 'black' }
                                            }}
                                            sx={{
                                                border: '1px solid black',
                                                boxSizing: 'border-box',
                                                '& .MuiOutlinedInput-root': {
                                                    borderColor: 'black !important',
                                                    '&:hover fieldset': {
                                                        borderColor: 'black !important',
                                                    },
                                                    '&.Mui-focused fieldset': {
                                                        borderColor: 'black !important',
                                                    },
                                                },
                                                color: "black",
                                                width: "100%"
                                            }} />
                                    </Stack>
                                </Grid>
                            </Stack>
                        </Grid>
                        <Grid item xs={6} sx={{ paddingRight: { xs: 0, sm: 2 } }}>
                            <Stack direction="column">
                                <Typography variant="p" gutterBottom style={{ color: 'black' }}>
                                    Project
                                </Typography>
                                <Select
                                    value={project}
                                    autoFocus
                                    // onChange={handleStatus}
                                    onChange={(e) => setProject(e.target.value)}
                                    displayEmpty
                                    inputProps={{ 'aria-label': 'Without label' }}
                                    sx={{
                                        border: '1px solid black',
                                        boxSizing: 'border-box',
                                        '& .MuiOutlinedInput-root': {
                                            borderColor: 'black !important',
                                            '&:hover fieldset': {
                                                borderColor: 'black !important',
                                            },
                                            '&.Mui-focused fieldset': {
                                                borderColor: 'black !important',
                                            },
                                        },
                                        color: "black",
                                        width: "50%"
                                    }}
                                >   
                                    <MenuItem value={''}>---</MenuItem>
                                    <MenuItem value={'Android Application'}>Android Application</MenuItem>
                                    <MenuItem value={'React Application'}>React Application</MenuItem>
                                </Select>
                            </Stack>
                        </Grid>
                        <Grid item xs={12} md={4} sm={4} sx={{ paddingRight: { xs: 0, sm: 2 } }}>
                            <Stack direction="column" sx={{ width: "100%" }}>
                                <Typography variant="p" gutterBottom style={{ color: 'black' }}>
                                    Description
                                </Typography>
                                
                                <ReactQuill
                                    value={description}
                                    onChange={(e) => setDescription(e.target.value)}
                                    style={{
                                        // border: '1px solid black',
                                        // borderRadius: '4px',
                                        //padding: '10px',
                                        width: '100%',
                                        boxSizing: 'border-box',
                                        color: 'black',
                                        // minHeight: '100px' // Set your desired minimum height
                                    }}
                                />
                            </Stack>
                        </Grid>
                        <Grid container spacing={2} sx={{ marginTop: "20px", padding: "20px" }}>
                            <Stack direction="row" sx={{ width: "100%" }}>
                                <Grid item xs={12} md={4} sm={4} sx={{ paddingRight: { xs: 0, sm: 2 } }}>
                                    <Stack direction="column">
                                        <Typography variant="p" gutterBottom style={{ color: 'black' }}>
                                            Start Date<span style={{ color: 'red' }}>*</span>
                                        </Typography>
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DemoContainer
                                                components={['DatePicker']}
                                                onChange={(e) => handleStartDateChange(e.target.value)}
                                                sx={{ marginTop: "-10px" }}
                                            >
                                                <DatePicker
                                                    value={startDate}
                                                    onChange={handleStartDateChange}
                                                    sx={{
                                                        border: '1px solid black',
                                                        '& .MuiOutlinedInput-root': {
                                                            borderColor: 'black !important',
                                                            color: "black !important",
                                                            '&:hover fieldset': {
                                                                borderColor: 'black !important',
                                                            },
                                                            '&.Mui-focused fieldset': {
                                                                borderColor: 'black !important',
                                                            },
                                                        },
                                                        '& .MuiSvgIcon-root': {
                                                            color: 'black',
                                                        },
                                                    }}
                                                >
                                                    {/* TextField component now becomes a child of DatePicker */}
                                                    {(params) => (
                                                        <TextField
                                                            {...params}
                                                            variant="outlined"
                                                        // Add any additional TextField props if needed
                                                        />
                                                    )}
                                                </DatePicker>
                                            </DemoContainer>

                                        </LocalizationProvider>
                                    </Stack>
                                </Grid>
                                <Grid item xs={12} md={4} sm={4} sx={{ paddingRight: { xs: 0, sm: 2 } }}>
                                    <Stack direction="column">
                                        <Typography variant="p" gutterBottom style={{ color: 'black' }}>
                                            End Date
                                        </Typography>
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DemoContainer
                                                components={['DatePicker']}
                                                onChange={(e) => handleEndDateChange(e.target.value)}
                                                sx={{ marginTop: "-10px" }}
                                            >
                                                <DatePicker
                                                    value={endDate}
                                                    onChange={handleEndDateChange}
                                                    sx={{
                                                        border: '1px solid black',
                                                        '& .MuiOutlinedInput-root': {
                                                            borderColor: 'black !important',
                                                            color: "black !important",
                                                            '&:hover fieldset': {
                                                                borderColor: 'black !important',
                                                            },
                                                            '&.Mui-focused fieldset': {
                                                                borderColor: 'black !important',
                                                            },
                                                        },
                                                        '& .MuiSvgIcon-root': {
                                                            color: 'black',
                                                        },
                                                    }}
                                                >
                                                    {/* TextField component now becomes a child of DatePicker */}
                                                    {(params) => (
                                                        <TextField
                                                            {...params}
                                                            variant="outlined"
                                                        // Add any additional TextField props if needed
                                                        />
                                                    )}
                                                </DatePicker>
                                            </DemoContainer>

                                        </LocalizationProvider>
                                    </Stack>
                                </Grid>
                                <Grid item xs={12} md={4} sm={4} sx={{ paddingRight: { xs: 0, sm: 2 },marginTop:"20px" }}>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox defaultChecked />} label="Without Due Date" style={{ color: 'black' }} />
                                    </FormGroup>
                                </Grid>
                                <Grid item xs={12} md={4} sm={4} sx={{ paddingRight: { xs: 0, sm: 2 }}}>
                                    <Stack direction="column">
                                        <Typography variant="p" gutterBottom style={{ color: 'black' }}>
                                            Contract Type
                                        </Typography>
                                        <Select
                                            value={contractType}
                                            autoFocus
                                            onChange={(e) => setContractType(e.target.value)}
                                            displayEmpty
                                            inputProps={{ 'aria-label': 'Without label' }}
                                            sx={{
                                                border: '1px solid black',
                                                boxSizing: 'border-box',
                                                '& .MuiOutlinedInput-root': {
                                                    borderColor: 'black !important',
                                                    '&:hover fieldset': {
                                                        borderColor: 'black !important',
                                                    },
                                                    '&.Mui-focused fieldset': {
                                                        borderColor: 'black !important',
                                                    },
                                                },
                                                color: "black"
                                            }}
                                        >
                                            <MenuItem value={''}>---</MenuItem>
                                            <MenuItem value={'Monthly'}>Monthly</MenuItem>
                                            <MenuItem value={'Yearly'}>Yearly</MenuItem>
                                        </Select>
                                    </Stack>
                                </Grid>
                            </Stack>
                        </Grid>
                        <Grid container spacing={2} sx={{ marginTop: "20px", padding: "20px" }}>
                            <Stack direction="row" sx={{ width: "100%" }}>
                                <Grid item xs={12} md={4} sm={4} sx={{ paddingRight: { xs: 0, sm: 2 } }}>
                                    <Stack direction="column">
                                        <Typography variant="p" gutterBottom style={{ color: 'black' }}>
                                            Contract Value<span style={{ color: 'red' }}>*</span><HelpIcon></HelpIcon>
                                        </Typography>
                                        <TextField id="outlined-basic" variant="outlined" 
                                        onChange={(e) => setContractValue(e.target.value)}
                                        placeholder='eg.Human Resource'
                                            inputProps={{
                                                style: { color: 'black' }
                                            }}
                                            sx={{
                                                border: '1px solid black',
                                                boxSizing: 'border-box',
                                                '& .MuiOutlinedInput-root': {
                                                    borderColor: 'black !important',
                                                    '&:hover fieldset': {
                                                        borderColor: 'black !important',
                                                    },
                                                    '&.Mui-focused fieldset': {
                                                        borderColor: 'black !important',
                                                    },
                                                },
                                                color: "black"
                                            }} />
                                    </Stack>
                                </Grid>
                                <Grid item xs={12} md={4} sm={4} sx={{ paddingRight: { xs: 0, sm: 2 } }} >
                                    <Stack direction="column">
                                        <Typography variant="p" gutterBottom style={{ color: 'black' }}>
                                            Currency
                                        </Typography>
                                        <Select
                                            value={currency}
                                            autoFocus
                                            onChange={(e) => setCurrency(e.target.value)}
                                            displayEmpty
                                            inputProps={{ 'aria-label': 'Without label' }}
                                            sx={{
                                                border: '1px solid black',
                                                boxSizing: 'border-box',
                                                '& .MuiOutlinedInput-root': {
                                                    borderColor: 'black !important',
                                                    '&:hover fieldset': {
                                                        borderColor: 'black !important',
                                                    },
                                                    '&.Mui-focused fieldset': {
                                                        borderColor: 'black !important',
                                                    },
                                                },
                                                color: "black"
                                            }}
                                        >
                                            <MenuItem value={''}>---</MenuItem>
                                            <MenuItem value={'INR (Rs.)'}>INR (Rs.)</MenuItem>
                                            <MenuItem value={'US($)'}>US($)</MenuItem>
                                            <MenuItem value={'EURO'}>EURO</MenuItem>
                                        </Select>
                                    </Stack>
                                </Grid>
                            </Stack>
                        </Grid>
                        <hr sx={{ color: 'gray', }} />
                        <Typography sx={{ ml: 2, flex: 1, color: 'black', fontSize: "20px" }} component="div">
                            Client Details
                        </Typography>
                        <Grid container spacing={2} sx={{ marginTop: "20px", padding: "20px" }}>
                            <Stack direction="row" sx={{ width: '100%' }}>
                                <Grid item xs={12} md={4} sm={4} sx={{ paddingRight: { xs: 0, sm: 2 } }} >
                                    <Stack direction="column">
                                        <Typography variant="p" gutterBottom style={{ color: 'black' }}>
                                        Clients<span style={{ color: 'red' }}>*</span>
                                        </Typography>
                                        <Select
                                            value={clients}
                                            autoFocus
                                            onChange={(e) => setClients(e.target.value)}
                                            displayEmpty
                                            inputProps={{ 'aria-label': 'Without label' }}
                                            sx={{
                                                border: '1px solid black',
                                                boxSizing: 'border-box',
                                                '& .MuiOutlinedInput-root': {
                                                    borderColor: 'black !important',
                                                    '&:hover fieldset': {
                                                        borderColor: 'black !important',
                                                    },
                                                    '&.Mui-focused fieldset': {
                                                        borderColor: 'black !important',
                                                    },
                                                },
                                                color: "black"
                                            }}
                                        >
                                            <MenuItem disabled value="">
                                                <em>---</em>
                                            </MenuItem>
                                            <MenuItem value={'David'}>David</MenuItem>
                                            <MenuItem value={'Chrissy'}>Chrissy</MenuItem>
                                        </Select>
                                    </Stack>
                                </Grid>
                                <Grid item xs={12} md={4} sm={4} sx={{ paddingRight: { xs: 0, sm: 2 } }}>
                                    <Stack direction="column">
                                        <Typography variant="p" gutterBottom style={{ color: 'black' }}>
                                            Cell
                                        </Typography>
                                        <TextField id="outlined-basic" variant="outlined" 
                                        onChange={(e) => setCell(e.target.value)}
                                        placeholder='eg.Human Resource'
                                            inputProps={{
                                                style: { color: 'black' }
                                            }}
                                            sx={{
                                                border: '1px solid black',
                                                boxSizing: 'border-box',
                                                '& .MuiOutlinedInput-root': {
                                                    borderColor: 'black !important',
                                                    '&:hover fieldset': {
                                                        borderColor: 'black !important',
                                                    },
                                                    '&.Mui-focused fieldset': {
                                                        borderColor: 'black !important',
                                                    },
                                                },
                                                color: "black"
                                            }} />
                                    </Stack>
                                </Grid>
                                <Grid item xs={12} md={4} sm={4} sx={{ paddingRight: { xs: 0, sm: 2 } }}>
                                    <Stack direction="column">
                                        <Typography variant="p" gutterBottom style={{ color: 'black' }}>
                                            Office Phone Number
                                        </Typography>
                                        <TextField id="outlined-basic" variant="outlined" 
                                        onChange={(e) => setOffcPhone(e.target.value)}
                                        placeholder='eg. +123456789'
                                            inputProps={{
                                                style: { color: 'black' }
                                            }}
                                            sx={{
                                                border: '1px solid black',
                                                boxSizing: 'border-box',
                                                '& .MuiOutlinedInput-root': {
                                                    borderColor: 'black !important',
                                                    '&:hover fieldset': {
                                                        borderColor: 'black !important',
                                                    },
                                                    '&.Mui-focused fieldset': {
                                                        borderColor: 'black !important',
                                                    },
                                                },
                                                color: "black"
                                            }} />
                                    </Stack>
                                </Grid>
                            </Stack>
                        </Grid>
                        <Grid container spacing={2} sx={{ marginTop: "20px", padding: "20px" }}>
                            <Stack direction="row" sx={{ width: '100%' }}>
                                <Grid item xs={12} md={4} sm={4} sx={{ paddingRight: { xs: 0, sm: 2 } }}>
                                    <Stack direction="column">
                                        <Typography variant="p" gutterBottom style={{ color: 'black' }}>
                                            City
                                        </Typography>
                                        <TextField id="outlined-basic" variant="outlined" 
                                        onChange={(e) => setCity(e.target.value)}
                                        placeholder='eg.Human Resource'
                                            inputProps={{
                                                style: { color: 'black' }
                                            }}
                                            sx={{
                                                border: '1px solid black',
                                                boxSizing: 'border-box',
                                                '& .MuiOutlinedInput-root': {
                                                    borderColor: 'black !important',
                                                    '&:hover fieldset': {
                                                        borderColor: 'black !important',
                                                    },
                                                    '&.Mui-focused fieldset': {
                                                        borderColor: 'black !important',
                                                    },
                                                },
                                                color: "black"
                                            }} />
                                    </Stack>
                                </Grid>
                                <Grid item xs={12} md={4} sm={4} sx={{ paddingRight: { xs: 0, sm: 2 } }}>
                                    <Stack direction="column">
                                        <Typography variant="p" gutterBottom style={{ color: 'black' }}>
                                            State
                                        </Typography>
                                        <TextField id="outlined-basic" variant="outlined" 
                                        onChange={(e) => setState(e.target.value)}
                                        placeholder='eg.New York'
                                            inputProps={{
                                                style: { color: 'black' }
                                            }}
                                            sx={{
                                                border: '1px solid black',
                                                boxSizing: 'border-box',
                                                '& .MuiOutlinedInput-root': {
                                                    borderColor: 'black !important',
                                                    '&:hover fieldset': {
                                                        borderColor: 'black !important',
                                                    },
                                                    '&.Mui-focused fieldset': {
                                                        borderColor: 'black !important',
                                                    },
                                                },
                                                color: "black"
                                            }} />
                                    </Stack>
                                </Grid>
                                <Grid item xs={12} md={4} sm={4} sx={{ paddingRight: { xs: 0, sm: 2 } }}>
                                    <Stack direction="column">
                                        <Typography variant="p" gutterBottom style={{ color: 'black' }}>
                                            Country
                                        </Typography>
                                        <TextField id="outlined-basic" variant="outlined" 
                                        onChange={(e) => setCountry(e.target.value)}
                                        placeholder='eg.Dubai'
                                            inputProps={{
                                                style: { color: 'black' }
                                            }}
                                            sx={{
                                                border: '1px solid black',
                                                boxSizing: 'border-box',
                                                '& .MuiOutlinedInput-root': {
                                                    borderColor: 'black !important',
                                                    '&:hover fieldset': {
                                                        borderColor: 'black !important',
                                                    },
                                                    '&.Mui-focused fieldset': {
                                                        borderColor: 'black !important',
                                                    },
                                                },
                                                color: "black"
                                            }} />
                                    </Stack>
                                </Grid>
                                <Grid item xs={12} md={4} sm={4} sx={{ paddingRight: { xs: 0, sm: 2 } }}>
                                    <Stack direction="column">
                                        <Typography variant="p" gutterBottom style={{ color: 'black' }}>
                                            Postal Code
                                        </Typography>
                                        <TextField id="outlined-basic" variant="outlined" 
                                        onChange={(e) => setPostalCode(e.target.value)}
                                        placeholder='eg.12345'
                                            inputProps={{
                                                style: { color: 'black' }
                                            }}
                                            sx={{
                                                border: '1px solid black',
                                                boxSizing: 'border-box',
                                                '& .MuiOutlinedInput-root': {
                                                    borderColor: 'black !important',
                                                    '&:hover fieldset': {
                                                        borderColor: 'black !important',
                                                    },
                                                    '&.Mui-focused fieldset': {
                                                        borderColor: 'black !important',
                                                    },
                                                },
                                                color: "black"
                                            }} />
                                    </Stack>
                                </Grid>
                            </Stack>
                        </Grid>
                        <Grid container spacing={2} sx={{ marginTop: "20px", padding: "20px" }}>
                            <Stack direction="row" spacing={2}  sx={{ width: '100%' }}>
                                <Grid container>
                                    <Stack direction="column" sx={{ width: "100%" }}>
                                        <Typography variant="p" gutterBottom style={{ color: 'black' }}>
                                            Alternate Address
                                        </Typography>
                                        <textarea
                                            id="reason"
                                            value={address}
                                            onChange={(e) => setAddress(e.target.value)}
                                            style={{
                                                border: '1px solid black',
                                                borderRadius: '4px',
                                                padding: '10px',
                                                width: '100%',
                                                boxSizing: 'border-box',
                                                minHeight: '100px' // Set your desired minimum height
                                            }}
                                        />
                                    </Stack>
                                </Grid>
                                <Grid container>
                                    <Stack direction="column" sx={{ width: "100%" }}>
                                        <Typography variant="p" gutterBottom style={{ color: 'black' }}>
                                            Notes
                                        </Typography>
                                        <textarea
                                            id="reason"
                                            value={notes}
                                            onChange={(e) => setNotes(e.target.value)}
                                            style={{
                                                border: '1px solid black',
                                                borderRadius: '4px',
                                                padding: '10px',
                                                width: '100%',
                                                boxSizing: 'border-box',
                                                minHeight: '100px' // Set your desired minimum height
                                            }}
                                        />
                                    </Stack>
                                </Grid>;
                            </Stack>
                        </Grid>
                        <Button variant="contained" autoFocus onClick={handleSave} color="inherit" sx={{ backgroundColor: 'blue', '&:hover': { backgroundColor: 'orange' }, textAlign: 'center', margin: '25px' }}>
                            Save
                        </Button>

                    </Box>
                </Dialog>

            </React.Fragment>

        </div>


    );
}

