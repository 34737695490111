import { Box } from '@mui/material';
import React from 'react';
import './CompanySettings.css';

function CompanySettings() {
  return (
    <Box sx={{display:"flex"}} component="div">
      <div className='companysettings_container'>
      <h1>Company Settings</h1>
      <hr></hr>
      <form>
      <div className="row">
                <div className="column">
                    <label id="name">Company Name</label>
                    <input type="text" id="name" placeholder="Road Pilot Technologies PVT LTD"/>
                </div>
                <div className="column">
                    <label id="email">Company Email</label>
                    <input type="email" id="email" placeholder="contact@info.roadpilot.co.in"/>
                </div>
            </div>
            <div className="row">
                <div className="column">
                    <label id="subject">Company Phone Number</label>
                    <input type="text" id="subject" placeholder="+91 1234567890"/>
                </div>
                <div className="column">
                    <label id="contact">Company Website </label>
                    <input type="tel" id="contact" placeholder="https://roadpilot.co.in/"/>
                </div>
            </div>
            <hr></hr>
            <button>Submit</button>
        </form>

      </div>
    </Box>
  )
}

export default CompanySettings;
