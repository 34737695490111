import React from 'react';
import SettingsSidebar from './SettingsSidebar';
import { ColorModeContext, useMode } from "../../theme";
import { Box, CssBaseline, ThemeProvider, Card, CardContent } from "@mui/material";

function MainAttendanceSettings() {
    const [theme = {}, colorMode] = useMode();
  return (
    <ColorModeContext.Provider value={colorMode}>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <div className="app">

        <SettingsSidebar/>

        <main className="content">
          
          <Box m="20px" sx={{display:"flex"}}>
           
           <Card sx={{ backgroundColor: '#ffffff' }}>
              <CardContent>
                <Box sx={{ width: '100%' }}>
                  
                 
                
                
                </Box>
                </CardContent>
          </Card>
            
       
          </Box>
         
        </main>  
      </div>
    </ThemeProvider>
  </ColorModeContext.Provider>
  )
}

export default MainAttendanceSettings
