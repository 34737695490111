import * as React from 'react';
import { useState, useRef, useEffect } from 'react';
import Stack from '@mui/material/Stack';
import AddIcon from '@mui/icons-material/Add';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import { Box, Button, Grid } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import HelpIcon from '@mui/icons-material/Help';
import 'quill-emoji/dist/quill-emoji.css'; // Import Quill emoji CSS
import Quill from 'quill';
import { emojiIcon } from 'quill-emoji'; // Import emojiIcon from quill-emoji
import TextField from '@mui/material/TextField';



const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function AddAppreciation() {
    const [open, setOpen] = React.useState(false);
    const [award, setAward] = React.useState('');
    const [givenTo, setGivenTo] = React.useState('');
    const [date, setDate] = React.useState(null);
    const [awardError, setAwardError] = React.useState(false);
    const [givenToError, setGivenToError] = useState('');
    const [dateError, setDateError] = useState('');
    const [summary, setSummary] = useState('');
    const [photo, setPhoto] = useState('');

    // const handleFileChange = (e) => {
    //     const file = e.target.files[0];
    //     setFileName(file.name); // Update file name
    //     console.log(file.name); // Print file name to console
    // };



    // Event handler to update the date state and log the date to the console
    const handleDateChange = (newDate) => {
        setDate(newDate); // Update the date state
        console.log(newDate); // Log the new date to the console
    };



    const handleChange = (value) => {
        setSummary(value); // Update the state with the new value
       // console.log(value); // Print the value to the console
    };

    const handlePhoto = (e) => {
        const file = e.target.files[0];
        setPhoto(file.name);
            console.log(file.name);// You can also upload the file to a server if needed
    };



    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
    const handleSave = () => {
        const list = { award, givenTo, date, summary, photo }
        console.log(list)
        if (award === '') {
            setAwardError(true);
        } else {
            const list = { award, givenTo, date, summary, photo };
            console.log(list);
            setAwardError(false); // Reset the error state
            handleClose(); // Close the dialog
        }
    };
    
    // const SuppressWarnings = ({ children }) => {
    //     // Suppress specific warning
    //     console.error = () => {};
    //     return children;
    //   };
    // // Ref to track changes in the editor
    // const editorRef = useRef(null);

    // // Effect to observe changes in the editor
    // useEffect(() => {
    //     const editorNode = editorRef.current;
    //     if (editorNode) {
    //         // Create a new MutationObserver
    //         const observer = new MutationObserver((mutationsList) => {
    //             // Handle mutations
    //             for (const mutation of mutationsList) {
    //                 if (mutation.type === 'childList' && mutation.addedNodes.length > 0) {
    //                     // Handle added nodes if needed
    //                 }
    //             }
    //         });

    //         // Start observing the editor node for changes
    //         observer.observe(editorNode, { childList: true });

    //         // Cleanup function to disconnect the observer
    //         return () => {
    //             observer.disconnect();
    //         };
    //     }
    // }, []);
    // Register emoji toolbar icon
    // const CustomToolbar = () => (
    //     <div id="toolbar">
    //         <button className="ql-emoji">
    //             {emojiIcon}
    //         </button>
    //     </div>
    // );

    // Register emoji module
    //Quill.register('modules/emoji', require('quill-emoji').default);

    // Set Quill toolbar options
    // const modules = {
    //     toolbar: {
    //         container: '#toolbar',
    //         handlers: {
    //             emoji: function () {
    //                 // Show emoji picker
    //                 const range = this.quill.getSelection();
    //                 this.quill.emojiPicker.show(range);
    //             }
    //         }
    //     },
    //     emoji: true // Enable emoji module
    // };

    return (
        <div>
            <React.Fragment>
                <Stack direction="row" spacing={2}>
                    <Button variant="contained" onClick={handleClickOpen} sx={{ backgroundColor: 'blue', '&:hover': { backgroundColor: 'orange' }, textAlign: 'center', marginLeft: '10px' }}><AddIcon />Add Appreciation</Button>
                </Stack>
                <Dialog
                    fullScreen
                    open={open}
                    onClose={handleClose}
                    TransitionComponent={Transition}
                    PaperProps={{
                        style: {
                            backgroundColor: 'white',
                        },
                    }}
                >

                    <AppBar sx={{ position: 'relative' }}>
                        <Toolbar>
                            <IconButton
                                edge="start"
                                color="inherit"
                                onClick={handleClose}
                                aria-label="close"
                            >
                                <CloseIcon />
                            </IconButton>
                            <Typography sx={{ ml: 2, flex: 1 }} component="div">
                                Appreciation
                            </Typography>

                        </Toolbar>

                    </AppBar>
                    <Box sx={{ padding: "30px", margin: "20px", backgroundColor: "white" }}>

                        <Typography sx={{ ml: 2, flex: 1, color: 'black', fontSize: "20px" }} component="div">
                            Add Appreciation
                        </Typography>
                        <hr sx={{ color: 'gray', }} />

                        <Grid container spacing={2} sx={{ marginTop: "20px", padding: "20px" }}>
                            <Stack direction="row" sx={{ width: '100%' }}>
                                <Grid item xs={12} md={4} sm={4} sx={{ paddingRight: { xs: 0, sm: 2 } }}>
                                    <Stack direction="column">
                                        <Typography variant="p" gutterBottom style={{ color: 'black' }}>
                                            Award <span style={{ color: 'red' }}>*</span>
                                        </Typography>
                                        <Select
                                            value={award}
                                            autoFocus
                                            onChange={(e) => setAward(e.target.value)}
                                            displayEmpty
                                            inputProps={{ 'aria-label': 'Without label' }}
                                            sx={{
                                                border: '1px solid black',
                                                boxSizing: 'border-box',
                                                '& .MuiOutlinedInput-root': {
                                                    borderColor: 'black !important',
                                                    '&:hover fieldset': {
                                                        borderColor: 'black !important',
                                                    },
                                                    '&.Mui-focused fieldset': {
                                                        borderColor: 'black !important',
                                                    },
                                                },
                                                color: "black"
                                            }}
                                        >
                                            <MenuItem disabled value="">
                                                <em>---</em>
                                            </MenuItem>
                                            <MenuItem value={'Gold'}>Gold</MenuItem>
                                            <MenuItem value={'Silver'}>Silver</MenuItem>
                                            <MenuItem value={'Bronze'}>Bronze</MenuItem>
                                        </Select>
                                        {awardError && <Typography variant="caption" style={{ color: 'red' }}>Please select an award</Typography>}
                                    </Stack>
                                </Grid>
                                <Grid item xs={12} md={4} sm={4} sx={{ paddingRight: { xs: 0, sm: 2 } }}>
                                    <Stack direction="column">
                                        <Typography variant="p" gutterBottom style={{ color: 'black' }}>
                                            Given To<span style={{ color: 'red' }}>*</span>
                                        </Typography>
                                        <Select
                                            value={givenTo}
                                            autoFocus
                                            onChange={(e) => setGivenTo(e.target.value)}
                                            displayEmpty
                                            inputProps={{ 'aria-label': 'Without label' }}
                                            sx={{
                                                border: '1px solid black',
                                                boxSizing: 'border-box',
                                                '& .MuiOutlinedInput-root': {
                                                    borderColor: 'black !important',
                                                    '&:hover fieldset': {
                                                        borderColor: 'black !important',
                                                    },
                                                    '&.Mui-focused fieldset': {
                                                        borderColor: 'black !important',
                                                    },
                                                },
                                                color: "black"
                                            }}
                                        >
                                            <MenuItem disabled value="">
                                                <em>---</em>
                                            </MenuItem>
                                            <MenuItem value={'Andrew'}>Andrew</MenuItem>
                                            <MenuItem value={'Bohr'}>Bohr</MenuItem>
                                            <MenuItem value={'Ceaser'}>Ceaser</MenuItem>
                                        </Select>
                                    </Stack>
                                </Grid>
                                <Grid item xs={12} md={4} sm={4} sx={{ paddingRight: { xs: 0, sm: 2 } }}>
                                <Stack direction="column">
                                        <Typography variant="p" gutterBottom style={{ color: 'black' }}>
                                            Date
                                        </Typography>
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DemoContainer
                                                components={['DatePicker']}
                                                onChange={(e) => handleDateChange(e.target.value)}
                                                sx={{ marginTop: "-10px" }}
                                            >
                                                <DatePicker
                                                    value={date}
                                                    onChange={handleDateChange}
                                                    sx={{
                                                        border: '1px solid black',
                                                        '& .MuiOutlinedInput-root': {
                                                            borderColor: 'black !important',
                                                            color: "black !important",
                                                            '&:hover fieldset': {
                                                                borderColor: 'black !important',
                                                            },
                                                            '&.Mui-focused fieldset': {
                                                                borderColor: 'black !important',
                                                            },
                                                        },
                                                        '& .MuiSvgIcon-root': {
                                                            color: 'black',
                                                        },
                                                    }}
                                                >
                                                        {/* TextField component now becomes a child of DatePicker */}
                                                        {(params) => (
                                                        <TextField
                                                            {...params}
                                                            variant="outlined"
                                                        // Add any additional TextField props if needed
                                                        />
                                                    )}
                                                </DatePicker>
                                            </DemoContainer>

                                        </LocalizationProvider>
                                    </Stack>
                                </Grid>
                            </Stack>
                        </Grid>


                        <Grid container sx={{ padding: "20px" }}>
                            <Stack direction="column" sx={{ width: "100%" }}>
                                <Typography variant="p" gutterBottom style={{ color: 'black' }}>
                                    Summary<span style={{ color: 'red' }}>*</span>
                                </Typography>
                                {/* <CustomToolbar style={{ color: 'black' }} /> Render custom emoji toolbar */}
                                <ReactQuill
                                    value={summary}
                                    onChange={handleChange}
                                    // modules={modules}
                                    // theme="snow"
                                    style={{
                                       // border: '1px solid black',
                                        // borderRadius: '4px',
                                        //padding: '10px',
                                        width: '100%',
                                        boxSizing: 'border-box',
                                        color: 'black',
                                        borderBlockColor:"black"
                                        // minHeight: '100px' // Set your desired minimum height
                                    }}
                                />
                                {/* <SuppressWarnings>
                                  <ReactQuill
                                    value={summary}
                                    onChange={handleChange}
                                    style={{
                                      width: '100%',
                                      boxSizing: 'border-box',
                                      color: 'black',
                                      borderBlockColor: 'black'
                                    }}
                                  />
                                </SuppressWarnings> */}
                            </Stack>
                        </Grid>
                        <Grid container sx={{ padding: "20px" }}>
                            <Stack direction="column" sx={{ width: "100%" }}>
                                <Typography variant="p" gutterBottom style={{ color: 'black' }}>
                                    Photo <HelpIcon></HelpIcon>
                                </Typography>
                                <label htmlFor="file-upload"
                                    style={{
                                        cursor: 'pointer', color: 'black',
                                        border: '1px solid black',
                                        borderRadius: '4px',
                                        padding: '10px',
                                        width: '100%',
                                        boxSizing: 'border-box',
                                        minHeight: '100px', // Set your desired minimum height
                                        backgroundColor: 'lightgray', // Change background color to light gray
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                    }}>
                                    Choose a File
                                </label>
                                <input
                                    onChange={handlePhoto} // Call handleFileChange when file changes
                                    type="file"
                                    id="file-upload"
                                    style={{ display: 'none' }}
                                />
                                {photo && <div style={{ color: 'black' }}>{photo}</div>}
                            </Stack>
                        </Grid>


                        <Button variant="contained" autoFocus color="inherit" onClick={handleSave} sx={{ backgroundColor: 'blue', '&:hover': { backgroundColor: 'orange' }, textAlign: 'center', margin: '25px' }}>
                            Save
                        </Button>

                    </Box>
                </Dialog>

            </React.Fragment>

        </div>


    );
}

