import React, { useEffect, useState } from 'react'
import { Box, Grid, Button } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import 'tailwindcss/tailwind.css';
import './PayrollReport.css'
import MUIDataTable from "mui-datatables";
import { createTheme } from "@mui/material/styles"
import { ThemeProvider } from '@mui/styles';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { IconButton, Menu } from '@mui/material';
import { styled } from '@mui/material/styles';
import Switch from '@mui/material/Switch';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
// import AddPayReport from './AddPayroll';
import InputLabel from '@mui/material/InputLabel';
import {SendSharp } from '@mui/icons-material';
// import AddPayReport from './AddPayReport';


function PayrollReport() {

  const [users, setUsers] = useState([])
  // const [value, setValue] = React.useState('active');
  // State to manage dropdown menu
  const [anchorEl, setAnchorEl] = useState(null);
  // const [showPopup, setShowPopup] = useState(false);
  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleViewClick = () => {
    // setShowPopup(true); // Open the pop-up window when "View" is clicked
    handleCloseMenu(); // Close the menu
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  // const handleChange = (event) => {
  //   setValue(event.target.value);
  // };
  const AntSwitch = styled(Switch)(({ theme }) => ({
    width: 28,
    height: 16,
    padding: 0,
    display: 'flex',
    '&:active': {
      '& .MuiSwitch-thumb': {
        width: 15,
      },
      '& .MuiSwitch-switchBase.Mui-checked': {
        transform: 'translateX(9px)',
      },
    },
    '& .MuiSwitch-switchBase': {
      padding: 2,
      '&.Mui-checked': {
        transform: 'translateX(12px)',
        color: '#fff',
        '& + .MuiSwitch-track': {
          opacity: 1,
          backgroundColor: theme.palette.mode === 'dark' ? '#177ddc' : '#1890ff',
        },
      },
    },
    '& .MuiSwitch-thumb': {
      boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
      width: 12,
      height: 12,
      borderRadius: 6,
      transition: theme.transitions.create(['width'], {
        duration: 200,
      }),
    },
    '& .MuiSwitch-track': {
      borderRadius: 16 / 2,
      opacity: 1,
      backgroundColor:
        theme.palette.mode === 'dark' ? 'rgba(255,255,255,.35)' : 'rgba(0,0,0,.25)',
      boxSizing: 'border-box',
    },
  }));
  const columns = [
    {
      name: "id",
      label: "ID"  // to rename column
    },
    //   {
    //       name:"firstName",
    //   },
    {
      name: "name",
      label: "Name"
    },
    {
      name: "netSalary",
      label: "Net Salary"
    },
    {
      name: "duration",
      label: "Duration"
    },
    {
      name: "paidUp",
      label: "Paid Up"
    },
    {
      name: "maidenName",
      label: "Lead Agent"
    },
    {
      name: "status",
      label: "Status",
      options: {
        customBodyRender: (value, tableMeta) => (
          <Stack value={value} direction="row" spacing={1} alignItems="center" onChange={(event) => handleStatusChange(event, tableMeta.rowIndex)}>

            <AntSwitch defaultChecked inputProps={{ 'aria-label': 'ant design' }} />
            <Typography value="on">Active</Typography>
          </Stack>
        )
      }
    },
    {
      name: "action",
      label: "Action",
      options: {
        customBodyRender: (value, tableMeta) => (
          <FormControl>
            <IconButton onClick={handleMenuClick}>
              <MoreVertIcon />
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleCloseMenu}
            >
              <MenuItem onClick={handleViewClick}>View</MenuItem>
              <MenuItem onClick={handleCloseMenu}>Edit</MenuItem>
              <MenuItem onClick={handleCloseMenu}>Delete</MenuItem>
              <MenuItem onClick={handleCloseMenu}>Change to Client</MenuItem>
              <MenuItem onClick={handleCloseMenu}>Add Follow up</MenuItem>
            </Menu>
          </FormControl>
        )
      }
    }
  ];

  const handleStatusChange = (event, rowIndex) => {
    const newUsers = [...users];
    newUsers[rowIndex].status = event.target.value;
    setUsers(newUsers);
  };

  // const handleStatusChange = (event, rowIndex) => {
  //     const { value } = event.target;
  //     setUsers(prevUsers => {
  //         const updatedUsers = [...prevUsers];
  //         updatedUsers[rowIndex].status = value;
  //         return updatedUsers;
  //     });
  // };

  useEffect(() => {
    fetch('https://dummyjson.com/users')
      .then(res => res.json())
      .then((data) => {
        let local = data?.users?.map((user) => ({
          ...user,
          name: user?.firstName + " " + user?.lastName,
          // status: "inactive"
        }))
        console.log(local)
        setUsers(local);
      })
  })

  const options = {
    filterType: 'checkbox',
    //selectableRows: false,
    elevation: 0,
    rowsPerPage: 5,
    rowsPerPageOptions: [5, 10, 20, 30],
  };

  const getMuiTheme = () =>
    createTheme({
      typography: {
        fontFamily: "Poppins",
      },
      palette: {
        background: {
          paper: "#1e293b",
          default: "0f172a",
        },
        mode: "dark",
      },
      components: {
        MuiTableCell: {
          styleOverrides: {
            head: {
              padding: "10px 4px",
            },
            body: {
              padding: "7px 15px",
              color: "#e2e8f0",
            },
          }
        }
      }
    })


  //dropdown in box
  const [dep, setDepartment] = React.useState('');

  const handledepChange = (event) => {
    setDepartment(event.target.value);
  };

  const [emp, setEmployee] = React.useState('');

  const handleempChange = (event) => {
    setEmployee(event.target.value);
  };

  return (
    <div className='py-10 min-h-screen grid place-items-center'>
      {/* <AddPayReport/> */}
      <br></br>
      <Box component="section" sx={{ p: 2, backgroundColor: "#121212" }}>
        <Grid container spacing={2} style={{ color: 'white', margin: "10px" }}>
          <Grid item xs={4} sm={4}>
            <Grid container spacing={2} alignItems="center">

              <Grid item xs={12}>
                <br></br>
                {/* <FormGroup> */}
                <FormControlLabel control={<Checkbox style={{ color: "white" }} />} label="Include Expense claims" style={{ color: "white", border: "white" }} />
                {/* </FormGroup> */}

              </Grid>

            </Grid>

          </Grid>
          <Grid item xs={4} sm={4}>
            <Grid container spacing={2} alignItems="center">

              <Grid item xs={12}>
                <br></br>
                {/* <FormGroup> */}
                <FormControlLabel control={<Checkbox style={{ color: "white" }} />} label="Add timelogs to salary" style={{ color: "white", border: "white" }} />
                {/* </FormGroup> */}

              </Grid>

            </Grid>

          </Grid>
          <Grid item xs={4} sm={4}>
            <Grid container spacing={2} alignItems="center">

              <Grid item xs={12}>
                <br></br>
                {/* <FormGroup> */}
                <FormControlLabel control={<Checkbox style={{ color: "white" }} />} label="Use Attendance" style={{ color: "white", border: "white" }} />
                {/* </FormGroup> */}

              </Grid>

            </Grid>

          </Grid>
          <Grid item xs={4} sm={4}>
            <Grid container spacing={2} alignItems="center">

              <Grid item xs={12}>
                <br></br>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">Department</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={dep}
                    label="Department"
                    onChange={handledepChange}
                  >
                    <MenuItem value={"hr"}>HR</MenuItem>
                    <MenuItem value={"finance"}>Finance</MenuItem>
                    <MenuItem value={"sales"}>Sales</MenuItem>
                  </Select>
                </FormControl>

              </Grid>

            </Grid>

          </Grid>
          <Grid item xs={4} sm={4}>
            <Grid container spacing={2} alignItems="center">

              <Grid item xs={12}>
                <br></br>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">Select Employee</InputLabel>
                  <Select
                    border="white"
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={emp}
                    label="Select Employee"
                    onChange={handleempChange}
                  >
                    <MenuItem value={10}>Ten</MenuItem>
                    <MenuItem value={20}>Twenty</MenuItem>
                    <MenuItem value={30}>Thirty</MenuItem>
                  </Select>
                </FormControl>

              </Grid>

            </Grid>

          </Grid>


        </Grid>
        <br></br>
        <hr></hr><br></br>
        <Button variant="contained" style={{textAlign:"right"}}>
        Generate &nbsp;<SendSharp/>
      </Button><br></br>
      </Box><br></br>
      <div className='w-10/12 max-w-4xl'>
        <ThemeProvider theme={getMuiTheme()}>
          <MUIDataTable
            title={"Crane Owner List"}
            data={users}
            columns={columns}
            options={options}
          />
        </ThemeProvider>
      </div>

    </div>
  )
}

export default PayrollReport;