import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Card, CardContent } from '@mui/material';
import AppSettingsTab from './AppSettingsTab';
import { styled } from '@mui/material/styles';
import GoogleSettingsTab from './GoogleSettingsTab';

  const StyledTabs = styled((props) => (
    <Tabs
      {...props}
      TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
    />
  ))({
    '& .MuiTabs-indicator': {
      display: 'flex',
      justifyContent: 'center',
      backgroundColor: 'transparent',
    },
    '& .MuiTabs-indicatorSpan': {
      maxWidth: 40,
      width: '100%',
      backgroundColor: '#635ee7',
    },
  });
  
  const StyledTab = styled((props) => <Tab disableRipple {...props} />)(
    ({ theme }) => ({
      textTransform: 'none',
      fontWeight: theme.typography.fontWeightRegular,
      fontSize: theme.typography.pxToRem(15),
      marginRight: theme.spacing(1),
      color: '#C0B476', 
      '&.Mui-selected': {
        color: '#000000', 
      },
      '&.Mui-focusVisible': {
        backgroundColor: 'red', 
      },
    }),
  );


  function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography component="div">{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  
  CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };
  
  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }
  
  export default function TabsAppSettings() {
    const [value, setValue] = React.useState(0);
  
    const handleChange = (event, newValue) => {
      setValue(newValue);
    };
  
    return (
        <Card sx={{ backgroundColor: 'white' }}>
            <CardContent>
      <Box sx={{ width: '100%' }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <StyledTabs
           value={value} onChange={handleChange} aria-label="basic tabs example">
            <StyledTab label="App Settings" {...a11yProps(0)} />
            <StyledTab label="Google Seettings" {...a11yProps(1)} />
            {/* <StyledTab label="Item Three" {...a11yProps(2)} /> */}
          </StyledTabs>
        </Box>
        <hr></hr>
        <CustomTabPanel value={value} index={0}>
        
          <AppSettingsTab />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
          <GoogleSettingsTab />
        </CustomTabPanel>
        {/* <CustomTabPanel value={value} index={2}>
          Item Three
        </CustomTabPanel> */}
      </Box>
      </CardContent>
        </Card>
    );
  }