import { Box , Grid, Snackbar, TextField, Alert, ThemeProvide, createTheme, ThemeProvider} from '@mui/material';
import React ,{useEffect, useState} from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import ListItemText from '@mui/material/ListItemText';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import MenuItem from '@mui/material/MenuItem';
import MUIDataTable from "mui-datatables";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
  });


function LeadCategory() {

    const [open, setOpen] = React.useState(false);
    const [category,setCategory] = React.useState();
    const[leadSource,setLeadSource] = useState([]);
    const [data,SetData] = useState([]);

    const [update, setUpdate] = React.useState(false);

    const handleClickUpdate = () => {
      setUpdate(true);
    };  
    
    const handleUpdateClose = () => {
      setUpdate(false);
    };



    const handleClickOpen = () => {
      setOpen(true);
    };  
    
    const handleClose = () => {
      setOpen(false);
    };

    useEffect(  ()=>{
    
        getData();
    
       },[]);
    
       async function getData() {
    
        let results = await fetch('https://roadpilot.co.in/server/public/api/GetLeadCategories');
        results = await results.json();
        setLeadSource(results);
        };

        const columns = [
            {
                name: 'category',
                label: 'Lead Category',
                options: {
                    customBodyRender: (value) => value.charAt(0).toUpperCase() + value.slice(1)
                }
            },
            {
              name: 'id', // Assuming this is the name of the column containing the ID
              label: 'Actions',
              options: {
                  customBodyRender: (value) => (
                      <>
                           <Button variant="contained" onClick={() => handleDelete(value)} sx={{ backgroundColor: 'red', '&:hover': { backgroundColor: 'orange' }, textAlign: 'center', marginLeft: '10px' }}>
                           <DeleteIcon /> Delete Lead Source
              </Button>
                        
                      </>
                  )
              }
              
            }
        ];

        async function handleEdit(id){
            console.log("Edit clicked for ID:", id);
          
            let result = await fetch("https://roadpilot.co.in/server/public/api/UpdateLeadSource/"+id);
            result = await result.json();
            SetData(result);
            handleClickUpdate();
          
          };



          async function handleDelete(id){
            const response = await fetch("https://roadpilot.co.in/server/public/api/DeleteLeadCategory/"+id,{
                method:"DELETE",
            });
            const result = await response.json(); // Assigning to a new variable
            console.log(result);
            getData();
            console.log("Edit clicked for ID:", id);
          };
          



    
    

    // Save Lead Sourse

    const item = {category}

    async function SaveDate() {
        

        let result = await fetch("https://roadpilot.co.in/server/public/api/AddLeadCategory",{
            method:"POST",
            headers:{
              "Content-Type":"application/json",
              "Accept":"application/json"
            },
            body:JSON.stringify(item)
          });
       
          result = await result.json();
       
          alert( "Saved successfully");
          
          getData();
           setOpen(false);

    }


    const options = {
        //selectableRows: false,
        elevation: 0,
        rowsPerPage: 5,
        rowsPerPageOptions: [5, 10, 25, 100],
        tableBody: {
            style: {
                maxHeight: '500px',
                overflowY: 'scroll',
                marginLeft:'5PX'
            }
        },
        tableHead: {
            style: {
                backgroundColor: '#f2f2f2',
                color: 'black'
            }
        },
        rows: {
            style: {
                marginLeft: '5px',
                textAlign: 'center'
            },
            selectableRowsOnClick: true
        }
    };
    
       const getTheme = () =>
       createTheme({
         palette: {
           mode: 'light',
           primary: {
             main: '#2196f3',
           },
           secondary: {
             main: '#03a9f4',
           },
         },
         typography: {
           fontFamily: [
             'Roboto',
             'Helvetica',
             'Arial',
             'Sans-serif',
           ].join(','),
         },
         components: {
           MuiButton: {
             styleOverrides: {
               root: {
                 textTransform: 'none',
               },
             },
           },
         },
         tableBody: {
          style: {
              maxHeight: '500px',
              overflowY: 'scroll'
          }
      },
      tableHead: {
          style: {
              backgroundColor: '#f2f2f2',
              color: 'black'
          }
      },
      rows: {
          style: {
              '&:hover': {
                  backgroundColor: '#f2f2f2'
              }
          }
      }
       });


  return (
    <>

<List style={{ background: 'none', padding: 5 }}>
          <ListItemText style={{ background: 'none', margin: 5 }}>
              <React.Fragment>
              <Button variant="contained" onClick={handleClickOpen} sx={{ backgroundColor: 'blue', '&:hover': { backgroundColor: 'orange' }, textAlign: 'center', marginLeft: '10px' }}>
                  <AddCircleIcon/> Add Lead Category
              </Button>
              <Dialog
                // fullScreen
                maxWidth= '100vh'
                open={open}
                onClose={handleClose}
                TransitionComponent={Transition}
                PaperProps={{
                  style: {
                    backgroundColor: 'white',
                  },
                }}
              >
                <AppBar sx={{ position: 'relative' }}>
                  <Toolbar>
                    <IconButton
                      edge="start"
                      color="inherit"
                      onClick={handleClose}
                      aria-label="close"
                    >
                      <CloseIcon />
                    </IconButton>
                    <Typography sx={{ ml: 2, flex: 1 }} component="div">
                      Add Lead Category
                    </Typography>
                  </Toolbar>
                </AppBar>
                <Box>

          <Grid container spacing={2} style={{ color: 'black', margin:"10px" }}>
            
            <Grid item xs={10} sm={10}>
            <Typography variant="p" gutterBottom style={{ color: 'black' }}>
      Lead Category
    </Typography>
    <Grid container spacing={2} alignItems="center">
      <Grid item xs={12}>
        <TextField
          label="enter Lead Category"
          variant="outlined"
          fullWidth
          placeholder="e.g.Google"
          inputProps={{ style: { color: 'black' } }}
          InputLabelProps={{ style: { color: 'black' } }}
          onChange={(e)=>setCategory(e.target.value)}
          sx={{
            '& .MuiOutlinedInput-root': { 
              borderColor: 'black',
              '&:hover fieldset': {
                borderColor: 'black',
              },
              '&.Mui-focused fieldset': {
                borderColor: 'black',
              },
            },
            '& .MuiOutlinedInput-input': {
              '&::placeholder': { color: 'gray' }
            }
          }}
        />
      </Grid>

    </Grid>

            </Grid>
            
          </Grid>

        

         <Button variant="contained" autoFocus color="inherit" onClick={SaveDate} sx={{ backgroundColor: 'blue', '&:hover': { backgroundColor: 'orange' }, textAlign: 'center', margin: '25px' }}>
      Save
 </Button>

    </Box>
              </Dialog>
            </React.Fragment>

            </ListItemText>
          <Divider />
          <ListItemText style={{ background: 'white', margin: 0 }}> 
          <ThemeProvider theme={getTheme()}>
            
           

                      <MUIDataTable
                fullScreen={true}
                title={"Lead Category"}
                data={leadSource}
                columns={columns}
                options={options}
            />


          </ThemeProvider>
          
          </ListItemText>
        </List>


      
    </>
  )
}

export default LeadCategory
