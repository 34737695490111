import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import LoginPag from '../scenes/Login/LoginPag';

function Protected(props) {
    const { cmp: Component } = props;
    const navigate = useNavigate();
    useEffect(() => {
        if (!localStorage.getItem("user-info")) {
            navigate("/");
        }
    }, [navigate, localStorage.getItem("user-info")]); // Add localStorage.getItem("user-info") as a dependency

    // Render the protected component only if the user is authenticated
    return (
        <div>
            {localStorage.getItem("user-info") ? <Component /> : <navigate tp = "/" replace/>}
        </div>
    );
}

export default Protected;
