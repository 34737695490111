import { Box , Grid, Snackbar, TextField, Alert, Autocomplete, createTheme, ThemeProvider} from '@mui/material';
import React ,{useEffect, useState} from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import ListItemText from '@mui/material/ListItemText';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import MenuItem from '@mui/material/MenuItem';
import MUIDataTable from "mui-datatables";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
  });


function LeadAgent() {

    const [open, setOpen] = React.useState(false);
    const[leadSource,setLeadSource] = useState([]);
    const [users, setUsers] = useState([]);
    const [loading, setLoading] = useState(true);
    const [data,SetData] = useState([]);
    const [selectedOption, setSelectedOption] = React.useState(null);
    const [update, setUpdate] = React.useState(false);

    const handleClickUpdate = () => {
      setUpdate(true);
    };  
    
    const handleUpdateClose = () => {
      setUpdate(false);
    };



    const handleClickOpen = () => {
      setOpen(true);
    };  
    
    const handleClose = () => {
      setOpen(false);
    };

    useEffect(  ()=>{
    
        getData();
       getUsers();
    
       },[]);
    
       async function getData() {
    
        let results = await fetch('https://roadpilot.co.in/server/public/api/GetLeadAgents');
        results = await results.json();
        setLeadSource(results);
        };

        async function getUsers() {
        
          await  fetch('https://roadpilot.co.in/server/public/api/getAllEmployees')
          .then(response => response.json())
          .then(data => {
            const names = data.map(item => item.name).filter(name => name); // Extracting and filtering names from data
            setUsers(names); // Setting options to an array of names
            setLoading(false);
          })
          .catch(error => {
            console.error('Error fetching data:', error);
            setLoading(false);
          });
        }

        const getOptionLabel = (users) => {
            return users; // Assuming the option is already a string (name)
          };

      

        const columns = [
            {
                name: 'agents',
                label: 'Lead Agents',
                options: {
                    customBodyRender: (value) => value.charAt(0).toUpperCase() + value.slice(1)
                }
            },
            {
              name: 'id', // Assuming this is the name of the column containing the ID
              label: 'Actions',
              options: {
                  customBodyRender: (value) => (
                      <>
                           <Button variant="contained" onClick={() => handleDelete(value)} sx={{ backgroundColor: 'red', '&:hover': { backgroundColor: 'orange' }, textAlign: 'center', marginLeft: '10px' }}>
                           <DeleteIcon /> Delete Lead Source
              </Button>
                        
                      </>
                  )
              }
              
            }
        ];

        async function handleEdit(id){
            console.log("Edit clicked for ID:", id);
          
            let result = await fetch("https://roadpilot.co.in/server/public/api/UpdateLeadSource/"+id);
            result = await result.json();
            SetData(result);
            handleClickUpdate();
          
          };



          async function handleDelete(id){
            const response = await fetch("https://roadpilot.co.in/server/public/api/DeleteLeadAgents/"+id,{
                method:"DELETE",
            });
            const result = await response.json(); // Assigning to a new variable
            console.log(result);
            getData();
            console.log("Edit clicked for ID:", id);
          };
          



    
    

    // Save Lead Sourse

    const item = {selectedOption}

    async function SaveDate() {

        const formData = new FormData();
        formData.append('agents', item.selectedOption);
        
        try {
        let result = await fetch("https://roadpilot.co.in/server/public/api/AddLeadAgents",{
            method:"POST",
            body: formData
          });
       
          if (!result.ok) {
            throw new Error('Failed to update data');
          }
      
          console.log(result);
          alert("Updated");
      
          handleUpdateClose();
          getData(); // Assuming these functions are defined elsewhere in your code
        } catch (error) {
          console.error(error);
          alert("Failed to update data");
        }

           setOpen(false);

    }


    const options = {
        //selectableRows: false,
        elevation: 0,
        rowsPerPage: 5,
        rowsPerPageOptions: [5, 10, 25, 100],
        tableBody: {
            style: {
                maxHeight: '500px',
                overflowY: 'scroll',
                marginLeft:'5PX'
            }
        },
        tableHead: {
            style: {
                backgroundColor: '#f2f2f2',
                color: 'black'
            }
        },
        rows: {
            style: {
                marginLeft: '5px',
                textAlign: 'center'
            },
            selectableRowsOnClick: true
        }
    };
    
       const getTheme = () =>
       createTheme({
         palette: {
           mode: 'light',
           primary: {
             main: '#2196f3',
           },
           secondary: {
             main: '#03a9f4',
           },
         },
         typography: {
           fontFamily: [
             'Roboto',
             'Helvetica',
             'Arial',
             'Sans-serif',
           ].join(','),
         },
         components: {
           MuiButton: {
             styleOverrides: {
               root: {
                 textTransform: 'none',
               },
             },
           },
         },
         tableBody: {
          style: {
              maxHeight: '500px',
              overflowY: 'scroll'
          }
      },
      tableHead: {
          style: {
              backgroundColor: '#f2f2f2',
              color: 'black'
          }
      },
      rows: {
          style: {
              '&:hover': {
                  backgroundColor: '#f2f2f2'
              }
          }
      }
       });
    


  return (
    <>

<List style={{ background: 'none', padding: 5 }}>
          <ListItemText style={{ background: 'none', margin: 5 }}>
              <React.Fragment>
              <Button variant="contained" onClick={handleClickOpen} sx={{ backgroundColor: 'blue', '&:hover': { backgroundColor: 'orange' }, textAlign: 'center', marginLeft: '10px' }}>
                  <AddCircleIcon/> Add Lead agents
              </Button>
              <Dialog
                // fullScreen
                maxWidth= '100vh'
                open={open}
                onClose={handleClose}
                TransitionComponent={Transition}
                PaperProps={{
                  style: {
                    backgroundColor: 'white',
                  },
                }}
              >
                <AppBar sx={{ position: 'relative' }}>
                  <Toolbar>
                    <IconButton
                      edge="start"
                      color="inherit"
                      onClick={handleClose}
                      aria-label="close"
                    >
                      <CloseIcon />
                    </IconButton>
                    <Typography sx={{ ml: 2, flex: 1 }} component="div">
                      Add Lead Agent
                    </Typography>
                  </Toolbar>
                </AppBar>
                <Box>

          <Grid container spacing={2} style={{ color: 'black', margin:"10px" }}>
            
            <Grid item xs={10} sm={10}>

            <Autocomplete
                options={users}
                loading={loading}
                getOptionLabel={getOptionLabel}
                onChange={(event, value) => setSelectedOption(value)}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label="Select Name"
                        variant="outlined"
                        sx={{ width: 300 }}
                        InputProps={{
                            ...params.InputProps,
                            type: 'search',
                            style: { color: 'black' }
                          }}
                          InputLabelProps={{ ...params.InputProps,
                             style: { color: 'black' } }}
                        style={{ borderColor: 'black', color: 'black' }}
                    />
                )}
            />


            </Grid>
            
          </Grid>

        

         <Button variant="contained" autoFocus color="inherit" onClick={SaveDate} sx={{ backgroundColor: 'blue', '&:hover': { backgroundColor: 'orange' }, textAlign: 'center', margin: '25px' }}>
      Save
 </Button>

    </Box>
              </Dialog>
            </React.Fragment>

            </ListItemText>
          <Divider />
          <ListItemText style={{ background: 'white', margin: 0 }}> 
          <ThemeProvider theme={getTheme()}>
            
           

                      <MUIDataTable
                fullScreen={true}
                title={"Lead agents"}
                data={leadSource}
                columns={columns}
                options={options}
            />


          </ThemeProvider>
          
          </ListItemText>
        </List>


      
    </>
  )
}

export default LeadAgent
