import * as React from 'react';
import Stack from '@mui/material/Stack';
import AddIcon from '@mui/icons-material/Add';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import { Box, Button, TextField, Grid} from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});


export default function AddSales() {

    const [open, setOpen] = React.useState(false);
    const [selectedOption, setSelectedOption] = React.useState(null);
    const [category, setCategory] = React.useState('');
    const [source, setSource] = React.useState('');
    const [agent, setAgent] = React.useState('');
    const [Name, setName] = React.useState();
    const [Email, setEmail] = React.useState();
    const [phone, setphone] = React.useState();
    const [language, setlanguage] = React.useState();

    const handleCategoryChange = (event) => {
        setCategory(event.target.value);
    };
    const handleSourceChange = (event) => {
        setSource(event.target.value);
    };
    const handleAgentChange = (event) => {
        setAgent(event.target.value);
    };
    

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
   
    //save data
    const item = {Name,Email,phone, language}
    async function SaveData() {

        const formData = new FormData();
        formData.append('name', Name);
        formData.append('email', Email);
        formData.append('phone', phone);
        formData.append('language', language);
        formData.append('category', item.selectedOption);

        console.log(item);
        
        // try {
        // let result = await fetch("https://roadpilot.co.in/server/public/api/AddSalesData",{
        //     method:"POST",
        //     body: formData
        //   });
       
        //   if (!result.ok) {
        //     throw new Error('Failed to update data');
        //   }
      
        //   console.log(result);
        //   alert("Updated");
      
        // //   handleUpdateClose();
        // //   getData(); 
        // } catch (error) {
        //   console.error(error);
        //   alert("Failed to update data");
        // }

           setOpen(false);

    }


    return (
        <div>
            <React.Fragment>
                <Stack direction="row" spacing={2}>
                    <Button variant="contained" onClick={handleClickOpen} sx={{ backgroundColor: 'blue', '&:hover': { backgroundColor: 'orange' }, textAlign: 'center', marginLeft: '10px' }}><AddIcon />Add Sales</Button>
                    {/* <Button variant="contained" disabled>
        Disabled
      </Button>
      <Button variant="contained" href="#contained-buttons">
        Link
      </Button> */}
                </Stack>
                <Dialog
                    fullScreen
                    open={open}
                    onClose={handleClose}
                    TransitionComponent={Transition}
                    PaperProps={{
                        style: {
                            backgroundColor: 'white',
                        },
                    }}
                >

                    <AppBar sx={{ position: 'relative' }}>
                        <Toolbar>
                            <IconButton
                                edge="start"
                                color="inherit"
                                onClick={handleClose}
                                aria-label="close"
                            >
                                <CloseIcon />
                            </IconButton>
                            <Typography sx={{ ml: 2, flex: 1 }} component="div">
                                Add New Sales
                            </Typography>

                        </Toolbar>

                    </AppBar>
                    <Box>


                        <Grid container spacing={2} style={{ color: 'black', margin: "10px" }}>


                            <Grid item xs={4} sm={3}>
                                <Typography variant="p" gutterBottom style={{ color: 'black' }}>
                                    Name
                                </Typography>
                                <Grid container spacing={2} alignItems="center">
                                    <Grid item xs={12}>
                                        <br></br>
                                        <TextField
                                            required
                                            label="Enter name"
                                            variant="outlined"
                                            fullWidth
                                            placeholder="e.g.Name"
                                            onChange={(e) => setName(e.target.value)}
                                            inputProps={{ style: { color: 'black' } }}
                                            InputLabelProps={{ style: { color: 'black' } }}
                                            sx={{
                                                '& .MuiOutlinedInput-root': {
                                                    borderColor: 'black',
                                                    '&:hover fieldset': {
                                                        borderColor: 'black',
                                                    },
                                                    '&.Mui-focused fieldset': {
                                                        borderColor: 'black',
                                                    },
                                                },
                                                '& .MuiOutlinedInput-input': {
                                                    '&::placeholder': { color: 'gray' }
                                                }
                                            }}
                                        />
                                    </Grid>

                                </Grid>

                            </Grid>
                            <Grid item xs={4} sm={3}>
                                <Typography variant="p" gutterBottom style={{ color: 'black' }}>
                                    Email ID
                                </Typography>
                                <Grid container spacing={2} alignItems="center">
                                    <Grid item xs={12}>
                                        <br></br>
                                        <TextField
                                            label="enter email"
                                            variant="outlined"
                                            fullWidth
                                            placeholder="eg@example.com"
                                            type="email"
                                            onChange={(e) => setEmail(e.target.value)}
                                            inputProps={{ style: { color: 'black' } }}
                                            InputLabelProps={{ style: { color: 'black' } }}
                                            sx={{
                                                '& .MuiOutlinedInput-root': {
                                                    borderColor: 'black',
                                                    '&:hover fieldset': {
                                                        borderColor: 'black',
                                                    },
                                                    '&.Mui-focused fieldset': {
                                                        borderColor: 'black',
                                                    },
                                                },
                                                '& .MuiOutlinedInput-input': {
                                                    '&::placeholder': { color: 'gray' }
                                                }
                                            }}
                                        />
                                    </Grid>

                                </Grid>

                            </Grid>

                        </Grid>

                        <Grid container spacing={2} style={{ color: 'black', margin: "10px" }}>
                            <Grid item xs={4} sm={3}>
                                <Typography variant="p" gutterBottom style={{ color: 'black' }}>
                                    Phone number
                                </Typography>
                                <Grid container spacing={2} alignItems="center">
                                    <Grid item xs={12}>
                                        <br></br>
                                        <TextField
                                            required
                                            label="enter Phone Number"
                                            variant="outlined"
                                            fullWidth
                                            placeholder="+91 1234567890"
                                            type="number"
                                            onChange={(e)=>{setphone(e.target.value)}}
                                            inputProps={{ style: { color: 'black' } }}
                                            InputLabelProps={{ style: { color: 'black' } }}
                                            sx={{
                                                '& .MuiOutlinedInput-root': {
                                                    borderColor: 'black',
                                                    '&:hover fieldset': {
                                                        borderColor: 'black',
                                                    },
                                                    '&.Mui-focused fieldset': {
                                                        borderColor: 'black',
                                                    },
                                                },
                                                '& .MuiOutlinedInput-input': {
                                                    '&::placeholder': { color: 'gray' }
                                                }
                                            }}
                                        />
                                    </Grid>

                                </Grid>

                            </Grid>

                            <Grid item xs={8} sm={2}>
                                <Typography variant="p" gutterBottom style={{ color: 'black' }}>
                                    Language
                                </Typography>
                                <Grid container spacing={2} alignItems="center">
                                    <Grid item xs={12}>
                                        <br></br>
                                        <TextField
                                            required
                                            label="Enter Language"
                                            variant="outlined"
                                            fullWidth
                                            placeholder="e.g. Hindi"
                                            onChange={(e)=>{setlanguage(e.target.value)}}
                                            inputProps={{ style: { color: 'black' } }}
                                            InputLabelProps={{ style: { color: 'black' } }}
                                            sx={{
                                                '& .MuiOutlinedInput-root': {
                                                    borderColor: 'gray',
                                                    '&:hover fieldset': {
                                                        borderColor: 'black',
                                                    },
                                                    '&.Mui-focused fieldset': {
                                                        borderColor: 'gray',
                                                    },
                                                },
                                                '& .MuiOutlinedInput-input': {
                                                    '&::placeholder': { color: 'gray' }
                                                }
                                            }}
                                        />
                                    </Grid>

                                </Grid>

                            </Grid>

                        </Grid>


                        <Grid container spacing={2} style={{ color: 'black', margin: "10px" }}>
                            <Grid item xs={8} sm={3}>
                                <Typography variant="p" gutterBottom style={{ color: 'black' }}>
                                    Category
                                </Typography>
                                <Grid container spacing={2} alignItems="center">
                                    <Grid item xs={12}>
                                        <br></br>
                                        <FormControl fullWidth>
                                            <InputLabel id="demo-simple-select-label">Category</InputLabel>
                                            <Select
                                                required
                                                placeholder='choose'
                                                style={{color:"black"}}
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={category}
                                                label="Category"
                                                onChange={handleCategoryChange}
                                            >
                                                <MenuItem value="">
            <em>None</em>
          </MenuItem>
                                                <MenuItem value={10}>Ten</MenuItem>
                                                <MenuItem value={20}>Twenty</MenuItem>
                                                <MenuItem value={30}>Thirty</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>

                                </Grid>

                            </Grid>
                            <Grid item xs={8} sm={2.5}>
                                <Typography variant="p" gutterBottom style={{ color: 'black' }}>
                                    Source
                                </Typography>
                                <Grid container spacing={2} alignItems="center">
                                    <Grid item xs={12}>
                                        <br></br>
                                        <FormControl fullWidth>
                                            <InputLabel id="demo-simple-select-label">Source</InputLabel>
                                            <Select
                                                required
                                                style={{color:"black"}}
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={source}
                                                label="Source"
                                                onChange={handleSourceChange}
                                            >
                                                <MenuItem value="">
            <em>None</em>
          </MenuItem>
                                                <MenuItem value={10}>Ten</MenuItem>
                                                <MenuItem value={20}>Twenty</MenuItem>
                                                <MenuItem value={30}>Thirty</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>

                                </Grid>

                            </Grid>
                            <Grid item xs={8} sm={2.5}>
                                <Typography variant="p" gutterBottom style={{ color: 'black' }}>
                                    Agent
                                </Typography>
                                <Grid container spacing={2} alignItems="center">
                                    <Grid item xs={12}>
                                        <br></br>
                                        <FormControl fullWidth>
                                            <InputLabel id="demo-simple-select-label">Agent</InputLabel>
                                            <Select
                                                required
                                                style={{color:"black"}}
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={agent}
                                                label="Agent"
                                                onChange={handleAgentChange}
                                            >
                                                <MenuItem value="">
            <em>None</em>
          </MenuItem>
                                                <MenuItem value={10}>Ten</MenuItem>
                                                <MenuItem value={20}>Twenty</MenuItem>
                                                <MenuItem value={30}>Thirty</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>

                                </Grid>

                            </Grid>
                            

                        </Grid>



                        <Button variant="contained" autoFocus color="inherit" onClick={SaveData} sx={{ backgroundColor: 'blue', '&:hover': { backgroundColor: 'orange' }, textAlign: 'center', margin: '25px' }}>
                            Save
                        </Button>

                    </Box>
                </Dialog>

            </React.Fragment>

        </div>


    );
}
