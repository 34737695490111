import * as React from 'react';
import { useState } from 'react';
import './MechView.css'
import Stack from '@mui/material/Stack';
import AddIcon from '@mui/icons-material/Add';
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import { Box, Button, Grid } from '@mui/material';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import { LineChart } from '@mui/x-charts/LineChart';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import SwipeLeftIcon from '@mui/icons-material/SwipeLeft';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import HourglassBottomIcon from '@mui/icons-material/HourglassBottom';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import SouthEastIcon from '@mui/icons-material/SouthEast';
import { axisClasses } from '@mui/x-charts';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Chip from '@mui/material/Chip';
import { BarChart } from '@mui/x-charts/BarChart';
import Modal from '@mui/material/Modal';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow,Select,MenuItem } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import {alpha } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import '../../mainfavicon.png';
import StarIcon from '@mui/icons-material/Star';
import GppGoodIcon from '@mui/icons-material/GppGood';
import NorthEastIcon from '@mui/icons-material/NorthEast';
import Inventory2Icon from '@mui/icons-material/Inventory2';


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});
const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.black,
}));

const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.black, 0.1),
    '&:hover': {
      backgroundColor: alpha(theme.palette.common.black, 0.15),
    },
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(1),
      width: 'auto',
    },
  }));
  
  const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }));

  const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'black',
    width: '100%',
    '& .MuiInputBase-input': {
      padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(4)})`,
      transition: theme.transitions.create('width'),
      [theme.breakpoints.up('sm')]: {
        width: '12ch',
        '&:focus': {
          width: '20ch',
        },
      },
    },
  }));

const chartSetting = {
    // xAxis: [
    //     {
    //         label: 'rainfall (mm)',
    //     },
    // ],
    // width: 500,
    // height: 400,
    yAxis: [
        {
            label: 'rainfall (mm)',
        },
    ],
    // width: 500,
    // height: 200,
    sx: {
        [`.${axisClasses.left} .${axisClasses.label}`]: {
            transform: 'translate(-20px, 0)',
        },
    },
};

const dataset = [
    {
        jobs: 59,
        details: 'Jobs',
    },
    {
        dhaba: 52,
        details: 'Dhaba',
    },
    {
        mech: 76,
        details: 'Mech',
    },
    {
        convey: 25,
        details: 'Convey',
    },
    {
        profile: 25,
        details: 'Profile',
    },
    {
        others: 45,
        details: 'Others',
    },
];


const valueFormatter = (value) => `${value}mm`;


export default function MechView() {
    const [open, setOpen] = React.useState(false);


    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
    const handleFileButtonClick = () => {
        // Trigger click event on the file input element
        document.getElementById('fileInput').click();
    };

    const [isModalOpen, setIsModalOpen] = useState(false);

    const toggleModal = () => {
        setIsModalOpen(!isModalOpen);
    };
    const [dateFilter, setDateFilter] = useState('recents');
const drivers = [
    { name: 'Mike', transactionID: '245836493', date: '10-Apr-2024', payment: 'Rs.148' },
    { name: 'John', transactionID: '245836483', date: '01-Apr-2024', payment: 'Rs.148' },
    { name: 'Jane', transactionID: '268562341', date: '20-Mar-2024', payment: 'Rs.150' },
    { name: 'Alice', transactionID: '256428754', date: '14-Mar-2024', payment: 'Rs.256' },
    { name: 'Johnson', transactionID: '248612492', date: '26-Feb-2024', payment: 'Rs.210' },
    { name: 'Smith', transactionID: '267098332', date: '09-Feb-2024', payment: 'Rs.135' },
];
const filteredDrivers = drivers.filter(driver => {
    const currentDate = new Date();
    const driverDate = new Date(driver.date);
    switch (dateFilter) {
        case 'day':
            return driverDate.getDate() === currentDate.getDate();
        case 'week':
            return Math.abs(currentDate - driverDate) / (1000 * 60 * 60 * 24) <= 7;
        case 'month':
            return driverDate.getMonth() === currentDate.getMonth();
        case 'year':
            return driverDate.getFullYear() === currentDate.getFullYear();
        default:
            return true; // 'recents' option or default
    }
});

const handleDateFilterChange = (event) => {
    setDateFilter(event.target.value);
};
const [week, setWeek] = React.useState('');

const handleChange = (event) => {
    setWeek(event.target.value);
};
    return (
        <div>
            <React.Fragment>
                <Stack direction="row" spacing={2}>
                    <Typography variant="text" onClick={handleClickOpen} sx={{ color: "white" }}>View</Typography>

                </Stack>
                <Dialog
                    fullScreen
                    open={open}
                    onClose={handleClose}
                    TransitionComponent={Transition}
                    PaperProps={{
                        style: {
                            backgroundColor: 'white',
                        },
                    }}
                >

                    {/* <AppBar sx={{ position: 'relative', height: '30px' }}>
                        <Toolbar>
                            <IconButton
                                edge="start"
                                color="inherit"
                                onClick={handleClose}
                                aria-label="close"
                            >
                                <CloseIcon />
                            </IconButton> */}
                    {/* <Typography sx={{ ml: 2, flex: 1 }} component="div">
                                Driver Detail
                            </Typography> */}
                    {/* 
                        </Toolbar>

                    </AppBar> */}
                    <Box display="flex" flexDirection="column" >
                        <Box sx={{ flexGrow: 1 }}>

                            {/* <Grid container spacing={1}> */}
                            {/* first column */}
                            <Grid item xs={12}>
                                <Item style={{ backgroundColor: "#fff", height: '40px', paddingInline: "3px" }}>

                                    <Typography style={{ color: "black", textAlign: "left" }}>
                                        <IconButton
                                            edge="start"
                                            onClick={handleClose}
                                            aria-label="close"
                                            style={{ marginLeft: "4px" }}
                                        >
                                            <CloseIcon style={{ backgroundColor: "red", color: "white", borderRadius: "50%", fontSize: "20px" }} />
                                        </IconButton>
                                        <b>ID: RPTLDIDNOS000001234567890</b>
                                        <Typography className='btn'>
                                            <Button variant="contained" sx={{ backgroundColor: "green", '&:hover': { backgroundColor: 'orange' }, textAlign: 'right' }}>Edit</Button>&nbsp;&nbsp;
                                            <Button variant="contained" sx={{ backgroundColor: 'blue', '&:hover': { backgroundColor: 'orange' }, textAlign: 'right', marginLeft: '10px' }}><AddIcon />Add Ticket</Button>&nbsp;&nbsp;
                                        </Typography>
                                    </Typography>
                                </Item>
                            </Grid>
                            <div style={{ marginBottom: "5px" }}></div>
                            <Grid item xs={12} md={4}>
                                <Grid container spacing={1}>
                                    <Grid item xs={12} md={2} style={{ color: "black" }}>
                                        <Grid>
                                            <Item style={{ backgroundColor: "#fff" }}>
                                                <Stack direction="row">
                                                    {/* <img src='.src/scenes/mainfavicon.png' alt='Drivers profile'/> */}
                                                    <AccountCircleIcon style={{ color: "grey", fontSize: "100px" }} />
                                                    <br></br>
                                                    <Typography style={{ color: "black" }}>Dhaba<br></br>
                                                        <Chip label={<span style={{ color: "red", fontSize: "12px", backgroundColor: "pink",borderRadius: "5px" }}>Standard</span>} />
                                                    </Typography>
                                                </Stack>
                                                <br></br>
                                                <Stack direction="row">
                                                <Stack direction="row">
                                                    <StarIcon style={{ color: "green", fontSize: "30px" }} />
                                                    <br></br>
                                                    <Typography style={{ color: "black", fontSize: "20px" ,width:'auto' }}>5.0<br></br>
                                                        <Chip label={<span style={{ color: "black", fontSize: "15px"}}>Rating</span>} />
                                                    </Typography>
                                                </Stack>
                                                <br></br>
                                                <Stack direction="row">
                                                    <GppGoodIcon style={{ color: "blue", fontSize: "30px" ,direction:"left"}} />
                                                    <br></br>
                                                    <Typography style={{ color: "black", fontSize: "20px" ,width:'auto' }}>1<br></br>
                                                        <Chip label={<span style={{ color: "black", fontSize: "15px"}}>Experience</span>} />
                                                    </Typography>
                                                </Stack>
                                                </Stack>
                                                <Typography style={{ color: "black", fontSize: "20px", textAlign: "left" }}>
                                                    Dhaba Details
                                                </Typography>
                                                <hr></hr>
                                                <Typography style={{ color: "#c2c2d6", textAlign: "left" }}>
                                                    Name: Udhay<br></br>
                                                    Contact:+91 1234567890 <br></br>
                                                    Email: driver@roadpilot.co.inactive<br></br>
                                                    State: Tamil Nadu<br></br>
                                                    Address: Erani street,Lakshminagar<br></br>
                                                    Pincode: 600056
                                                </Typography><br></br>
                                                <Typography style={{ color: "black", textAlign: "left", fontSize: "20px" }}>Features</Typography>
                                                <hr></hr>
                                                <div style={{ marginBottom: "10px" }}></div>
                                                <Stack direction="row" style={{ padding: "5px" }}>
                                                    <Button variant="outlined" style={{ color: "green", fontSize: "8px", borderRadius: '10px' }}>Veg &Non-Veg</Button>&nbsp;
                                                    <Button variant="outlined" style={{ color: "orange", fontSize: "8px",borderRadius: '10px' }}>Budget-Friendly prices</Button>&nbsp;
                                                </Stack>

                                                <Stack direction="row" style={{ padding: "5px" }}>
                                                    <Button variant="outlined" style={{ color: "blue", fontSize: "8px",borderRadius: '10px' }}>Dining</Button>&nbsp;
                                                    <Button variant="outlined" style={{ color: "grey", fontSize: "8px",borderRadius: '10px' }}>Takeaway</Button>&nbsp;
                                                </Stack>

                                                <Stack direction="row" style={{ padding: "5px" }}>
                                                    <Button variant="outlined" style={{ color: "red", fontSize: "8px",borderRadius: '10px' }}>Music and Entertainment</Button>&nbsp;
                                                    <Button variant="outlined" style={{ color: "purple", fontSize: "8px",borderRadius: '10px' }}>Open Kitchen</Button>&nbsp;
                                                </Stack>

                                                <Stack direction="row" style={{ padding: "5px", paddingBottom: "60px" }}>
                                                    <Button variant="outlined" style={{ color: "pink", fontSize: "8px",borderRadius: '10px' }}>24/7 Service</Button>&nbsp;
                                                    <Button variant="outlined" style={{ color: "red", fontSize: "8px",borderRadius: '10px' }}>Local Specialities</Button>&nbsp;
                                                </Stack>

                                                <Stack direction="row" style={{ padding: "5px" }}>
                                                    <Button variant="contained" style={{ backgroundColor: "green" }}>Edit</Button>&nbsp;&nbsp;
                                                    <Button variant="outlined" style={{ color: "red" }}>Suspend</Button>
                                                </Stack>
                                            </Item>
                                        </Grid>
                                    </Grid>
                                    {/* second column */}
                                    <Grid item xs={12} md={7} style={{ color: "black" }}>
                                        <Grid container spacing={1} style={{ marginBottom: "5px" }}>


                                            <Grid item xs={12} sm={6} md={6} lg={6}>

                                                {/* first row */}

                                                <Grid container spacing={1} >
                                                    <Grid item xs={12} sm={6}>
                                                        <Item style={{ backgroundColor: "#fff" }}>
                                                            <Typography gutterBottom variant="subtitle1" style={{ textAlign: "left", fontSize: "20px", color: "#4d4f5c" }}>
                                                                Total Customers
                                                            </Typography>
                                                            <Typography variant="body2" gutterBottom style={{ textAlign: "right", color: "#4d4f5c" }}>
                                                                <LocalShippingIcon style={{ color: "white", borderRadius: "50%", backgroundColor: "red", paddingInline: "3px" }} />
                                                            </Typography>
                                                            <Typography variant="body2" style={{ textAlign: "left", fontSize: "16px" }} color="blue">
                                                                275
                                                            </Typography>
                                                        </Item>
                                                    </Grid>

                                                    <Grid item xs={12} sm={6}>
                                                        <Item style={{ backgroundColor: "#fff" }}>
                                                            <Typography gutterBottom variant="subtitle1" style={{ textAlign: "left", fontSize: "20px", color: "#4d4f5c" }}>
                                                                Jobs rejected
                                                            </Typography>
                                                            <Typography variant="body2" gutterBottom style={{ textAlign: "right", color: "#4d4f5c" }}>
                                                                <SwipeLeftIcon style={{ color: "white", borderRadius: "50%", backgroundColor: "purple", paddingInline: "3px" }} />
                                                            </Typography>
                                                            <Typography variant="body2" style={{ textAlign: "left", fontSize: "16px" }} color="blue">
                                                                275
                                                            </Typography>
                                                        </Item>
                                                    </Grid>
                                                </Grid>
                                            </Grid>

                                            <Grid item xs={12} sm={6} md={6} lg={6}>

                                                {/* first row */}
                                                <Grid container spacing={1} >
                                                    <Grid item xs={12} sm={6}>
                                                        <Item style={{ backgroundColor: "#fff" }}>
                                                            <Typography gutterBottom variant="subtitle1" style={{ textAlign: "left", fontSize: "20px", color: "#4d4f5c" }}>
                                                                Jobs accepted
                                                            </Typography>
                                                            <Typography variant="body2" gutterBottom style={{ textAlign: "right", color: "#4d4f5c" }}>
                                                                <CheckCircleIcon style={{ color: "white", borderRadius: "50%", backgroundColor: "green", paddingInline: "3px" }} />
                                                            </Typography>
                                                            <Typography variant="body2" style={{ textAlign: "left", fontSize: "16px" }} color="blue">
                                                                275
                                                            </Typography>
                                                        </Item>
                                                    </Grid>

                                                    <Grid item xs={12} sm={6}>
                                                        <Item style={{ backgroundColor: "#fff" }}>
                                                            <Typography gutterBottom variant="subtitle1" style={{ textAlign: "left", fontSize: "20px", color: "#4d4f5c" }}>
                                                                Convoy created
                                                            </Typography>
                                                            <Typography variant="body2" gutterBottom style={{ textAlign: "right", color: "#4d4f5c" }}>
                                                                <HourglassBottomIcon style={{ color: "white", borderRadius: "50%", backgroundColor: "blue", paddingInline: "3px" }} />
                                                            </Typography>
                                                            <Typography variant="body2" style={{ textAlign: "left", fontSize: "16px" }} color="blue">
                                                                275
                                                            </Typography>
                                                        </Item>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>



                                        <Grid container spacing={1} >
                                            <Grid item xs={12} md={6} style={{ color: "black" }}>
                                                <Grid container spacing={1}>
                                                    <Grid item xs={12}>
                                                        <Grid>
                                                            <Item style={{ backgroundColor: "#fff" }}>
                                                            <Stack direction="row">
                                                                    <Stack direction="row">
                                                                        <Inventory2Icon style={{ color: "grey", fontSize: "40px",marginTop:"15px" }} />
                                                                        <Typography style={{ color: "black", fontSize: "15px" ,width:'auto',marginTop:"15px"}}>Rs. 1450.50<br></br>
                                                                            <Chip label={<span style={{ color: "black", fontSize: "12px", borderRadius: "10px" }}>Total amount</span>} />
                                                                        </Typography>
                                                                    </Stack>
                                                                    <Stack sx={{marginLeft:'200px',color:'black'}}>
                                                                        <FormControl sx={{ m: 1, minWidth: 30 }} size="small" style={{color:'black'}}>
                                                                            {/* <InputLabel id="demo-simple-select-helper-label">Week</InputLabel> */}
                                                                            <Select
                                                                                // labelId="demo-simple-select-helper-label"
                                                                                // id="demo-select-small"
                                                                                value={week}
                                                                                onChange={handleChange}
                                                                                sx={{ color: 'black', "& .MuiSelect-icon": { color: 'black' },backgroundColor:'#CCD3CA'}}
                                                                                displayEmpty
                                                                                inputProps={{ 'aria-label': 'Without label' }}
                                                                            >
                                                                                <MenuItem value={10}>Day</MenuItem>
                                                                                <MenuItem value={20}>Week</MenuItem>
                                                                                <MenuItem value={30}>Month</MenuItem>
                                                                                <MenuItem value={30}>Year</MenuItem>
                                                                            </Select>
                                                                        </FormControl>
                                                                        <Chip label={<span style={{ color: "green", fontSize: "12px", backgroundColor: "#C1F2B0" }}>3.2%<NorthEastIcon /></span>} />
                                                                    </Stack>
                                                                </Stack>
                                                                <LineChart
                                                                    sx={{
                                                                        '& .MuiAreaElement-series-data': {
                                                                            fill: "url('#myGradient')",
                                                                        },
                                                                    }}
                                                                    xAxis={[{ data: [1, 2, 3, 5, 8, 10] }]}
                                                                    series={[
                                                                        {
                                                                            id: 'data',
                                                                            curve: "linear",
                                                                            data: [2.9, 5.5, 5, 4.6, 6.2, 5.8],
                                                                            area: true,
                                                                        },
                                                                    ]}
                                                                    maxwidth={300}
                                                                    height={250}
                                                                >
                                                                    <defs>
                                                                        <linearGradient id="myGradient" gradientTransform="rotate(90)">
                                                                            <stop offset="5%" stopColor="#8576FF" />
                                                                            <stop offset="95%" stopColor="#fff" />
                                                                        </linearGradient>
                                                                    </defs>
                                                                </LineChart>
                                                            </Item>
                                                        </Grid>
                                                    </Grid>
                                                    <div style={{ paddingBottom: "5px" }}></div>
                                                    <Grid item xs={12}>
                                                        <Grid>
                                                            <Item style={{ backgroundColor: "#fff" }}>
                                                                <BarChart
                                                                    dataset={dataset}
                                                                    // yAxis={[{ scaleType: 'band', dataKey: 'month',barGapRatio: 0.9 }]}
                                                                    // series={[{ dataKey: 'seoul', label: 'Seoul rainfall', valueFormatter }]}
                                                                    // layout="horizontal"
                                                                    grid={{ vertical: true }}
                                                                    {...chartSetting}
                                                                    yAxis={[{ scaleType: 'band', dataKey: 'details', }]}
                                                                    series={[
                                                                        { dataKey: 'jobs', valueFormatter },
                                                                        { dataKey: 'dhaba', valueFormatter },
                                                                        { dataKey: 'mech', valueFormatter },
                                                                        { dataKey: 'profile', valueFormatter },
                                                                        { dataKey: 'convey', valueFormatter },
                                                                        { dataKey: 'others', valueFormatter },
                                                                    ]}
                                                                    layout="horizontal"
                                                                    // width={500}
                                                                    // height={300}
                                                                    maxwidth={250}
                                                                    height={250}

                                                                />
                                                            </Item>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>

                                            <Grid item xs={12} md={6} style={{ color: "black" }}>
                                                <Item style={{ backgroundColor: "#fff" }}>
                                                    <Typography style={{ color: "black", textAlign: "left", fontSize:"16px" }}><b>Transactions and settlements for Dhaba</b>
                                                    </Typography><br></br>
                                                    <Stack direction="row" spacing={1}>
                                                        <Typography style={{ color: "black", backgroundColor: "#92e6a7",borderRadius: '10px' }}>
                                                            <Stack direction="column" width="200px">
                                                                <h1 style={{ fontSize: "16px" }}><b>Total Drivers</b></h1><h1 style={{ color: "green", fontSize: "16px" }}>484</h1><p style={{ fontSize: "12px" }}>as of 01-April-2024</p>
                                                            </Stack>
                                                        </Typography>
                                                        <Typography style={{ color: "black", backgroundColor: "#d7e3fc",borderRadius: '10px' }}>
                                                            <Stack direction="column" width="200px">
                                                                <h1 style={{ fontSize: "16px" }}><b>Total Payments</b></h1><h1 style={{ color: "blue", fontSize: "16px" }}>Rs.23500</h1><p style={{ fontSize: "12px" }}>as of 01-April-2024</p>
                                                            </Stack>
                                                        </Typography>
                                                    </Stack>
                                                    <br></br>
                                                    <div>
                                                        <Select value={dateFilter} onChange={handleDateFilterChange} sx={{ color: 'black','& .MuiSelect-icon': { color: 'black' },textAlign:'left',backgroundColor:"#C0D6E8" ,justifyContent:'left',marginLeft:"-320px" }}>
                                                            <MenuItem value="recents">Recents</MenuItem>
                                                            <MenuItem value="day">Day</MenuItem>
                                                            <MenuItem value="week">Week</MenuItem>
                                                            <MenuItem value="month">Month</MenuItem>
                                                            <MenuItem value="year">Year</MenuItem>
                                                        </Select>
                                                        <TableContainer component={Paper} sx={{ backgroundColor: '#ffffff' }}>
                                                            <Table aria-label="Drivers and Payments Table" sx={{ border: 'none' }}>
                                                                <TableHead>
                                                                    <TableRow>
                                                                        <TableCell sx={{ color: 'black' }}>Driver Name</TableCell>
                                                                        <TableCell sx={{ color: 'black' }}>Transaction ID</TableCell>
                                                                        <TableCell sx={{ color: 'black' }}>Date</TableCell>
                                                                        <TableCell sx={{ color: 'black' }}>Payments</TableCell>
                                                                    </TableRow>
                                                                </TableHead>
                                                                <TableBody>
                                                                    {filteredDrivers.map((driver, index) => (
                                                                        <TableRow key={index} sx={{ border: 'none' }}>
                                                                            <TableCell component="th" scope="row" sx={{ border: 'none', color: 'black' }}>
                                                                                {driver.name}
                                                                            </TableCell>
                                                                            <TableCell sx={{ border: 'none', color: 'black' }}>{driver.transactionID}</TableCell>
                                                                            <TableCell sx={{ border: 'none', color: 'black' }}>{driver.date}</TableCell>
                                                                            <TableCell sx={{ border: 'none', color: 'black' }}>{driver.payment}</TableCell>
                                                                        </TableRow>
                                                                    ))}
                                                                </TableBody>
                                                            </Table>
                                                        </TableContainer>
                                                    </div>
                                                </Item>

                                            </Grid>
                                        </Grid>



                                    </Grid>
                                    {/* third column */}
                                    <Grid item xs={12} md={3} style={{ color: "black" }}>
                                        <Grid>
                                            <Item style={{ backgroundColor: "#fff" }}>
                                                <Typography style={{ textAlign: "left", fontSize: "20px", color: "#4d4f5c" }}>User Activity Timeline</Typography>
                                                <Search>
                                                    <SearchIconWrapper style={{color:"black"}}>
                                                        <SearchIcon style={{color:"black"}}/>
                                                    </SearchIconWrapper>
                                                    <StyledInputBase
                                                       style={{color:"black",borderColor: 'black'}}
                                                        placeholder="Search…"
                                                        inputProps={{ 'aria-label': 'search' }}
                                                    />
                                                </Search>

                                                <Timeline position="alternate">
                                                    <TimelineItem>
                                                        <TimelineOppositeContent color="black">
                                                            09:30 am
                                                        </TimelineOppositeContent>
                                                        <TimelineSeparator>
                                                            <TimelineDot />
                                                            <TimelineConnector />
                                                        </TimelineSeparator>
                                                        <TimelineContent style={{ color: "black", fontSize: "14px" }}>Earned 500 points<br></br>
                                                            <Typography style={{ color: "black", fontSize: "10px" }}>Earned 500 points</Typography>
                                                        </TimelineContent>

                                                    </TimelineItem>
                                                    <TimelineItem>
                                                        <TimelineOppositeContent color="black">
                                                            10:00 am
                                                        </TimelineOppositeContent>
                                                        <TimelineSeparator>
                                                            <TimelineDot color="secondary" />
                                                            <TimelineConnector />
                                                        </TimelineSeparator>
                                                        <TimelineContent style={{ color: "black", fontSize: "14px" }}>Joined the program<br></br>
                                                            <Typography style={{ color: "black", fontSize: "10px" }}>Customer Joined the program</Typography>
                                                        </TimelineContent>
                                                    </TimelineItem>
                                                    <TimelineItem>
                                                        <TimelineOppositeContent color="black">
                                                            12:00 am
                                                        </TimelineOppositeContent>
                                                        <TimelineSeparator>
                                                            <TimelineDot color="success" />
                                                            <TimelineConnector />
                                                        </TimelineSeparator>
                                                        <TimelineContent style={{ color: "black", fontSize: "14px" }}>8 Invoices have been paid<br></br>
                                                            <Typography style={{ color: "black", fontSize: "10px" }}>Invoices have been paid to the company</Typography>
                                                        </TimelineContent>
                                                    </TimelineItem>
                                                    <TimelineItem>
                                                        <TimelineOppositeContent color="black">
                                                            9:00 am
                                                        </TimelineOppositeContent>
                                                        <TimelineSeparator>
                                                            <TimelineDot color="primary" />
                                                            <TimelineConnector />
                                                        </TimelineSeparator>
                                                        <TimelineContent style={{ color: "black", fontSize: "14px" }}>Order #36675<br></br>
                                                            <Typography style={{ color: "black", fontSize: "10px" }}>Invoices have been paid to the company</Typography>
                                                        </TimelineContent>
                                                    </TimelineItem>
                                                </Timeline>
                                            </Item>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            {/* </Grid> */}
                        </Box>
                    </Box>
                </Dialog>

            </React.Fragment>

        </div>


    );
}
