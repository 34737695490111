import * as React from 'react';
import Stack from '@mui/material/Stack';
import AddIcon from '@mui/icons-material/Add';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import { Box, Button, TextField, Grid } from '@mui/material';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import HelpIcon from '@mui/icons-material/Help';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import Quill from 'quill';
import { emojiIcon } from 'quill-emoji';
import FormGroup from '@mui/material/FormGroup';
import Checkbox from '@mui/material/Checkbox';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import FormControlLabel from '@mui/material/FormControlLabel';
import {useState} from 'react';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Projects from '../Projects/Projects';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function AddTasks() {
    const [open, setOpen] = React.useState(false);
    const [showFields, setShowFields] = useState(false);
    const [taskCategory,setTaskCategory]=React.useState('');
    const [project,setProject]=React.useState('');
    const[assignedTo,setAssignedTo]=React.useState('');
    const[milestones,setMilestones]=React.useState('');
    const[label,setLabel]=React.useState('');
    const[priority,setPriority]=React.useState('');
    const[description,setDescription]=React.useState('');
    const[status,setStatus]=React.useState('');
    const[startDate,setStartDate]=React.useState(null);
    const[dueDate,setDueDate]=React.useState(null);
    const[photo,setPhoto]=React.useState('');
    const[title,setTitle]=React.useState('');
    const[titleError,setTitleError]=React.useState('');

    

    const handleStartDateChange = (newDate) => {
        setStartDate(newDate); // Update the date state
        console.log(newDate); // Log the new date to the console
    };
    
    const handleDueDateChange = (newDate) => {
        setDueDate(newDate); // Update the date state
        console.log(newDate); // Log the new date to the console
    };


    const handleChange = (value) => {
        setDescription(value); // Update the state with the new value
       // console.log(value); // Print the value to the console
    };

    const handlePhoto = (e) => {
        const file = e.target.files[0];
        setPhoto(file.name);
            console.log(file.name);// You can also upload the file to a server if needed
    };

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const toggleFields = () => {
        setShowFields(!showFields);
    };

    const handleSave = () => {
        const list = {title,taskCategory,project,startDate,dueDate,assignedTo,description,label,status,milestones,priority,photo };
        console.log(list)
        if (title === '') {
            setTitleError(true);
        } else {
            const list = {title,taskCategory,project,startDate,dueDate,assignedTo,description,label,status,milestones,priority,photo};
            console.log(list);
            setTitleError(false); // Reset the error state
            handleClose(); // Close the dialog
        }
    };

    // Register emoji toolbar icon
    // const CustomToolbar = () => (
    //     <div id="toolbar">
    //         <button className="ql-emoji">
    //             {emojiIcon}
    //         </button>
    //     </div>
    // );

    // Register emoji module
    //Quill.register('modules/emoji', require('quill-emoji').default);

    // Set Quill toolbar options
    // const modules = {
    //     toolbar: {
    //         container: '#toolbar',
    //         handlers: {
    //             emoji: function () {
    //                 // Show emoji picker
    //                 const range = this.quill.getSelection();
    //                 this.quill.emojiPicker.show(range);
    //             }
    //         }
    //     },
    //     emoji: true // Enable emoji module
    // };
        return (
            <div>
                <React.Fragment>
                    <Stack direction="row" spacing={2}>
                        <Button variant="contained" onClick={handleClickOpen} sx={{ backgroundColor: 'blue', '&:hover': { backgroundColor: 'orange' }, textAlign: 'center', marginLeft: '10px' }}><AddIcon />Add New Tasks</Button>
                    </Stack>
                    <Dialog
                        fullScreen
                        open={open}
                        onClose={handleClose}
                        TransitionComponent={Transition}
                        PaperProps={{
                            style: {
                                backgroundColor: 'white',
                            },
                        }}
                    >

                        <AppBar sx={{ position: 'relative' }}>
                            <Toolbar>
                                <IconButton
                                    edge="start"
                                    color="inherit"
                                    onClick={handleClose}
                                    aria-label="close"
                                >
                                    <CloseIcon />
                                </IconButton>
                                <Typography sx={{ ml: 2, flex: 1 }} component="div">
                                    Add Task
                                </Typography>

                            </Toolbar>

                        </AppBar>
                        <Box>
                            <Typography sx={{ ml: 2, flex: 1, color: 'black', fontSize: "20px" }} component="div">
                                Task Info
                            </Typography>
                            <hr sx={{ color: 'gray', }} />
                            <Grid container spacing={2} sx={{ marginTop: "20px", marginLeft: "40px" }}>
                                <Stack direction="row" spacing={2} sx={{ width: '100%' }}>
                                    <Grid item xs={12} md={4} sm={4} >
                                        <Stack direction="column">
                                            <Typography variant="p" gutterBottom style={{ color: 'black' }}>
                                                Title<span style={{ color: 'red' }}>*</span>
                                            </Typography>
                                            <TextField id="outlined-basic" variant="outlined" placeholder='eg.Enter a Title' value={title} 
                                         onChange={(e) => setTitle(e.target.value)}
                                                inputProps={{
                                                    style: { color: 'black' }
                                                }}
                                                sx={{
                                                    border: '1px solid black',
                                                    boxSizing: 'border-box',
                                                    '& .MuiOutlinedInput-root': {
                                                        borderColor: 'black !important',
                                                        '&:hover fieldset': {
                                                            borderColor: 'black !important',
                                                        },
                                                        '&.Mui-focused fieldset': {
                                                            borderColor: 'black !important',
                                                        },
                                                    },
                                                    color: "black"
                                                }} />
                                                {titleError && <Typography variant="caption" style={{ color: 'red' }}>Please select a title</Typography>}
                                        </Stack>
                                    </Grid>
                                    <Grid item xs={6} >
                                        <Stack direction="column">
                                            <Typography variant="p" gutterBottom style={{ color: 'black' }}>
                                                Task Category
                                            </Typography>
                                            <Select
                                                value={taskCategory}
                                                autoFocus
                                                onChange={(e) => setTaskCategory(e.target.value)}
                                                displayEmpty
                                                inputProps={{ 'aria-label': 'Without label' }}
                                                sx={{
                                                    border: '1px solid black',
                                                    boxSizing: 'border-box',
                                                    '& .MuiOutlinedInput-root': {
                                                        borderColor: 'black !important',
                                                        '&:hover fieldset': {
                                                            borderColor: 'black !important',
                                                        },
                                                        '&.Mui-focused fieldset': {
                                                            borderColor: 'black !important',
                                                        },
                                                    },
                                                    color: "black",
                                                    width: "50%"
                                                }}
                                            >
                                                <MenuItem disabled value="">
                                                    <em>---</em>
                                                </MenuItem>
                                                <MenuItem value={"AndroidApp"}>Android Application</MenuItem>
                                                <MenuItem value={"MobileApp"}>Mobile Application</MenuItem>
                                            </Select>
                                        </Stack>
                                    </Grid>
                                </Stack>
                            </Grid>
                            <br></br>
                            <Grid container spacing={2} sx={{ marginLeft: "20px" }}>
                                <Grid item xs={6} >
                                    <Stack direction="column">
                                        <Typography variant="p" gutterBottom style={{ color: 'black' }}>
                                            Project<HelpIcon></HelpIcon>
                                        </Typography>
                                        <Select
                                            value={project}
                                            autoFocus
                                            onChange={(e) => setProject(e.target.value)}
                                            displayEmpty
                                            inputProps={{ 'aria-label': 'Without label' }}
                                            sx={{
                                                border: '1px solid black',
                                                boxSizing: 'border-box',
                                                '& .MuiOutlinedInput-root': {
                                                    borderColor: 'black !important',
                                                    '&:hover fieldset': {
                                                        borderColor: 'black !important',
                                                    },
                                                    '&.Mui-focused fieldset': {
                                                        borderColor: 'black !important',
                                                    },
                                                },
                                                color: "black",
                                                width: "50%"
                                            }}
                                        >
                                            <MenuItem disabled value="">
                                                <em>---</em>
                                            </MenuItem>
                                            <MenuItem value={"AndroidApp"}>Android Application</MenuItem>
                                            <MenuItem value={"MobileApp"}>Mobile Application</MenuItem>
                                        </Select>
                                    </Stack>
                                </Grid>
                            </Grid>
                            <br></br>
                            <Grid container spacing={2} sx={{ marginLeft: "40px" }} >
                                <Stack direction="row" spacing={2} sx={{ width: "100%"}}>
                                    <Grid item xs={12} md={4} sm={4} >
                                        <Stack direction="column">
                                            <Typography variant="p" gutterBottom style={{ color: 'black' }}>
                                                Start Date<span style={{ color: 'red' }}>*</span>
                                            </Typography>
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DemoContainer
                                                components={['DatePicker']}
                                                onChange={(e) => handleStartDateChange(e.target.value)}
                                                sx={{ marginTop: "-10px" }}
                                            >
                                                <DatePicker
                                                    value={startDate}
                                                    onChange={handleStartDateChange}
                                                    sx={{
                                                        border: '1px solid black',
                                                        '& .MuiOutlinedInput-root': {
                                                            borderColor: 'black !important',
                                                            color: "black !important",
                                                            '&:hover fieldset': {
                                                                borderColor: 'black !important',
                                                            },
                                                            '&.Mui-focused fieldset': {
                                                                borderColor: 'black !important',
                                                            },
                                                        },
                                                        '& .MuiSvgIcon-root': {
                                                            color: 'black',
                                                        },
                                                    }}
                                                >
                                                        {/* TextField component now becomes a child of DatePicker */}
                                                        {(params) => (
                                                        <TextField
                                                            {...params}
                                                            variant="outlined"
                                                        // Add any additional TextField props if needed
                                                        />
                                                    )}
                                                </DatePicker>
                                            </DemoContainer>

                                        </LocalizationProvider>
                                        </Stack>
                                    </Grid>
                                    <Grid item xs={12} md={4} sm={4} >
                                        <Stack direction="column">
                                            <Typography variant="p" gutterBottom style={{ color: 'black' }}>
                                                Due Date<span style={{ color: 'red' }}>*</span>
                                            </Typography>
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DemoContainer
                                                components={['DatePicker']}
                                                onChange={(e) => handleDueDateChange(e.target.value)}
                                                sx={{ marginTop: "-10px" }}
                                            >
                                                <DatePicker
                                                    value={dueDate}
                                                    onChange={handleDueDateChange}
                                                    sx={{
                                                        border: '1px solid black',
                                                        '& .MuiOutlinedInput-root': {
                                                            borderColor: 'black !important',
                                                            color: "black !important",
                                                            '&:hover fieldset': {
                                                                borderColor: 'black !important',
                                                            },
                                                            '&.Mui-focused fieldset': {
                                                                borderColor: 'black !important',
                                                            },
                                                        },
                                                        '& .MuiSvgIcon-root': {
                                                            color: 'black',
                                                        },
                                                    }}
                                                >
                                                        {/* TextField component now becomes a child of DatePicker */}
                                                        {(params) => (
                                                        <TextField
                                                            {...params}
                                                            variant="outlined"
                                                        // Add any additional TextField props if needed
                                                        />
                                                    )}
                                                </DatePicker>
                                            </DemoContainer>

                                        </LocalizationProvider>
                                        </Stack>
                                    </Grid>
                                    <Grid item xs={12} md={4} sm={4} sx={{  marginTop: "20px" }}>
                                        <FormGroup>
                                            <FormControlLabel control={<Checkbox defaultChecked />} label="Without Due Date" style={{ color: 'black' }} />
                                        </FormGroup>
                                    </Grid>
                                </Stack>
                            </Grid>
                            <br></br>
                            <Grid container spacing={2} sx={{  marginLeft: "20px" }}>
                                <Grid item xs={6} >
                                    <Stack direction="column">
                                        <Typography variant="p" gutterBottom style={{ color: 'black' }}>
                                            Assigned TO<HelpIcon></HelpIcon>
                                        </Typography>
                                        <Select
                                            value={assignedTo}
                                            autoFocus
                                            onChange={(e) => setAssignedTo(e.target.value)}
                                            displayEmpty
                                            inputProps={{ 'aria-label': 'Without label' }}
                                            sx={{
                                                border: '1px solid black',
                                                boxSizing: 'border-box',
                                                '& .MuiOutlinedInput-root': {
                                                    borderColor: 'black !important',
                                                    '&:hover fieldset': {
                                                        borderColor: 'black !important',
                                                    },
                                                    '&.Mui-focused fieldset': {
                                                        borderColor: 'black !important',
                                                    },
                                                },
                                                color: "black",
                                                width: "50%"
                                            }}
                                        >
                                            <MenuItem disabled value="">
                                                <em>---</em>
                                            </MenuItem>
                                            <MenuItem value={"Andrew"}>Andrew</MenuItem>
                                            <MenuItem value={"Bohr"}>Bohr</MenuItem>
                                        </Select>
                                    </Stack>
                                </Grid>
                            </Grid>
                            <br></br>
                            <Grid container spacing={2} sx={{ marginLeft: "40px" }}>
                                    <Stack direction="column" sx={{ width: "100%" }}>
                                        <Typography variant="p" gutterBottom style={{ color: 'black' }}>
                                            Description
                                        </Typography>
                                        <ReactQuill
                                            value={description}
                                            onChange={handleChange}
                                            // modules={modules}
                                            // theme="snow"
                                            style={{
                                               // border: '1px solid black',
                                                // borderRadius: '4px',
                                                //padding: '10px',
                                                width: '80%',
                                                boxSizing: 'border-box',
                                                color: 'black',
                                                // minHeight: '100px' // Set your desired minimum height
                                            }}
                                        />
                                    </Stack>
                            </Grid>
                            <br></br>
                            <Grid container spacing={2} sx={{  marginLeft: "40px",marginTop:"40px" }}>
                               <Typography variant="p" gutterBottom style={{ml: 2, flex: 1, color: 'black',fontSize:"20px" }}>
                                    <KeyboardArrowDownIcon onClick={toggleFields}></KeyboardArrowDownIcon> Other Details
                                </Typography>
                                {showFields && (
                                     <>
                                    <Grid container spacing={2} sx={{  marginLeft: "20px" ,marginTop:"20px"}}>
                                    <Stack direction="column" sx={{ width: '100%' }}>
                                        <Grid item xs={6} >
                                            <Stack direction="column">
                                                <Typography variant="p" gutterBottom style={{ color: 'black' }}>
                                                    Label
                                                </Typography>
                                                <Select
                                                    value={label}
                                                    autoFocus
                                                    onChange={(e) => setLabel(e.target.value)}
                                                    displayEmpty
                                                    inputProps={{ 'aria-label': 'Without label' }}
                                                    sx={{
                                                        border: '1px solid black',
                                                        boxSizing: 'border-box',
                                                        '& .MuiOutlinedInput-root': {
                                                            borderColor: 'black !important',
                                                            '&:hover fieldset': {
                                                                borderColor: 'black !important',
                                                            },
                                                            '&.Mui-focused fieldset': {
                                                                borderColor: 'black !important',
                                                            },
                                                        },
                                                        color: "black",
                                                        width: "50%"
                                                    }}
                                                >
                                                    <MenuItem disabled value="">
                                                        <em>---</em>
                                                    </MenuItem>
                                                    <MenuItem value={"Andrew"}>Andrew</MenuItem>
                                                    <MenuItem value={"Bohr"}>Bohr</MenuItem>
                                                </Select>
                                            </Stack>
                                        </Grid>
                                        <Grid item xs={6} >
                                            <Stack direction="column">
                                                <Typography variant="p" gutterBottom style={{ color: 'black' }}>
                                                    Milestones
                                                </Typography>
                                                <Select
                                                    value={milestones}
                                                    autoFocus
                                                    onChange={(e) => setMilestones(e.target.value)}
                                                    displayEmpty
                                                    inputProps={{ 'aria-label': 'Without label' }}
                                                    sx={{
                                                        border: '1px solid black',
                                                        boxSizing: 'border-box',
                                                        '& .MuiOutlinedInput-root': {
                                                            borderColor: 'black !important',
                                                            '&:hover fieldset': {
                                                                borderColor: 'black !important',
                                                            },
                                                            '&.Mui-focused fieldset': {
                                                                borderColor: 'black !important',
                                                            },
                                                        },
                                                        color: "black",
                                                        width: "50%"
                                                    }}
                                                >
                                                    <MenuItem disabled value="">
                                                        <em>---</em>
                                                    </MenuItem>
                                                    <MenuItem value={"Andrew"}>Andrew</MenuItem>
                                                    <MenuItem value={"Bohr"}>Bohr</MenuItem>
                                                </Select>
                                            </Stack>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Stack direction="column">
                                                <Typography variant="p" gutterBottom style={{ color: 'black' }}>
                                                    Status
                                                </Typography>
                                                <Select
                                                    value={status}
                                                    autoFocus
                                                    onChange={(e) => setStatus(e.target.value)}
                                                    displayEmpty
                                                    inputProps={{ 'aria-label': 'Without label' }}
                                                    sx={{
                                                        border: '1px solid black',
                                                        boxSizing: 'border-box',
                                                        '& .MuiOutlinedInput-root': {
                                                            borderColor: 'black !important',
                                                            '&:hover fieldset': {
                                                                borderColor: 'black !important',
                                                            },
                                                            '&.Mui-focused fieldset': {
                                                                borderColor: 'black !important',
                                                            },
                                                        },
                                                        color: "black",
                                                        width: "50%"
                                                    }}
                                                >
                                                    <MenuItem disabled value="">
                                                        <em>---</em>
                                                    </MenuItem>
                                                    <MenuItem value={"Andrew"}>Andrew</MenuItem>
                                                    <MenuItem value={"Bohr"}>Bohr</MenuItem>
                                                </Select>
                                            </Stack>
                                        </Grid>
                                        <br></br>
                                        <Grid container spacing={2} >
                                        <Stack direction="column">
                                               <Typography variant="p" gutterBottom style={{ color: 'black' }}>
                                                    Priority
                                                </Typography>
                                                <Select
                                                    value={priority}
                                                    autoFocus
                                                    onChange={(e) => setPriority(e.target.value)}
                                                    displayEmpty
                                                    inputProps={{ 'aria-label': 'Without label' }}
                                                    sx={{
                                                        border: '1px solid black',
                                                        boxSizing: 'border-box',
                                                        '& .MuiOutlinedInput-root': {
                                                            borderColor: 'black !important',
                                                            '&:hover fieldset': {
                                                                borderColor: 'black !important',
                                                            },
                                                            '&.Mui-focused fieldset': {
                                                                borderColor: 'black !important',
                                                            },
                                                        },
                                                        color: "black",
                                                        width: "50%"
                                                    }}
                                                >
                                                    <MenuItem disabled value="">
                                                        <em>---</em>
                                                    </MenuItem>
                                                    <MenuItem value={"High"}>High</MenuItem>
                                                    <MenuItem value={"Medium"}>Medium</MenuItem>
                                                    <MenuItem value={"Low"}>Low</MenuItem>
                                                </Select>
                                            </Stack>
                                        </Grid>
                                        <Grid container spacing={2} >
                                          <Stack direction="row" sx={{ width: '100%' }}>
                                            <Grid item xs={12} md={4} sm={4}>
                                                <FormGroup>
                                                    <FormControlLabel control={<Checkbox defaultChecked />} label="Make Private " style={{ color: 'black' }} />
                                                    <HelpIcon></HelpIcon>
                                                </FormGroup>
                                            </Grid>
                                            <Grid item xs={12} md={4} sm={4}>
                                                <FormGroup>
                                                    <FormControlLabel control={<Checkbox defaultChecked />} label="Billable" style={{ color: 'black' }} />
                                                    <HelpIcon></HelpIcon>
                                                </FormGroup>
                                            </Grid>
                                            <Grid item xs={12} md={4} sm={4}>
                                                <FormGroup>
                                                    <FormControlLabel control={<Checkbox defaultChecked />} label="Without Due Date" style={{ color: 'black' }} />
                                                </FormGroup>
                                            </Grid>
                                          </Stack>
                                        </Grid>
                                        <Grid container spacing={2} >
                                          <Stack direction="row" sx={{ width: '100%' }}>
                                            <Grid item xs={12} md={4} sm={4}>
                                                <FormGroup>
                                                    <FormControlLabel control={<Checkbox defaultChecked />} label="Repeat" style={{ color: 'black' }} />
                                                    <HelpIcon></HelpIcon>
                                                </FormGroup>
                                            </Grid>
                                            <Grid item xs={12} md={4} sm={4}>
                                                <FormGroup>
                                                    <FormControlLabel control={<Checkbox defaultChecked />} label="Task is dependent on another task" style={{ color: 'black' }} />
                                                    <HelpIcon></HelpIcon>
                                                </FormGroup>
                                            </Grid>
                                          </Stack>
                                        </Grid>
                                        <Grid container spacing={2} >
                                           <Stack direction="column" sx={{ width: "100%" }}>
                                               <Typography variant="p" gutterBottom style={{ color: 'black' }}>
                                                   Photo <HelpIcon></HelpIcon>
                                               </Typography>
                                               <label htmlFor="file-upload"
                                                   style={{
                                                       cursor: 'pointer', color: 'black',
                                                       border: '1px solid black',
                                                       borderRadius: '4px',
                                                       padding: '10px',
                                                       width: '100%',
                                                       boxSizing: 'border-box',
                                                       minHeight: '100px', // Set your desired minimum height,
                                                       justifyContent: "center"
                                                   }}>
                                                   Choose a file
                                               </label>
                                               <input
                                                   type="file"
                                                   id="file-upload"
                                                   style={{ display: 'none' }}
                                                   onChange={handlePhoto} // Call handleFileChange when file changes
                                               />
                                               {photo && <div style={{ color: 'black' }}>{photo}</div>}
                                           </Stack>
                                        </Grid>
                                    </Stack>
                                </Grid>
                                
                                </>
                                )}
                            </Grid>
                            
                            <Button variant="contained" autoFocus color="inherit" onClick={handleSave} sx={{ backgroundColor: 'blue', '&:hover': { backgroundColor: 'orange' }, textAlign: 'center', margin: '25px' }}>
                                Save
                            </Button>

                        </Box>
                    </Dialog>

                </React.Fragment>

            </div>


        );
    }

