import React from 'react';
import TabsAppSettings from './TabsAppSettings';

function AppSettings() {
  return (
    <div>
      <TabsAppSettings/>
    </div>
  )
}

export default AppSettings

