import React from 'react';
import SettingsSidebar from './SettingsSidebar';
import { ColorModeContext, useMode } from "../../theme";
import { Box, CssBaseline, ThemeProvider } from "@mui/material";
import AppSettings from './AppSettings/AppSettings';

function MainSettings() {

  const [theme = {}, colorMode] = useMode();

  return (
    <ColorModeContext.Provider value={colorMode}>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <div className="app">

        <SettingsSidebar/>

        <main className="content">
          
          <Box m="20px" sx={{display:"flex"}}>
           {/* <box  display="flex" justifyContent="space-between" alignItems="center"> */}

           <AppSettings />
            
        {/* </box> */}
          </Box>
         
        </main>  
      </div>
    </ThemeProvider>
  </ColorModeContext.Provider>

  )
}

export default MainSettings
