import React from 'react';
import 'tailwindcss/tailwind.css';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import { Typography } from '@mui/material';
import Paper from '@mui/material/Paper';
import LocalActivityIcon from '@mui/icons-material/LocalActivity';
import { PieChart } from '@mui/x-charts/PieChart';
import { Placeholder } from 'react-bootstrap';

import PropTypes from 'prop-types';
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import DeleteIcon from '@mui/icons-material/Delete';
import FilterListIcon from '@mui/icons-material/FilterList';


function createData(jobs, applied, phone_screen, interview,hired,rejected) {
    const density = jobs / rejected;
    return { jobs, applied, phone_screen, interview,hired,rejected,density };
  }
  

  const rows = [
    createData('Software Developer','' ,'1 Candidate','', '',''),
    createData('Software Tester','' ,'','1 Candidate', '',''),
    createData('Designer','' ,'1 Candidate','', '1 Candidate','1 Candidate'),
  ];

const columns = [
    { id: 'jobs', label: 'Jobs', minWidth: 170 },
    { id: 'applied', label: 'Applied', minWidth: 100 ,},
    {
      id: 'phone_screen',
      label: 'Phone Screen',
      minWidth: 170,
    },
    {
      id: 'interview',
      label: 'Interview',
      minWidth: 170,
    },
    {
      id: 'hired',
      label: 'Hired',
      minWidth: 170,
    },
    {
        id: 'rejected',
        label: 'Rejected',
        minWidth: 170,
      },
  ];

function EnhancedTableHead(props) {
    // const { onRequestSort } =
    //     props;
    // const createSortHandler = (property) => (event) => {
    //     onRequestSort(event, property);
    // };

    return (
        <TableHead>
            <TableRow>

            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};

function EnhancedTableToolbar(props) {
    const { numSelected } = props;

    return (
        <Toolbar
            sx={{
                pl: { sm: 2 },
                pr: { xs: 1, sm: 1 },
                ...(numSelected > 0 && {
                    bgcolor: (theme) =>
                        alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
                }),
            }}
        >
            {numSelected > 0 ? (
                <Typography
                    sx={{ flex: '1 1 100%' }}
                    color="inherit"
                    variant="subtitle1"
                    component="div"
                >
                    {numSelected} selected
                </Typography>
            ) : (
                <Typography
                    sx={{ flex: '1 1 100%' }}
                    id="tableTitle"
                    component="div"
                >
                    Nutrition
                </Typography>
            )}

            {numSelected > 0 ? (
                <Tooltip title="Delete">
                    <IconButton>
                        <DeleteIcon />
                    </IconButton>
                </Tooltip>
            ) : (
                <Tooltip title="Filter list">
                    <IconButton>
                        <FilterListIcon />
                    </IconButton>
                </Tooltip>
            )}
        </Toolbar>
    );
}

EnhancedTableToolbar.propTypes = {
    numSelected: PropTypes.number.isRequired,
};

function RecruitDash() {
   
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

  
    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };
  
    const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(+event.target.value);
      setPage(0);
    };

    
    const colorStyles = {
        applied: '#ff0000', // red
        phone_screen: '#00ff00', // green
        interview: '#0000ff', // blue
        hired: '#ffff00', // yellow
        rejected: '#ff00ff', // purple
      };
    
      const getCellColor = (value, columnId) => {
        return value ? { backgroundColor: colorStyles[columnId] } : {};
      };

    const Item = styled(Paper)(({ theme }) => ({
        backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
        ...theme.typography.body2,
        padding: theme.spacing(1),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    }));
    return (
        <div className='py-10 min-h-screen grid place-items-center'>
            <Grid container spacing={6}>
                <Grid item xs={6} md={2.4}>
                    <Item style={{ height: '120px', backgroundColor: "#121212" }}>
                        <Typography gutterBottom variant="subtitle1" style={{ fontSize: "20px" }}>
                            Total Tickets
                        </Typography>
                        <Typography variant="body2" gutterBottom style={{ textAlign: "right" }}>
                            <LocalActivityIcon></LocalActivityIcon>
                        </Typography>
                        <Typography variant="body2" style={{ fontSize: "16px" }} color="blue">
                            275
                        </Typography>
                    </Item>
                </Grid>
                <Grid item xs={6} md={2.4}>
                    <Item style={{ height: '120px', backgroundColor: "#121212" }}>
                        <Typography gutterBottom variant="subtitle1" style={{ fontSize: "20px" }}>
                            Total Tickets
                        </Typography>
                        <Typography variant="body2" gutterBottom style={{ textAlign: "right" }}>
                            <LocalActivityIcon></LocalActivityIcon>
                        </Typography>
                        <Typography variant="body2" style={{ fontSize: "16px" }} color="blue">
                            275
                        </Typography>
                    </Item>
                </Grid>
                <Grid item xs={6} md={2.4}>
                    <Item style={{ height: '120px', backgroundColor: "#121212" }}>
                        <Typography gutterBottom variant="subtitle1" style={{ fontSize: "20px" }}>
                            Total Tickets
                        </Typography>
                        <Typography variant="body2" gutterBottom style={{ textAlign: "right" }}>
                            <LocalActivityIcon></LocalActivityIcon>
                        </Typography>
                        <Typography variant="body2" style={{ fontSize: "16px" }} color="blue">
                            275
                        </Typography>
                    </Item>
                </Grid>
                <Grid item xs={6} md={2.4}>
                    <Item style={{ height: '120px', backgroundColor: "#121212" }}>
                        <Typography gutterBottom variant="subtitle1" style={{ fontSize: "20px" }}>
                            Total Tickets
                        </Typography>
                        <Typography variant="body2" gutterBottom style={{ textAlign: "right" }}>
                            <LocalActivityIcon></LocalActivityIcon>
                        </Typography>
                        <Typography variant="body2" style={{ fontSize: "16px" }} color="blue">
                            275
                        </Typography>
                    </Item>
                </Grid>
                <Grid item xs={6} md={2.4}>
                    <Item style={{ height: '120px', backgroundColor: "#121212" }}>
                        <Typography gutterBottom variant="subtitle1" style={{ fontSize: "20px" }}>
                            Open Tickets
                        </Typography>
                        <Typography variant="body2" gutterBottom style={{ textAlign: "right" }}>
                            <LocalActivityIcon></LocalActivityIcon>
                        </Typography>
                        <Typography variant="body2" style={{ fontSize: "16px" }} color="blue">
                            42
                        </Typography>
                    </Item>
                </Grid>
                <Grid item xs={6} md={2.4}>
                    <Item style={{ height: '120px', backgroundColor: "#121212" }}>
                        <Typography gutterBottom variant="subtitle1" style={{ fontSize: "20px" }}>
                            Closed Tickets
                        </Typography>
                        <Typography variant="body2" gutterBottom style={{ textAlign: "right" }}>
                            <LocalActivityIcon></LocalActivityIcon>
                        </Typography>
                        <Typography variant="body2" style={{ fontSize: "16px" }} color="blue">
                            140
                        </Typography>
                    </Item>
                </Grid>
                <Grid item xs={6} md={2.4}>
                    <Item style={{ height: '120px', backgroundColor: "#121212" }}>
                        <Typography gutterBottom variant="subtitle1" style={{ fontSize: "20px" }}>
                            Pending Tickets
                        </Typography>
                        <Typography variant="body2" gutterBottom style={{ textAlign: "right" }}>
                            <LocalActivityIcon></LocalActivityIcon>
                        </Typography>
                        <Typography variant="body2" style={{ fontSize: "16px" }} color="blue">
                            140
                        </Typography>
                    </Item>
                </Grid>
                <Grid item xs={6} md={2.4}>
                    <Item style={{ height: '120px', backgroundColor: "#121212" }}>
                        <Typography gutterBottom variant="subtitle1" style={{ fontSize: "20px" }}>
                            Resolved Tickets
                        </Typography>
                        <Typography variant="body2" gutterBottom style={{ textAlign: "right" }}>
                            <LocalActivityIcon></LocalActivityIcon>
                        </Typography>
                        <Typography variant="body2" style={{ fontSize: "16px" }} color="blue">
                            12
                        </Typography>
                    </Item>
                </Grid>
            </Grid>
            <br></br>
            <Grid item xs={6} md={8}>
                <Item style={{ height: '300px', backgroundColor: "#121212" }}>
                    <Typography gutterBottom variant="subtitle1" style={{ fontSize: "20px" }}>
                        Total Applications
                    </Typography>
                    <Box sx={{ width: '100%' }}>
                        <Paper sx={{ width: '100%', mb: 2 }}>
                        <TableContainer sx={{ maxHeight: 440 }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
    {rows
      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
      .map((row, index) => ( // Add index as the second argument to map
        <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
          {columns.map((column) => {
            const value = row[column.id];
            return (
              <TableCell key={column.id} align={column.align} style={{...getCellColor(value, column.id)}}>
                {column.format && typeof value === 'number'
                  ? column.format(value)
                  : value}
              </TableCell>
            );
          })}
        </TableRow>
      ))}
  </TableBody>
        </Table>
      </TableContainer>
                            <TablePagination
                                rowsPerPageOptions={[5, 10, 25]}
                                component="div"
                                count={rows.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                        </Paper>
                    </Box>
                </Item>
            </Grid>
            <br></br>
            <Grid container spacing={2}>
                <Grid item xs={6} md={6}>
                    <Item style={{ height: '250px', width: '580px', backgroundColor: "#121212" }}>
                        <Typography gutterBottom variant="subtitle1" style={{ fontSize: "20px" }}>
                            Application Sources
                        </Typography>
                        <PieChart
                            series={[
                                {
                                    data: [
                                        { id: 0, value: 10, label: 'LinkedIN' },
                                        { id: 1, value: 15, label: 'Twitter' },
                                        { id: 2, value: 20, label: 'Facebook' },
                                        { id: 3, value: 15, label: 'Instagram' },
                                        { id: 4, value: 20, label: 'Other' },
                                    ],
                                },
                            ]}
                            width={400}
                            height={200}
                        />
                    </Item>
                </Grid>
                <Grid item xs={6} md={6}>
                    <Item style={{ height: '250px', width: '580px', backgroundColor: "#121212" }}>
                        <Typography gutterBottom variant="subtitle1" style={{ fontSize: "20px" }}>
                            Application Status
                        </Typography>
                        <PieChart
                            series={[
                                {
                                    data: [
                                        { id: 0, value: 10, label: 'Applied' },
                                        { id: 1, value: 15, label: 'Phone Screen' },
                                        { id: 2, value: 20, label: 'Interview' },
                                        { id: 3, value: 15, label: 'Hired' },
                                        { id: 4, value: 20, label: 'Rejected' },
                                    ],
                                },
                            ]}
                            width={400}
                            height={200}
                        />
                    </Item>
                </Grid>
                <Grid item xs={6} md={6}>
                    <Item style={{ height: '250px', width: '580px', backgroundColor: "#121212" }}>
                        <Typography gutterBottom variant="subtitle1" style={{ fontSize: "20px" }}>
                            Open Jobs
                        </Typography>
                        <Typography variant="body2" gutterBottom style={{ textAlign: "right" }}>
                            <LocalActivityIcon></LocalActivityIcon>
                        </Typography>
                        <Typography variant="body2" style={{ fontSize: "16px" }} color="blue">
                            12
                        </Typography>
                    </Item>
                </Grid>
                <Grid item xs={6} md={6}>
                    <Item style={{ height: '250px', width: '580px', backgroundColor: "#121212" }}>
                        <Typography gutterBottom variant="subtitle1" style={{ fontSize: "20px" }}>
                            Today's Interview
                        </Typography>
                        <Typography variant="body2" gutterBottom style={{ textAlign: "right" }}>
                            <LocalActivityIcon></LocalActivityIcon>
                        </Typography>
                        <Typography variant="body2" style={{ fontSize: "16px" }} color="blue">
                            <Placeholder>-No records Found-</Placeholder>
                        </Typography>
                    </Item>
                </Grid>
            </Grid>
        </div>
    )
}

export default RecruitDash;