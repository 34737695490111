import React, { useState } from 'react';
import { Box,Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, withStyles } from '@mui/material';
import MarkAttendance from './MarkAttendance';

const AttendanceTable = () => {
  const [attendanceData, setAttendanceData] = useState([
    { name: 'Employee 1', attendance: ['X', 'Y', 'X', 'X', 'X', 'Y', 'X', 'X', 'Y', 'X', 'X', 'Y', 'X', 'Y', 'X', 'X', 'X', 'Y', 'X', 'X', 'Y', 'X', 'X', 'Y', 'X', 'X', 'X', 'Y', 'X', 'X'] },
    { name: 'Employee 2', attendance: ['X', 'Y', 'X', 'Y', 'X', 'X', 'X', 'X', 'Y', 'X', 'Y', 'Y', 'X', 'Y', 'X', 'X', 'X', 'Y', 'X', 'X', 'Y', 'X', 'X', 'Y', 'X', 'X', 'X', 'Y', 'X', 'X'] },
    // Add more employees as needed
  ]);
  
  const monthDays = Array.from({ length: 30 }, (_, i) => i + 1);
  const weekdays = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];

  const toggleAttendance = (employeeIndex, dayIndex) => {
    const newAttendanceData = [...attendanceData];
    newAttendanceData[employeeIndex].attendance[dayIndex] =
      newAttendanceData[employeeIndex].attendance[dayIndex] === 'X' ? 'Y' : 'X';
    setAttendanceData(newAttendanceData);
  };

  const calculateTotalPresent = (attendance) => {
    return attendance.filter(status => status === 'X').length;
  };

  return (
    <Box>
      {/* <MarkAttendance></MarkAttendance> */}
      <br></br>
      <TableContainer component={Paper} sx={{ overflowX: 'auto' }}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            {Array.from({ length: 30 }, (_, i) => i + 1).map((day, index) => (
              <TableCell key={index}>{day}</TableCell>
            ))}
            <TableCell>Total</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {attendanceData.map((employee, employeeIndex) => (
            <TableRow key={employee.name}>
              <TableCell>{employee.name}</TableCell>
              {employee.attendance.map((status, dayIndex) => (
                <TableCell
                  key={dayIndex}
                  onClick={() => toggleAttendance(employeeIndex, dayIndex)}
                  style={{ cursor: 'pointer', backgroundColor: status === 'X' ? 'grey' : 'black' }}
                >
                  {status}
                </TableCell>
              ))}
              <TableCell>{calculateTotalPresent(employee.attendance)}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
    </Box>
    
  );
};

export default AttendanceTable;
