import SettingsSidebar from './SettingsSidebar';
import React from 'react';
import { ColorModeContext, useMode } from "../../theme";
import { Box, CssBaseline, ThemeProvider, Card, CardContent } from "@mui/material";

function MainProjectSettings() {
    const [theme = {}, colorMode] = useMode();
    return (
      <Box>
  
  <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <div className="app">
  
          <SettingsSidebar/>
  
          <main className="content">
            
            <Box m="20px" sx={{display:"flex"}}>
             {/* <box  display="flex" justifyContent="space-between" alignItems="center"> */}
  
             <Card sx={{ backgroundColor: '#ffffff' }}>
              <CardContent>
                <Box sx={{ width: '100%' }}>
                  
                 
                
                
                </Box>
                </CardContent>
          </Card>
          {/* </box> */}
            </Box>
           
          </main>  
        </div>
      </ThemeProvider>
    </ColorModeContext.Provider>
        
      </Box>
    )
  }

export default MainProjectSettings
