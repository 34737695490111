import React, { useState } from 'react';
import { Avatar, Button, TextField, Grid, Typography, Container, InputAdornment, IconButton   , MenuItem} from '@mui/material';
import { makeStyles } from '@mui/styles';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import dayjs from 'dayjs';
import FacebookIcon from '@mui/icons-material/Facebook';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { styled } from '@mui/material/styles';
import DayjsUtils from 'dayjs';
import BlurOnIcon from '@mui/icons-material/BlurOn';
import XIcon from '@mui/icons-material/X';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import AuthUser from '../../../Data/AuthUser';

// Custom styled DatePicker
// Custom styled DatePicker container
const StyledDatePickerContainer = styled('div')({
  '& .MuiSvgIcon-root': {
    color: 'black', // Set icon color to black
  },
});

const useStyles = makeStyles((theme) => ({
  paper: {
  
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    // margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
    width: '150px', // Increase the avatar size
    height: '150px', // Increase the avatar size
  },
  form: {
    width: '100%', // This ensures the form takes full width
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  input: {
    display: 'none',
  },
}));

const yesterday = dayjs().subtract(1, 'day');

const maritalStatusOptions = ['Married', 'Single', 'Divorced', 'Widowed'];
const genderOptions = ['Male', 'Female', 'Other'];


function ProfileLayout() {
  const classes = useStyles();
  const [avatarImage, setAvatarImage] = useState(null);

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onload = () => {
      setAvatarImage(reader.result);
    };
    if (file) {
      reader.readAsDataURL(file);
    }
  };

  const [showPassword, setShowPassword] = React.useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedState, setSelectedState] = useState(null);

  const [maritalStatus, setMaritalStatus] = useState('');
  const [spouseName, setSpouseName] = useState('');

  const handleMaritalStatusChange = (event) => {
    const status = event.target.value;
    setMaritalStatus(status);
    // Clear spouseName if marital status is not 'Married'
    if (status !== 'Married') {
      setSpouseName('');
    }
  };

  const [gender, setGender] = useState('');

  const handleGenderChange = (event) => {
    setGender(event.target.value);
  };

  // const {user} = AuthUser();


  return (
    <Container component="main">
      <div className={classes.paper}>
        <label htmlFor="avatar-input">
          <Avatar className={classes.avatar} src={avatarImage} />
          <input
            accept="image/*"
            className={classes.input}
            id="avatar-input"
            type="file"
            onChange={handleImageChange}
          />
        </label>
        <Typography component="h1" variant="h5">
          {/* Update Profile */}
        </Typography>
        <form className={classes.form} noValidate>


          <Grid container spacing={2} style={{ color: 'black' }}>
            
            <Grid item xs={12} sm={4}>
            <Typography variant="p" gutterBottom style={{ color: 'black' }}>
      Name
    </Typography>
    <Grid container spacing={2} alignItems="center">
      <Grid item xs={12}>
      <TextField
         label="enter name"
         variant="outlined"
         fullWidth
         placeholder="e.g. AsjasdAkjl**********"
         inputProps={{ style: { color: 'black' } }}
         InputLabelProps={{ style: { color: 'black' } }}
         sx={{
           '& .MuiOutlinedInput-root': { 
             borderColor: 'black',
             '&:hover fieldset': {
               borderColor: 'black',
             },
             '&.Mui-focused fieldset': {
               borderColor: 'black',
             },
           },
           '& .MuiOutlinedInput-input': {
             '&::placeholder': { color: 'gray' }
           }
         }}
        //  value={user.name} // Set the value here
        />
      </Grid>

    </Grid>

            </Grid>
            <Grid item xs={12} sm={4}>
            <Typography variant="p" gutterBottom style={{ color: 'black' }}>
      Personal Email
    </Typography>
    <Grid container spacing={2} alignItems="center">
      <Grid item xs={12}>
        <TextField
          label="enter email"
          variant="outlined"
          fullWidth
          placeholder="eg@example.com"
          type="email"
          inputProps={{ style: { color: 'black' } }}
          InputLabelProps={{ style: { color: 'black' } }}
          sx={{
            '& .MuiOutlinedInput-root': { 
              borderColor: 'black',
              '&:hover fieldset': {
                borderColor: 'black',
              },
              '&.Mui-focused fieldset': {
                borderColor: 'black',
              },
            },
            '& .MuiOutlinedInput-input': {
              '&::placeholder': { color: 'gray' }
            }
          }}
          // value={user.email} 
        />
      </Grid>

    </Grid>

            </Grid>
            <Grid item xs={12} sm={4}>
            <Typography variant="p" gutterBottom style={{ color: 'black' }}>
      Update password
    </Typography>
    <Grid container spacing={2} alignItems="center">
      <Grid item xs={12}>
      <TextField
          label="Enter password"
          variant="outlined"
          fullWidth
          placeholder="*********"
          type={showPassword ? 'text' : 'password'}
          inputProps={{
            style: { color: 'black' },
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
          InputLabelProps={{ style: { color: 'black' } }}
          sx={{
            '& .MuiOutlinedInput-root': {
              borderColor: 'black',
              '&:hover fieldset': {
                borderColor: 'black',
              },
              '&.Mui-focused fieldset': {
                borderColor: 'black',
              },
            },
            '& .MuiOutlinedInput-input': {
              '&::placeholder': { color: 'gray' },
            },
          }}
        />
      </Grid>

    </Grid>

            </Grid>
           
          </Grid>


          <Grid container spacing={2} style={{ color: 'black' , marginTop: '10px'}}>
            
            <Grid item xs={12} sm={4}>
            <Typography variant="p" gutterBottom style={{ color: 'black' }}>
      Country
    </Typography>
    <Grid container spacing={2} alignItems="center">
    <Grid item xs={12}>
    <TextField
          label="enter Country"
          variant="outlined"
          fullWidth
          placeholder="India"
          type="email"
          inputProps={{ style: { color: 'black' } }}
          InputLabelProps={{ style: { color: 'black' } }}
          sx={{
            '& .MuiOutlinedInput-root': { 
              borderColor: 'black',
              '&:hover fieldset': {
                borderColor: 'black',
              },
              '&.Mui-focused fieldset': {
                borderColor: 'black',
              },
            },
            '& .MuiOutlinedInput-input': {
              '&::placeholder': { color: 'gray' }
            }
          }}
        />
      </Grid>

    </Grid>

            </Grid>
            <Grid item xs={12} sm={4}>
            <Typography variant="p" gutterBottom style={{ color: 'black' }}>
      State
    </Typography>
    <Grid container spacing={2} alignItems="center">
    <Grid item xs={12}>
    <TextField
          label="enter State"
          variant="outlined"
          fullWidth
          placeholder="UP"
          type="email"
          inputProps={{ style: { color: 'black' } }}
          InputLabelProps={{ style: { color: 'black' } }}
          sx={{
            '& .MuiOutlinedInput-root': { 
              borderColor: 'black',
              '&:hover fieldset': {
                borderColor: 'black',
              },
              '&.Mui-focused fieldset': {
                borderColor: 'black',
              },
            },
            '& .MuiOutlinedInput-input': {
              '&::placeholder': { color: 'gray' }
            }
          }}
        />
      </Grid>

    </Grid>

            </Grid>
            <Grid item xs={12} sm={4}>
            <Typography variant="p" gutterBottom style={{ color: 'black' }}>
      Address
    </Typography>
    <Grid container spacing={2} alignItems="center">
      <Grid item xs={12}>
        <TextField
          label="enter address"
          variant="outlined"
          fullWidth
          placeholder="Address"
          inputProps={{ style: { color: 'black' } }}
          InputLabelProps={{ style: { color: 'black' } }}
          sx={{
            '& .MuiOutlinedInput-root': { 
              borderColor: 'black',
              '&:hover fieldset': {
                borderColor: 'black',
              },
              '&.Mui-focused fieldset': {
                borderColor: 'black',
              },
            },
            '& .MuiOutlinedInput-input': {
              '&::placeholder': { color: 'gray' }
            }
          }}
        />
      </Grid>

    </Grid>

            </Grid>
           
          </Grid>

          <Grid container spacing={2} style={{ color: 'black' , marginTop: '10px'}}>
            
            <Grid item xs={12} sm={4}>
            <Typography variant="p" gutterBottom style={{ color: 'black' }}>
      Mobile Number
    </Typography>
    <Grid container spacing={2} alignItems="center">
    <Grid item xs={12}>
    <TextField
          label="enter mobile Number"
          variant="outlined"
          fullWidth
          placeholder="+91 **********"
          type="email"
          inputProps={{ style: { color: 'black' } }}
          InputLabelProps={{ style: { color: 'black' } }}
          sx={{
            '& .MuiOutlinedInput-root': { 
              borderColor: 'black',
              '&:hover fieldset': {
                borderColor: 'black',
              },
              '&.Mui-focused fieldset': {
                borderColor: 'black',
              },
            },
            '& .MuiOutlinedInput-input': {
              '&::placeholder': { color: 'gray' }
            }
          }}
        />
      </Grid>

    </Grid>

            </Grid>
            <Grid item xs={12} sm={4}>
            <Typography variant="p" gutterBottom style={{ color: 'black' }}>
      Language
    </Typography>
    <Grid container spacing={2} alignItems="center">
    <Grid item xs={12}>
    <TextField
          label="Languages Known"
          variant="outlined"
          fullWidth
          placeholder="emglish, hindi, ..."
          type="email"
          inputProps={{ style: { color: 'black' } }}
          InputLabelProps={{ style: { color: 'black' } }}
          sx={{
            '& .MuiOutlinedInput-root': { 
              borderColor: 'black',
              '&:hover fieldset': {
                borderColor: 'black',
              },
              '&.Mui-focused fieldset': {
                borderColor: 'black',
              },
            },
            '& .MuiOutlinedInput-input': {
              '&::placeholder': { color: 'gray' }
            }
          }}
        />
      </Grid>

    </Grid>

            </Grid>
            <Grid item xs={12} sm={4}>
            <Typography variant="p" gutterBottom style={{ color: 'black' }}>
      Pincode
    </Typography>
    <Grid container spacing={2} alignItems="center">
      <Grid item xs={12}>
        <TextField
          label="enter PinCode"
          variant="outlined"
          fullWidth
          placeholder="Pincode"
          inputProps={{ style: { color: 'black' } , inputMode: 'numeric'}}
          InputLabelProps={{ style: { color: 'black' } }}
          sx={{
            '& .MuiOutlinedInput-root': { 
              borderColor: 'black',
              '&:hover fieldset': {
                borderColor: 'black',
              },
              '&.Mui-focused fieldset': {
                borderColor: 'black',
              },
            },
            '& .MuiOutlinedInput-input': {
              '&::placeholder': { color: 'gray' }
            }
          }}
        />
      </Grid>

    </Grid>

            </Grid>
           
          </Grid>


          <Grid container spacing={2} style={{ color: 'black' , marginTop: '10px'}}>
            
            <Grid item xs={12} sm={4}>
            <Typography variant="p" gutterBottom style={{ color: 'black' }}>
      Gender
    </Typography>
    <Grid container spacing={2} alignItems="center">
    <Grid item xs={12}>
    <TextField
      select
      label="Gender"
      value={gender}
      onChange={handleGenderChange}
      fullWidth
      placeholder="Gender"
      inputProps={{ style: { color: 'black' } }}
        InputLabelProps={{ style: { color: 'black' } }}
        sx={{
          '& .MuiSelect-root': {
            color: 'black', // Set text color to black for the selected option
          },
          '& .MuiOutlinedInput-root': {
            borderColor: 'black', // Set border color to black
            '&:hover fieldset': {
              borderColor: 'black',
            },
            '&.Mui-focused fieldset': {
              borderColor: 'black',
            },
          },
          '& .MuiOutlinedInput-input': {
            '&::placeholder': { color: 'gray' }, // Set placeholder color to gray
            color: 'black', // Set text color to black
          }
        }}
    >
      {genderOptions.map((option) => (
        <MenuItem key={option} value={option}>
          {option}
        </MenuItem>
      ))}
    </TextField>
      </Grid>

    </Grid>

            </Grid>
            <Grid item xs={12} sm={4}>
            <Typography variant="p" gutterBottom style={{ color: 'black' }}>
            Marital Status
    </Typography>
    <Grid container spacing={2} alignItems="center">
    <Grid item xs={12}>
          <TextField
        select
        label="Marital Status"
        value={maritalStatus}
        onChange={handleMaritalStatusChange}
        fullWidth
        inputProps={{ style: { color: 'black' } }}
        InputLabelProps={{ style: { color: 'black' } }}
        sx={{
          '& .MuiSelect-root': {
            color: 'black', // Set text color to black for the selected option
          },
          '& .MuiOutlinedInput-root': {
            borderColor: 'black', // Set border color to black
            '&:hover fieldset': {
              borderColor: 'black',
            },
            '&.Mui-focused fieldset': {
              borderColor: 'black',
            },
          },
          '& .MuiOutlinedInput-input': {
            '&::placeholder': { color: 'gray' }, // Set placeholder color to gray
            color: 'black', // Set text color to black
          }
        }}
      >
        {maritalStatusOptions.map((option) => (
          <MenuItem key={option} value={option}>
            {option}
          </MenuItem>
        ))}
      </TextField>

      </Grid>

    </Grid>

            </Grid>

            {maritalStatus === 'Married' && (
            <Grid item xs={12} sm={4}>
            <Typography variant="p" gutterBottom style={{ color: 'black' }}>
      Anniversary Date
    </Typography>
    <Grid container spacing={2} alignItems="center">
      <Grid item xs={12}>
     
      <LocalizationProvider dateAdapter={AdapterDayjs} dateLibInstance={DayjsUtils}>
      <StyledDatePickerContainer>
        <DatePicker
          defaultValue={yesterday}
          disablePast
          views={['year', 'month', 'day']}
          renderInput={(params) => (
            <Typography {...params} sx={{ color: 'black ' }} />
          )}
        />
      </StyledDatePickerContainer>
    </LocalizationProvider>
      </Grid>

    </Grid>

            </Grid>
            )}
          </Grid>


          <Grid container spacing={2} style={{ color: 'black' }}>
            
            <Grid item xs={12} sm={4}>
            <Typography variant="p" gutterBottom style={{ color: 'black' }}>
      DOB
    </Typography>
    <Grid container spacing={2} alignItems="center">
      <Grid item xs={12}>
      <LocalizationProvider dateAdapter={AdapterDayjs} dateLibInstance={DayjsUtils}>
      <StyledDatePickerContainer>
        <DatePicker
          defaultValue={yesterday}
          disablePast
          views={['year', 'month', 'day']}
          renderInput={(params) => (
            <Typography {...params} sx={{ color: 'black' }} />
          )}
        />
      </StyledDatePickerContainer>
    </LocalizationProvider>
      </Grid>

    </Grid>

            </Grid>
            <Grid item xs={12} sm={8}>
            <Typography variant="p" gutterBottom style={{ color: 'black' }}>
      About you
    </Typography>
    <Grid container spacing={2} alignItems="center">
      <Grid item xs={12}>
        <TextField
          label="About U"
          variant="outlined"
          fullWidth
          placeholder="About"
          type="text"
          inputProps={{ style: { color: 'black' } }}
          InputLabelProps={{ style: { color: 'black' } }}
          sx={{
            '& .MuiOutlinedInput-root': { 
              borderColor: 'black',
              '&:hover fieldset': {
                borderColor: 'black',
              },
              '&.Mui-focused fieldset': {
                borderColor: 'black',
              },
            },
            '& .MuiOutlinedInput-input': {
              '&::placeholder': { color: 'gray' }
            }
          }}
        />
      </Grid>

    </Grid>

            </Grid>
                
          </Grid>


          <Grid container spacing={2} style={{ color: 'black' }}>
            
            <Grid item xs={12} sm={4}>
            <Typography variant="p" gutterBottom style={{ color: 'black' }}>
      College
    </Typography>
    <Grid container spacing={2} alignItems="center">
      <Grid item xs={12}>
        <TextField
          label="College Name"
          variant="outlined"
          fullWidth
          placeholder="College Name"
          inputProps={{ style: { color: 'black' } }}
          InputLabelProps={{ style: { color: 'black' } }}
          sx={{
            '& .MuiOutlinedInput-root': { 
              borderColor: 'black',
              '&:hover fieldset': {
                borderColor: 'black',
              },
              '&.Mui-focused fieldset': {
                borderColor: 'black',
              },
            },
            '& .MuiOutlinedInput-input': {
              '&::placeholder': { color: 'gray' }
            }
          }}
        />
      </Grid>

    </Grid>

            </Grid>
            <Grid item xs={12} sm={4}>
            <Typography variant="p" gutterBottom style={{ color: 'black' }}>
      Majors
    </Typography>
    <Grid container spacing={2} alignItems="center">
      <Grid item xs={12}>
        <TextField
          label="enter your Majors"
          variant="outlined"
          fullWidth
          placeholder="Majors"
          inputProps={{ style: { color: 'black' } }}
          InputLabelProps={{ style: { color: 'black' } }}
          sx={{
            '& .MuiOutlinedInput-root': { 
              borderColor: 'black',
              '&:hover fieldset': {
                borderColor: 'black',
              },
              '&.Mui-focused fieldset': {
                borderColor: 'black',
              },
            },
            '& .MuiOutlinedInput-input': {
              '&::placeholder': { color: 'gray' }
            }
          }}
        />
      </Grid>

    </Grid>

            </Grid>
            <Grid item xs={12} sm={4}>
            <Typography variant="p" gutterBottom style={{ color: 'black' }}>
      Skills
    </Typography>
    <Grid container spacing={2} alignItems="center">
      <Grid item xs={12}>
      <TextField
          label="enter your Skills"
          variant="outlined"
          fullWidth
          placeholder="Java,Team Lead,........."
          inputProps={{ style: { color: 'black' } }}
          InputLabelProps={{ style: { color: 'black' } }}
          sx={{
            '& .MuiOutlinedInput-root': { 
              borderColor: 'black',
              '&:hover fieldset': {
                borderColor: 'black',
              },
              '&.Mui-focused fieldset': {
                borderColor: 'black',
              },
            },
            '& .MuiOutlinedInput-input': {
              '&::placeholder': { color: 'gray' }
            }
          }}
        />
      </Grid>

    </Grid>

            </Grid>
           
          </Grid>

          <Grid container spacing={2} style={{ color: 'black' }}>
            
            <Grid item xs={12} sm={4}>
            <Typography variant="p" gutterBottom style={{ color: 'black' }}>
      LinkedIn
    </Typography>
    <Grid container spacing={2} alignItems="center">
      <Grid item xs={12}>
      <TextField
      label="LinkedIn ID"
      variant="outlined"
      fullWidth
      placeholder="link"
      inputProps={{ style: { color: 'black' } }}
      InputLabelProps={{ style: { color: 'black' } }}
      sx={{
        '& .MuiOutlinedInput-root': { 
          borderColor: 'black',
          '&:hover fieldset': {
            borderColor: 'black',
          },
          '&.Mui-focused fieldset': {
            borderColor: 'black',
          },
        },
        '& .MuiOutlinedInput-input': {
          '&::placeholder': { color: 'gray' }
        }
      }}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <IconButton>
              <LinkedInIcon sx={{ color: 'black' }} />
            </IconButton>
          </InputAdornment>
        )
      }}
    />
      </Grid>

    </Grid>

            </Grid>
            <Grid item xs={12} sm={4}>
            <Typography variant="p" gutterBottom style={{ color: 'black' }}>
      Twitter
    </Typography>
    <Grid container spacing={2} alignItems="center">
      <Grid item xs={12}>
      <TextField
      label="Twitter ID"
      variant="outlined"
      fullWidth
      placeholder="link"
      inputProps={{ style: { color: 'black' } }}
      InputLabelProps={{ style: { color: 'black' } }}
      sx={{
        '& .MuiOutlinedInput-root': { 
          borderColor: 'black',
          '&:hover fieldset': {
            borderColor: 'black',
          },
          '&.Mui-focused fieldset': {
            borderColor: 'black',
          },
        },
        '& .MuiOutlinedInput-input': {
          '&::placeholder': { color: 'gray' }
        }
      }}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <IconButton>
              <XIcon sx={{ color: 'black' }} />
            </IconButton>
          </InputAdornment>
        )
      }}
    />
      </Grid>

    </Grid>

            </Grid>
            <Grid item xs={12} sm={4}>
            <Typography variant="p" gutterBottom style={{ color: 'black' }}>
      Slack
    </Typography>
    <Grid container spacing={2} alignItems="center">
      <Grid item xs={12}>
      <TextField
      label="Slack ID"
      variant="outlined"
      fullWidth
      placeholder="link"
      inputProps={{ style: { color: 'black' } }}
      InputLabelProps={{ style: { color: 'black' } }}
      sx={{
        '& .MuiOutlinedInput-root': { 
          borderColor: 'black',
          '&:hover fieldset': {
            borderColor: 'black',
          },
          '&.Mui-focused fieldset': {
            borderColor: 'black',
          },
        },
        '& .MuiOutlinedInput-input': {
          '&::placeholder': { color: 'gray' }
        }
      }}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <IconButton>
              <BlurOnIcon sx={{ color: 'black' }} />
            </IconButton>
          </InputAdornment>
        )
      }}
    />
      </Grid>

    </Grid>

            </Grid>
           
          </Grid>

          <Grid container spacing={2} style={{ color: 'black' }}>
            
            <Grid item xs={12} sm={4}>
            <Typography variant="p" gutterBottom style={{ color: 'black' }}>
      Facebook
    </Typography>
    <Grid container spacing={2} alignItems="center">
      <Grid item xs={12}>
      <TextField
      label="Facebook ID"
      variant="outlined"
      fullWidth
      placeholder="link"
      inputProps={{ style: { color: 'black' } }}
      InputLabelProps={{ style: { color: 'black' } }}
      sx={{
        '& .MuiOutlinedInput-root': { 
          borderColor: 'black',
          '&:hover fieldset': {
            borderColor: 'black',
          },
          '&.Mui-focused fieldset': {
            borderColor: 'black',
          },
        },
        '& .MuiOutlinedInput-input': {
          '&::placeholder': { color: 'gray' }
        }
      }}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <IconButton>
              <FacebookIcon sx={{ color: 'black' }} />
            </IconButton>
          </InputAdornment>
        )
      }}
    />
      </Grid>

    </Grid>

            </Grid>
            <Grid item xs={12} sm={4}>
            <Typography variant="p" gutterBottom style={{ color: 'black' }}>
      Others
    </Typography>
    <Grid container spacing={2} alignItems="center">
      <Grid item xs={12}>
        <TextField
          label="any thing"
          variant="outlined"
          fullWidth
          placeholder="Others"
          inputProps={{ style: { color: 'black' } }}
          InputLabelProps={{ style: { color: 'black' } }}
          sx={{
            '& .MuiOutlinedInput-root': { 
              borderColor: 'black',
              '&:hover fieldset': {
                borderColor: 'black',
              },
              '&.Mui-focused fieldset': {
                borderColor: 'black',
              },
            },
            '& .MuiOutlinedInput-input': {
              '&::placeholder': { color: 'gray' }
            }
          }}
        />
      </Grid>

    </Grid>

            </Grid>
            <Grid item xs={12} sm={4}>
            <Typography variant="p" gutterBottom style={{ color: 'black' }}>
      Feed Back
    </Typography>
    <Grid container spacing={2} alignItems="center">
      <Grid item xs={12}>
      <TextField
          label="Feed Back "
          variant="outlined"
          fullWidth
          placeholder="work Experience"
          inputProps={{ style: { color: 'black' } }}
          InputLabelProps={{ style: { color: 'black' } }}
          sx={{
            '& .MuiOutlinedInput-root': { 
              borderColor: 'black',
              '&:hover fieldset': {
                borderColor: 'black',
              },
              '&.Mui-focused fieldset': {
                borderColor: 'black',
              },
            },
            '& .MuiOutlinedInput-input': {
              '&::placeholder': { color: 'gray' }
            }
          }}
        />
      </Grid>

    </Grid>

            </Grid>
           
          </Grid>



          

          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            Update
          </Button>
        </form>
      </div>
    </Container>
  );
}

export default ProfileLayout;
