import { Box, Typography, createTheme, ThemeProvider, Chip, Button } from '@mui/material';
import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import Stack from '@mui/material/Stack';
import HelpIcon from '@mui/icons-material/Help';
import GroupIcon from '@mui/icons-material/Group';
import MUIDataTable from "mui-datatables";
import { makeStyles } from '@mui/styles';
import { Placeholder } from 'react-bootstrap';
import Divider from '@mui/material/Divider';
import FlightTakeoffIcon from '@mui/icons-material/FlightTakeoff';
import { useTheme } from '@mui/material';
import { tokens } from '../../theme';
import HouseIcon from '@mui/icons-material/House';
import CampaignIcon from '@mui/icons-material/Campaign';
import ViewListIcon from '@mui/icons-material/ViewList';
import Events from '../Events/events';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import Tooltip from '@mui/material/Tooltip';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import CakeIcon from '@mui/icons-material/Cake';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';


function PrivateDash() {

  const user = JSON.parse(localStorage.getItem('user-info'));
  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }));



  //*********************** */ My tasks**********************************


  // const useStyles = makeStyles((theme) => ({
  //   root: {
  //     backgroundColor: '#ffffff', // Change background color to white
  //     // Add more styles here as needed
  //   },
  // }));
  const Taskcolumns = [
    {
      name: "",
      label: "Task#"  // to rename column
    },
    {
      name: "",
      label: "Task"
    },
    {
      name: "",
      label: "Status"
    },
    {
      name: "",
      label: "Due Date"
    }

  ];

  // ******************Tickets *************************************

  const Ticketcolumns = [
    {
      name: "",
      label: "Tickets#"  // to rename column
    },
    {
      name: "",
      label: "Ticket Subject "
    },
    {
      name: "",
      label: "Status"
    },
    {
      name: "",
      label: "Requested On"
    }

  ];


  const options = {
    //selectableRows: false,
    elevation: 0,
    rowsPerPage: 5,
    rowsPerPageOptions: [5, 10, 25, 100],
    tableBody: {
      style: {
        maxHeight: '500px',
        overflowY: 'scroll',
        marginLeft: '5PX'
      }
    },
    tableHead: {
      style: {
        backgroundColor: '#f2f2f2',
        color: 'black'
      }
    },
    rows: {
      style: {
        marginLeft: '5px',
        textAlign: 'center'
      },
      selectableRowsOnClick: true
    }
  };

  const getTheme = () =>
    createTheme({
      palette: {
        mode: 'light',
        primary: {
          main: '#2196f3',
        },
        secondary: {
          main: '#03a9f4',
        },
      },
      typography: {
        fontFamily: [
          'Roboto',
          'Helvetica',
          'Arial',
          'Sans-serif',
        ].join(','),
      },
      components: {
        MuiButton: {
          styleOverrides: {
            root: {
              textTransform: 'none',
            },
          },
        },
      },
      tableBody: {
        style: {
          maxHeight: '500px',
          overflowY: 'scroll'
        }
      },
      tableHead: {
        style: {
          backgroundColor: '#f2f2f2',
          color: 'black'
        }
      },
      rows: {
        style: {
          '&:hover': {
            backgroundColor: '#f2f2f2'
          }
        }
      }
    });


  //const classes = useStyles();


  // ********************My calender**********************************8
  const theme = useTheme();
  //const colors = tokens(theme.palette.mode);

  // const handleDateClick = (selected) => {
  //   const title = prompt('Please enter a new title for your event');
  //   const calendarApi = selected.view.calendar;
  //   calendarApi.unselect();

  //   if (title) {
  //     calendarApi.addEvent({
  //       id: `${selected.dateStr}-${title}`,
  //       title,
  //       start: selected.startStr,
  //       end: selected.endStr,
  //       allDay: selected.allDay,
  //     });
  //   }
  // };

  // const handleEventClick = (selected) => {
  //   if (
  //     window.confirm(
  //       `Are you sure you want to delete the event '${selected.event.title}'`
  //     )
  //   ) {
  //     selected.event.remove();
  //   }
  // };
  const DataWithoutHeadingTable = ({ data }) => {
    return (
      <table>
        <tbody style={{ height: "250px" }}>
          {data.map((row, rowIndex) => (
            <React.Fragment key={rowIndex}>
            <tr>
              {row.map((cell, cellIndex) => (
                <td
                  key={cellIndex}
                  style={{
                    backgroundColor: cellIndex === 2 ? '#16FF00' : 'transparent',
                    // border: cellIndex === 2 ? '1px solid #fff' : '1px solid #ddd',
                    borderRadius: cellIndex === 2 && rowIndex > -1 ? '50px' : '25px', // Border radius for cells under the third column
                    // height: cellIndex === 2 && rowIndex > -1 ? '5px' : 'auto',
                    width: "300px",
                    fontSize: cellIndex === 2 && rowIndex > -1 ? '10px' : '15px',
                  }}
                >
                  {cell}
                </td>
              ))}
            </tr>
            {rowIndex < data.length - 1 && <tr><td colSpan={row.length}><hr /></td></tr>} {/* Insert HR line except for the last row */}
           </React.Fragment>
          ))}
        </tbody>
      </table>
    );
  };

  const data = [
    ['09-04-2024', 'Tuesday', 'General Shift', 'This is default shift'],
    ['10-04-2024', 'Wednesday', 'General Shift', 'This is default shift'],
    ['11-04-2024', 'Thursday', 'General Shift', 'This is default shift'],
    ['12-04-2024', 'Friday', 'General Shift', 'This is default shift'],
    ['13-04-2024', 'Saturday', 'General Shift', 'This is default shift'],
    ['14-04-2024', 'Sunday', 'General Shift', 'This is default shift'],
    ['15-04-2024', 'Monday', 'General Shift', 'This is default shift'],
  ];
  
  
  const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 10,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: theme.palette.mode === 'light' ? '#1a90ff' : '#308fe8',
    },
  }));
  return (
    <Box display="flex" flexDirection="column">
      <Box sx={{ flexGrow: 1 }}>
        <Grid item xs={12} md={6}>
          <Grid container spacing={1}>

            {/* first column */}
            <Grid item xs={12} md={6}>

              {/* first row */}
              <Grid container spacing={1} direction="row" style={{ marginBottom: '10px' }}>
                <Grid item xs={12}>
                  <Item style={{ backgroundColor: "#fff", color: "black" }}>
                    <Stack direction="row">
                      <AccountBoxIcon sx={{ color: "grey", fontSize: "120px", justifyContent: "left" }} />
                      <Typography component="div" sx={{ marginTop: "20px" }}>
                        <div>
                        <h1 style={{ color: "black", fontSize: "20px" }}>{user.name}</h1>
                        <p style={{ color: "grey" }}>---</p>
                        <p style={{ color: "grey" }}>Employee Id: 21</p>
                        </div>
                      </Typography>
                    </Stack>

                    <hr></hr></Item>
                </Grid>
              </Grid>

              {/* second row */}
              <Grid container spacing={1} direction="row" style={{ marginBottom: '10px' }}>
                <Grid item xs={6}>
                  <Item style={{ height: '80px', backgroundColor: "#fff", marginTop: "-5px" }}>
                    <Stack direction="row" width="100%" textAlign="center" spacing={2}>
                      <Typography component="div" variant="body2" style={{ fontSize: "15px" }} color="black">
                        <p style={{ color: "grey" }}>In Progress</p><br></br>25
                      </Typography>
                    </Stack>
                  </Item>
                </Grid>
                <Grid item xs={6}>
                  <Item style={{ height: '80px', backgroundColor: "#fff", marginTop: "-5px" }}>
                    <Stack direction="row" width="100%" textAlign="center" spacing={2}>
                      <Typography component="div" variant="body2" style={{ fontSize: "15px" }} color="black">
                        <p style={{ color: "grey" }}>Overdue</p><br></br>0
                      </Typography>
                    </Stack>
                  </Item>
                </Grid>
              </Grid>

              {/* second row */}
              <Grid container spacing={1} direction="row" style={{ marginBottom: '10px' }}>
                <Grid item xs={12}>
                  <Item style={{ height: '320px', backgroundColor: "#fff", marginTop: "10px", color: "black" }}>
                    <Stack direction="row">
                      <Typography sx={{ fontSize: "20px" }}>
                        <b>Shift schedule</b>
                      </Typography>
                      <Button variant="contained" sx={{ backgroundColor: '#6420AA', '&:hover': { backgroundColor: 'blue' }, textAlign: 'center', marginLeft: '200px' }}>Employee Shift</Button>
                    </Stack>
                    <br></br>
                    <div>
                      <DataWithoutHeadingTable data={data} />
                    </div>
                  </Item>
                </Grid>
              </Grid>

              {/* third row */}
              <Grid container spacing={1} direction="row" style={{ marginBottom: '10px' }}>
                <Grid item xs={12}>
                  <Item style={{ backgroundColor: "#fff", color: "black", marginTop: "10px" ,height:"auto"}}>
                    <Typography gutterBottom variant="subtitle1" style={{ textAlign: "left", fontSize: "20px", color: "#161e29" }}>
                      Birthdays
                    </Typography>
                    {/* <Typography variant="body2" gutterBottom style={{ textAlign: "center", color: "#4d4f5c" }}>
                      <MilitaryTechIcon />
                    </Typography>
                    <Typography variant="body2" style={{ textAlign: "center", fontSize: "16px", color: "#4d4f5c" }}>
                      <Placeholder>-Not Enough Data-</Placeholder>
                    </Typography>
                    <div style={{ marginBottom: "200px" }}></div> */}
                    <List sx={{ maxwidth: '600px',  bgcolor: '#fff' }}>
                      <ListItem>
                        <ListItemAvatar>
                          <Avatar>
                            <AccountCircleIcon />
                          </Avatar>
                        </ListItemAvatar>
                        <ListItemText>Andrew</ListItemText>
                        <ListItemText><CakeIcon/>17 Apr</ListItemText>
                        <ListItemText>11 months after</ListItemText>
                      </ListItem>
                      <Divider component="li" style={{backgroundColor:"grey"}}/>
                      <ListItem>
                        <ListItemAvatar>
                          <Avatar>
                            <AccountCircleIcon />
                          </Avatar>
                        </ListItemAvatar>
                        <ListItemText>Albert</ListItemText>
                        <ListItemText><CakeIcon/>1 may</ListItemText>
                        <ListItemText>11 months after</ListItemText>
                      </ListItem>
                      <Divider component="li" style={{backgroundColor:"grey"}}/>
                      <ListItem>
                        <ListItemAvatar>
                          <Avatar>
                            <AccountCircleIcon />
                          </Avatar>
                        </ListItemAvatar>
                        <ListItemText>Bohr</ListItemText>
                        <ListItemText><CakeIcon/>24 Aug</ListItemText>
                        <ListItemText>3 months after</ListItemText>
                      </ListItem>
                      <Divider component="li" style={{backgroundColor:"grey"}}/>
                      <ListItem>
                        <ListItemAvatar>
                          <Avatar>
                            <AccountCircleIcon />
                          </Avatar>
                        </ListItemAvatar>
                        <ListItemText>David</ListItemText>
                        <ListItemText><CakeIcon/>8 Jan</ListItemText>
                        <ListItemText>10 months after</ListItemText>
                      </ListItem>
                      <Divider component="li" style={{backgroundColor:"grey"}}/>
                      <ListItem>
                        <ListItemAvatar>
                          <Avatar>
                            <AccountCircleIcon />
                          </Avatar>
                        </ListItemAvatar>
                        <ListItemText>John</ListItemText>
                        <ListItemText><CakeIcon/>21 Feb</ListItemText>
                        <ListItemText>9 months after</ListItemText>
                      </ListItem>
                      <Divider component="li" style={{backgroundColor:"grey"}}/>
                    </List>

                  </Item>
                </Grid>
              </Grid>

              {/* fourth row */}
              <Grid container spacing={1} direction="row" style={{ marginBottom: '10px' }}>
                <Grid item xs={12}>
                  <Item style={{ height: '320px', backgroundColor: "#fff", color: "black", marginTop: "10px" }}>Employee Appreciation</Item>
                </Grid>
              </Grid>
              {/* fifth row */}
              <Grid container spacing={1} direction="row" style={{ marginBottom: '10px' }}>
                <Grid item xs={12}>
                  <Item style={{ backgroundColor: "#fff", color: "black", marginTop: "10px" }}>
                    <Typography gutterBottom variant="subtitle1" style={{ textAlign: "left", fontSize: "20px", color: "#161e29" }}>
                      On Leave Today
                    </Typography>
                    <Typography variant="body2" gutterBottom style={{ textAlign: "center", color: "#4d4f5c" }}>
                      <FlightTakeoffIcon />
                    </Typography>
                    <Typography variant="body2" style={{ textAlign: "center", fontSize: "16px", color: "#4d4f5c" }}>
                      <Placeholder>-Not Enough Data-</Placeholder>
                    </Typography>
                    <div style={{ marginBottom: "50px" }}></div>
                  </Item>
                </Grid>
              </Grid>

              {/* sixth row */}
              <Grid container spacing={1} direction="row" style={{ marginBottom: '10px' }}>
                <Grid item xs={12}>
                  <Item style={{ backgroundColor: "#fff", color: "black", marginTop: "10px" }}>
                    <Typography gutterBottom variant="subtitle1" style={{ textAlign: "left", fontSize: "20px", color: "#161e29" }}>
                      On Work From Home Today
                    </Typography>
                    <Typography variant="body2" gutterBottom style={{ textAlign: "center", color: "#4d4f5c" }}>
                      <HouseIcon />
                    </Typography>
                    <Typography variant="body2" style={{ textAlign: "center", fontSize: "16px", color: "#4d4f5c" }}>
                      <Placeholder>-Not Enough Data-</Placeholder>
                    </Typography>
                    <div style={{ marginBottom: "50px" }}></div>
                  </Item>
                </Grid>
              </Grid>


              {/* seventh row */}
              <Grid container spacing={1} direction="row" style={{ marginBottom: '10px' }}>
                <Grid item xs={12}>
                  <Item style={{ backgroundColor: "#fff", color: "black", marginTop: "10px" }}>
                    <Typography gutterBottom variant="subtitle1" style={{ textAlign: "left", fontSize: "20px", color: "#161e29" }}>
                      Today's Joinings & Work Anniversary
                    </Typography>
                    <Typography variant="body2" gutterBottom style={{ textAlign: "center", color: "#4d4f5c" }}>
                      <CampaignIcon />
                    </Typography>
                    <Typography variant="body2" style={{ textAlign: "center", fontSize: "16px", color: "#4d4f5c" }}>
                      <Placeholder>-Not Enough Data-</Placeholder>
                    </Typography>
                    <div style={{ marginBottom: "50px" }}></div>
                  </Item>
                </Grid>
              </Grid>

              {/* eighth row */}
              <Grid container spacing={1} direction="row" style={{ marginBottom: '10px' }}>
                <Grid item xs={12}>
                  <Item style={{ backgroundColor: "#fff", color: "black", marginTop: "10px" }}>
                    <Typography gutterBottom variant="subtitle1" style={{ textAlign: "left", fontSize: "20px", color: "#161e29" }}>
                      Notice Period Duration
                    </Typography>
                    <Typography variant="body2" gutterBottom style={{ textAlign: "center", color: "#4d4f5c" }}>
                      <ViewListIcon />
                    </Typography>
                    <Typography variant="body2" style={{ textAlign: "center", fontSize: "16px", color: "#4d4f5c" }}>
                      <Placeholder>-Not Enough Data-</Placeholder>
                    </Typography>
                    <div style={{ marginBottom: "50px" }}></div>
                  </Item>
                </Grid>
              </Grid>

              {/* nine row */}
              <Grid container spacing={1} direction="row" style={{ marginBottom: '10px' }}>
                <Grid item xs={12}>
                  <Item style={{ backgroundColor: "#fff", color: "black", marginTop: "10px" }}>
                    <Typography gutterBottom variant="subtitle1" style={{ textAlign: "left", fontSize: "20px", color: "#161e29" }}>
                      Probation Date
                    </Typography>
                    <Typography variant="body2" gutterBottom style={{ textAlign: "center", color: "#4d4f5c" }}>
                      <ViewListIcon />
                    </Typography>
                    <Typography variant="body2" style={{ textAlign: "center", fontSize: "16px", color: "#4d4f5c" }}>
                      <Placeholder>-Not Enough Data-</Placeholder>
                    </Typography>
                    <div style={{ marginBottom: "50px" }}></div>
                  </Item>
                </Grid>
              </Grid>


              {/* ten row */}
              <Grid container spacing={1} direction="row" style={{ marginBottom: '10px' }}>
                <Grid item xs={12}>
                  <Item style={{ backgroundColor: "#fff", color: "black" }}>
                    <Typography gutterBottom variant="subtitle1" style={{ textAlign: "left", fontSize: "20px", color: "#161e29" }}>
                      Internship Date
                    </Typography>
                    <Typography variant="body2" gutterBottom style={{ textAlign: "center", color: "#4d4f5c" }}>
                      <ViewListIcon />
                    </Typography>
                    <Typography variant="body2" style={{ textAlign: "center", fontSize: "16px", color: "#4d4f5c" }}>
                      <Placeholder>-Not Enough Data-</Placeholder>
                    </Typography>
                    <div style={{ marginBottom: "50px" }}></div>
                  </Item>
                </Grid>
              </Grid>


              {/* eleven row */}
              <Grid container spacing={1} direction="row" style={{ marginBottom: '10px' }}>
                <Grid item xs={12}>
                  <Item style={{ backgroundColor: "#fff", color: "black" }}>
                    <Typography gutterBottom variant="subtitle1" style={{ textAlign: "left", fontSize: "20px", color: "#161e29" }}>
                      Contract Date
                    </Typography>
                    <Typography variant="body2" gutterBottom style={{ textAlign: "center", color: "#4d4f5c" }}>
                      <ViewListIcon />
                    </Typography>
                    <Typography variant="body2" style={{ textAlign: "center", fontSize: "16px", color: "#4d4f5c" }}>
                      <Placeholder>-Not Enough Data-</Placeholder>
                    </Typography>
                    <div style={{ marginBottom: "50px" }}></div>
                  </Item>
                </Grid>
              </Grid>

            </Grid>

            {/* second column  */}
            <Grid item xs={12} md={6}>

              {/* first row  */}
              <Grid container spacing={1} direction="row" style={{ marginBottom: '10px' }}>
                <Grid item xs={6}>
                  <Item elevation={2} style={{ backgroundColor: "#fff", height: "150px" }}>
                    <Typography gutterBottom variant="subtitle1" style={{ textAlign: "left", fontSize: "20px", color: "#4d4f5c" }}>
                      Tasks
                    </Typography>
                    <Typography variant="body2" gutterBottom style={{ textAlign: "right", color: "#4d4f5c" }}>
                      <GroupIcon></GroupIcon>
                    </Typography>
                    <Stack direction="row" width="100%" textAlign="center" spacing={2}>
                      <Typography component="div" variant="body2" style={{ textAlign: "left", fontSize: "15px" }} color="red">
                        25<br></br><p style={{ color: "#4d4f5c" }}>Pending</p>
                      </Typography>
                      <Typography component="div" variant="body2" style={{ textAlign: "left", fontSize: "15px" }} color="blue">
                        5<br></br><p style={{ color: "#4d4f5c" }}>Overdue</p>
                      </Typography>
                    </Stack>
                  </Item>
                </Grid>
                <Grid item xs={6}>
                  <Item elevation={2} style={{ backgroundColor: "#fff", height: "150px" }}>
                    <Typography gutterBottom variant="subtitle1" style={{ textAlign: "left", fontSize: "20px", color: "#4d4f5c" }}>
                      Projects
                    </Typography>
                    <Typography variant="body2" gutterBottom style={{ textAlign: "right", color: "#4d4f5c" }}>
                      <GroupIcon></GroupIcon>
                    </Typography>
                    <Stack direction="row" width="100%" textAlign="center" spacing={2}>
                      <Typography component="div" variant="body2" style={{ textAlign: "left", fontSize: "15px" }} color="red">
                        25<br></br><p style={{ color: "#4d4f5c" }}>In Progress</p>
                      </Typography>
                      <Typography component="div" variant="body2" style={{ textAlign: "left", fontSize: "15px" }} color="blue">
                        5<br></br><p style={{ color: "#4d4f5c" }}>Overdue</p>
                      </Typography>
                    </Stack>
                  </Item>
                </Grid>
              </Grid>

              {/* second row  */}
              <Grid container spacing={1} direction="row" style={{ marginBottom: '10px' }}>
                <Grid item xs={12}>
                  <Item style={{ height: '130px', backgroundColor: "#fff", color: "black" }}>
                    <Stack direction="row">
                      <Typography sx={{fontSize:"18px"}}>Weekly Timelogs</Typography>
                      <Typography component="div" sx={{fontSize:"11px",marginLeft:"8px"}}><p style={{backgroundColor:"grey"}}>50s this week</p></Typography>
                    </Stack>
                    <br></br>
                    <Stack direction="row">
                      <Tooltip title="09-04-2024" arrow>
                        <Button sx={ { borderRadius:"100%" } }>Mo</Button>
                      </Tooltip>
                      <Tooltip title="1o-04-2024" arrow>
                        <Button sx={ { borderRadius:"100%" } }>Tu</Button>
                      </Tooltip>
                      <Tooltip title="11-04-2024" arrow>
                        <Button sx={ { borderRadius:"100%" } }>We</Button>
                      </Tooltip>
                      <Tooltip title="12-04-2024" arrow>
                        <Button sx={ { borderRadius:"100%" } }>Th</Button>
                      </Tooltip>
                      <Tooltip title="13-04-2024" arrow>
                        <Button sx={ { borderRadius:"100%" } }>Fr</Button>
                      </Tooltip>
                      <Tooltip title="14-04-2024" arrow>
                        <Button sx={ { borderRadius:"100%" } }>Sa</Button>
                      </Tooltip>
                      <Tooltip title="15-04-2024" arrow>
                        <Button sx={{backgroundColor:"#6420AA",borderRadius:"100%"}}>Su</Button>
                      </Tooltip>
                    </Stack>
                    <br></br>
                    <Tooltip title="50s" arrow>
                    <BorderLinearProgress variant="determinate" value={50} style={{color:"grey"}} />
                    </Tooltip>
                    <Stack direction="row" component="div">
                      <div>
                      <p>Duration: 50s</p>
                      <p style={{marginLeft:"460px"}}>Breaks: 0s</p>
                      
                      
                      <p>Duration: 50s
                      Breaks: 0s</p>
                      </div>
                      
                    </Stack>
                  </Item>
                </Grid>
              </Grid>

              {/* third row  */}
              <Grid container spacing={1} direction="row" style={{ marginBottom: '10px' }}>
                <Grid item xs={12}>
                  <Item style={{ backgroundColor: "#fff", color: "black" }}>
                    <Typography gutterBottom variant="subtitle1" style={{ textAlign: "left", fontSize: "20px", color: "#161e29" }}>
                      My Tasks <HelpIcon />
                    </Typography>
                    <ThemeProvider theme={getTheme()}>
                      <MUIDataTable
                        fullScreen={true}
                        columns={Taskcolumns}
                        options={options} />
                    </ThemeProvider>
                  </Item>
                </Grid>
              </Grid>

              {/* fourth row  */}
              <Grid container spacing={1} direction="row" style={{ marginBottom: '10px' }}>
                <Grid item xs={12}>
                  <Item style={{ backgroundColor: "#fff", color: "black" }}>
                    <Typography gutterBottom variant="subtitle1" style={{ textAlign: "left", fontSize: "20px", color: "#161e29" }}>
                      Tickets <HelpIcon />
                    </Typography>
                    <ThemeProvider theme={getTheme()}>
                      <MUIDataTable
                        fullScreen={true}
                        columns={Ticketcolumns}
                        options={options} />
                    </ThemeProvider>
                  </Item>
                </Grid>
              </Grid>

              {/* fifth row  */}
              <Grid container spacing={1} direction="row" style={{ marginBottom: '10px' }}>
                <Grid item xs={12}>
                  <Item style={{ backgroundColor: "#fff", color: "black" }}>
                    <Typography variant="h5">My Calender</Typography>
                    <Events></Events>
                  </Item>
                </Grid>
              </Grid>

              {/* sixth row  */}
              <Grid container spacing={1} direction="row" style={{ marginBottom: '10px' }}>
                <Grid item xs={12}>
                  <Item style={{ height: '120px', backgroundColor: "#fff", color: "black" }}>Notices</Item>
                </Grid>
              </Grid>

            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Box>
  )
}

export default PrivateDash;
