import { Box , Grid, TextField, createTheme, ThemeProvider} from '@mui/material';
import React ,{ useState} from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import ListItemText from '@mui/material/ListItemText';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import MUIDataTable from "mui-datatables";
import DeleteIcon from '@mui/icons-material/Delete';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

function VehicleModel() {
  const user = JSON.parse(localStorage.getItem('user-info'));
  const [open, setOpen] = React.useState(false);
  const [creator, setCreator] = useState(user.id); 
  const[brandname,setBrandname] = useState([]);
  // const [data,SetData] = useState([]);

 

  const [update, setUpdate] = React.useState(false);

 
  const handleUpdateClose = () => {
    setUpdate(false);
  };



  const handleClickOpen = () => {
    setOpen(true);
  };  
  
  const handleClose = () => {
    setOpen(false);
  };

  //  Image Selections
  const [selectedFile, setSelectedFile] = useState(null);
  const [imageUrl, setImageUrl] = useState(null);

  const handleFileChange = (event) => {
    const file = event.target.files[0];

    // Check if a file is selected and it's an image
    if (file && file.type.startsWith('image/')) {
      setSelectedFile(file);

      // Read and display the selected image
      const reader = new FileReader();
      reader.onload = () => {
        setImageUrl(reader.result);
      };
      reader.readAsDataURL(file); // Read file as a data URL
    } else {
      setSelectedFile(null);
      setImageUrl(null);
      alert('Please select an image file.');
    }
  };

  const handleUpload = () => {
    // Logic to handle image upload
    if (selectedFile) {
      console.log('Selected File:', selectedFile);
      // Implement your image upload logic here
      // For example, you can use FormData to send the image file to a server
    } else {
      console.log('No file selected');
    }
  };

 




  async function SaveDate() {

    const formData = new FormData();
    const last_update = new Date().getTime();

    
    formData.append('brand', brandname);
    formData.append('logo', selectedFile);
    formData.append('createby',creator);
    formData.append('last_update', last_update.toString());

    try {
      let response = await fetch(``, {
        method: "POST",
        body: formData
      });
  
      if (!response.ok) {
        throw new Error('Failed to update data');
      }
  
      console.log(response);
      alert("Updated");
  
      setOpen(false);
      // getData(); // Assuming these functions are defined elsewhere in your code
    } catch (error) {
      console.error(error);
      alert("Failed to update data");
    }

   

  };


  const columns = [
    {
        name: 'engine',
        label: 'Engine'
    },
    {
      name: 'model',
      label: 'Model'
  },
  {
    name: 'wheels',
    label: 'Wheels'
},
{
  name: 'fuel',
  label: 'Fuel'
},
    {
      name: 'id', // Assuming this is the name of the column containing the ID
      label: 'Actions',
      options: {
          customBodyRender: (value) => (
              <>
          
                    <Button variant="contained" onClick={() => handleEdit(value)} sx={{ backgroundColor: 'blue', '&:hover': { backgroundColor: 'orange' }, textAlign: 'center', marginLeft: '10px' }}>
                                <DeleteIcon /> Edit 
                    </Button>
                
              </>
          )
      }
      
    }
];

async function handleEdit(id){
  

};



const options = {
  //selectableRows: false,
  elevation: 0,
  rowsPerPage: 5,
  rowsPerPageOptions: [5, 10, 25, 100],
  tableBody: {
      style: {
          maxHeight: '500px',
          overflowY: 'scroll',
          marginLeft:'5PX'
      }
  },
  tableHead: {
      style: {
          backgroundColor: '#f2f2f2',
          color: 'black'
      }
  },
  rows: {
      style: {
          marginLeft: '5px',
          textAlign: 'center'
      },
      selectableRowsOnClick: true
  }
};

 const getTheme = () =>
 createTheme({
   palette: {
     mode: 'light',
     primary: {
       main: '#2196f3',
     },
     secondary: {
       main: '#03a9f4',
     },
   },
   typography: {
     fontFamily: [
       'Roboto',
       'Helvetica',
       'Arial',
       'Sans-serif',
     ].join(','),
   },
   components: {
     MuiButton: {
       styleOverrides: {
         root: {
           textTransform: 'none',
         },
       },
     },
   },
   tableBody: {
    style: {
        maxHeight: '500px',
        overflowY: 'scroll'
    }
},
tableHead: {
    style: {
        backgroundColor: '#f2f2f2',
        color: 'black'
    }
},
rows: {
    style: {
        '&:hover': {
            backgroundColor: '#f2f2f2'
        }
    }
}
 });



  return (
    <>

<Box>
  <Box>
  <Dialog
     fullScreen
     open={update}
     onClose={handleUpdateClose}
     TransitionComponent={Transition}
     PaperProps={{
      style: {
        backgroundColor: 'white',
      },
    }}
   >
     <AppBar sx={{ position: 'relative' }}>
       <Toolbar>
         <IconButton
           edge="start"
           color="inherit"
           onClick={handleUpdateClose}
           aria-label="close"
         >
           <CloseIcon />
         </IconButton>
         <Typography sx={{ ml: 2, flex: 1 }} component="div">
          Update Vehicle information
         </Typography>
         
       </Toolbar>
     </AppBar>

      <Box>
        
      </Box>

   </Dialog>
  </Box>
</Box>





<List style={{ background: 'none', padding: 5 }}>

<ListItemText style={{ background: 'none', margin: 5 }}>
<React.Fragment>
<Button variant="contained" onClick={handleClickOpen} sx={{ backgroundColor: 'blue', '&:hover': { backgroundColor: 'orange' }, textAlign: 'center', marginLeft: '10px' }}>
                  <AddCircleIcon/> Add Vehicle Information
              </Button>

              <Dialog
                fullScreen
                open={open}
                onClose={handleClose}
                TransitionComponent={Transition}
                PaperProps={{
                  style: {
                    backgroundColor: 'white',
                  },
                }}
              >
  <AppBar sx={{ position: 'relative' }}>
                  <Toolbar>
                    <IconButton
                      edge="start"
                      color="inherit"
                      onClick={handleClose}
                      aria-label="close"
                    >
                      <CloseIcon />
                    </IconButton>
                    <Typography sx={{ ml: 2, flex: 1 }} component="div">
                      Add Vehicle Information
                    </Typography>
                  </Toolbar>
                </AppBar>

                <Box>
                <Grid container spacing={2} style={{ color: 'black', margin:"10px" }}>


            
                <Grid item xs={8} sm={4}>
                <Typography variant="p" gutterBottom style={{ color: 'black' }}>
                Brand Name
                </Typography>
                <Grid container spacing={2} alignItems="center">
                <Grid item xs={12}>
                
                <TextField
                      label="enter Logo Name"
                      variant="outlined"
                      fullWidth
                      placeholder="eg. TATA"
                      type="text"
                      inputProps={{ style: { color: 'black' } }}
                      onChange={(e)=>setBrandname(e.target.value)}
                      InputLabelProps={{ style: { color: 'black' } }}
                      sx={{
                        '& .MuiOutlinedInput-root': { 
                          borderColor: 'black',
                          '&:hover fieldset': {
                            borderColor: 'black',
                          },
                          '&.Mui-focused fieldset': {
                            borderColor: 'black',
                          },
                        },
                        '& .MuiOutlinedInput-input': {
                          '&::placeholder': { color: 'gray' }
                        }
                      }}
                    />
                
                </Grid>

                </Grid>

                </Grid>


                <Grid item xs={12} sm={6}>
                <Typography variant="p" gutterBottom style={{ color: 'black' }}>
                Logo
                </Typography>
                <Grid container spacing={2} alignItems="center">
                <Grid item xs={12}>
                <TextField
                      fullWidth
                      variant="outlined"
                      label="Upload Image"
                      InputLabelProps={{ shrink: true }}
                      InputProps={{
                        type: 'file',
                        accept: 'image/*',
                        onChange: handleFileChange,
                      }}
                    />
                    {imageUrl && (
                      <div style={{ marginTop: '10px' }}>
                        <img src={imageUrl} alt="Selected" style={{ maxWidth: '100%', maxHeight: '200px' }} />
                      </div>
                    )}
                 
                </Grid>

                </Grid>

                </Grid>

                </Grid>

                <Button variant="contained" autoFocus color="inherit" onClick={SaveDate} sx={{ backgroundColor: 'blue', '&:hover': { backgroundColor: 'orange' }, textAlign: 'center', margin: '25px' }}>
                Save
                </Button>
                </Box>


              </Dialog>
</React.Fragment>
</ListItemText>

<Divider />
          <ListItemText style={{ background: 'white', margin: 0 }}> 
          <ThemeProvider theme={getTheme()}>
            
           

                      <MUIDataTable
                fullScreen={true}
                title={"Vehicle Model Information"}
                // data={}
                columns={columns}
                options={options}
            />


          </ThemeProvider>
          
          </ListItemText>
</List>
    
      
    </>
  )
}

export default VehicleModel;
