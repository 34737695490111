import { Box, Typography, ThemeProvider, createTheme } from '@mui/material';
import React from 'react';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import Stack from '@mui/material/Stack';
import MilitaryTechIcon from '@mui/icons-material/MilitaryTech';
import { Placeholder } from 'react-bootstrap';
import HelpIcon from '@mui/icons-material/Help';
import MUIDataTable from "mui-datatables";
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import FmdGoodIcon from '@mui/icons-material/FmdGood';
import PieChartOutlineIcon from '@mui/icons-material/PieChartOutline';

function ProfileView() {
    const user = JSON.parse(localStorage.getItem('user-info'));
    const Item = styled(Paper)(({ theme }) => ({
        backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
        ...theme.typography.body2,
        padding: theme.spacing(1),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    }));

    const DataWithoutHeadingTable = ({ data }) => {
        return (
            <table>
                <tbody>
                    {data.map((row, rowIndex) => (
                        <tr key={rowIndex}>
                            {row.map((cell, cellIndex) => (
                                <td
                                    key={cellIndex}
                                    style={{
                                        backgroundColor: cellIndex === 2 ? '#16FF00' : 'transparent',
                                        // borderBottom: cellIndex === 2 ? '1px solid #fff' : '1px solid #ddd',
                                        borderRadius: cellIndex === 2 && rowIndex > -1 ? '25px' : '0', // Border radius for cells under the third column
                                        // height: cellIndex === 2 && rowIndex > -1 ? '5px' : 'auto',
                                        padding: "10px", // Add padding to create space between columns
                                        //border: "1px solid #ddd", // Add border to separate cells
                                        textAlign:"left",
                                        color:"grey",
                                        fontSize:"15px",
                                        color: cellIndex === 0 ? "grey" : "black",
                                    }}
                                >
                                    {cell}
                                </td>
                            ))}
                        </tr>
                    ))}
                </tbody>
            </table>
        );
    };

    const data = [
        ['Employee ID', '26'],
        ['Full Name', 'Mike'],
        ['Designation', '--'],
        ['Department', '--'],
        ['Gender', 'Male'],
        ['Work Anniversary', '2 months from now'],
        ['Date of Birth', '--'],
        ['Email', 'cvgh@gmail.com'],
        ['Mobile', '8765432190'],
        ['Slack member ID', '14'],
        ['Hourly Rate', '--'],
        ['Address', '--'],
        ['Skills', '--'],
        ['Language', 'English'],
        ['Probation Date', '--'],
        ['Notice period start Date', '--'],
        ['Notice period end Date', '--'],
        ['Address', '--'],
    ];

    const getTheme = () =>
    createTheme({
      palette: {
        mode: 'light',
        primary: {
          main: '#2196f3',
        },
        secondary: {
          main: '#03a9f4',
        },
      },
      typography: {
        fontFamily: [
          'Roboto',
          'Helvetica',
          'Arial',
          'Sans-serif',
        ].join(','),
      },
      components: {
        MuiButton: {
          styleOverrides: {
            root: {
              textTransform: 'none',
            },
          },
        },
      },
      tableBody: {
        style: {
          maxHeight: '500px',
          overflowY: 'scroll'
        }
      },
      tableHead: {
        style: {
          backgroundColor: '#f2f2f2',
          color: 'black'
        }
      },
      rows: {
        style: {
          '&:hover': {
            backgroundColor: '#f2f2f2'
          }
        }
      }
    });

    const Ticketcolumns = [
        {
          name: "",
          label: "Tickets#"  // to rename column
        },
        {
          name: "",
          label: "Ticket Subject "
        },
        {
          name: "",
          label: "Status"
        },
        {
          name: "",
          label: "Requested On"
        }
    
      ];
    
      const options = {
        //selectableRows: false,
        elevation: 0,
        rowsPerPage: 5,
        rowsPerPageOptions: [5, 10, 25, 100],
        tableBody: {
          style: {
            maxHeight: '500px',
            overflowY: 'scroll',
            marginLeft: '5PX'
          }
        },
        tableHead: {
          style: {
            backgroundColor: '#f2f2f2',
            color: 'black'
          }
        },
        rows: {
          style: {
            marginLeft: '5px',
            textAlign: 'center'
          },
          selectableRowsOnClick: true
        }
      };
        

    return (
        <Box display="flex" flexDirection="column">
            <Box sx={{ flexGrow: 1 }}>


                <Grid item xs={12} md={6}>
                    <Grid container spacing={2}>

                        {/* first column */}
                        <Grid item xs={12} md={6.5} >

                            {/* first row */}
                            <Grid container spacing={1} direction="row">
                                <Grid item xs={12}>
                                    <Item style={{ backgroundColor: "#fff", color: "black" }}>
                                        <Stack direction="row">
                                            <AccountBoxIcon sx={{ color: "grey", fontSize: "150px", justifyContent: "left" }} />
                                            <Stack direction="column">
                                                <Typography component="div" sx={{ marginTop: "20px" }}>
                                                    <Stack direction="row" spacing={40}>
                                                        <h1 style={{ color: "black", fontSize: "20px" }}>{user.name}</h1>
                                                        <MoreHorizIcon style={{textAlign:"right"}}  />
                                                    </Stack>
                                                </Typography>
                                                <Typography component="div" sx={{ marginTop: "20px",textAlign:"left" }}>
                                                    <div>
                                                        <p style={{ color: "grey" }}>Last login at 24-04-2024 12:30 PM</p>
                                                    </div>
                                                </Typography>
                                                <br></br>
                                                <hr></hr>
                                                <br></br>
                                                <Stack  direction="row" spacing={8}>
                                                    <Typography>
                                                        <div>
                                                           <p>Open Task</p>
                                                           <br></br>
                                                           <h1 style={{fontSize:"25px"}}>0</h1>
                                                        </div>
                                                     </Typography>
                                                     <Typography>
                                                        <div>
                                                           <p>Projects</p>
                                                           <br></br>
                                                           <h1 style={{fontSize:"25px"}}>0</h1>
                                                        </div>
                                                     </Typography>
                                                     <Typography>
                                                        <div>
                                                           <p>Hours logged</p>
                                                           <br></br>
                                                           <h1 style={{fontSize:"25px"}}>0</h1>
                                                        </div>
                                                     </Typography>
                                                     <Typography>
                                                        <div>
                                                           <p>Tickets</p>
                                                           <br></br>
                                                           <h1 style={{fontSize:"25px"}}>0</h1>
                                                        </div>
                                                     </Typography>
                                                </Stack>
                                            </Stack>
                                        </Stack>

                                        <hr></hr></Item>
                                </Grid>
                            </Grid>

                            {/* second row */}
                            <Grid container spacing={1} direction="row" style={{ marginTop: '20px' }}>
                                <Grid item xs={12}>
                                    <Item style={{  backgroundColor: "#fff", color: "black" }}>
                                        <Typography sx={{ fontSize: "20px", textAlign: "left" }}>
                                            <b>Profile info</b>
                                        </Typography>
                                        <br></br>
                                        <div>
                                            <DataWithoutHeadingTable data={data}   />
                                        </div>
                                    </Item>
                                </Grid>
                            </Grid>


                        </Grid>


                        {/* second column */}
                        <Grid item xs={12} md={5.3} >

                            {/* first row */}
                            <Grid container spacing={1} direction="row" >
                                <Grid item xs={12}>
                                    <Item style={{ backgroundColor: "#fff", color: "black"}}>
                                        <Typography gutterBottom variant="subtitle1" style={{ textAlign: "left", fontSize: "20px", color: "#161e29" }}>
                                            Appreciation
                                        </Typography>
                                        <br></br>
                                        <br></br>
                                        <Typography variant="body2" gutterBottom style={{ textAlign: "center", color: "#4d4f5c" }}>
                                            <MilitaryTechIcon />
                                        </Typography>
                                        <Typography variant="body2" style={{ textAlign: "center", fontSize: "16px", color: "#4d4f5c" }}>
                                            <Placeholder>-Not Enough Data-</Placeholder>
                                        </Typography>
                                        <div style={{ marginBottom: "50px" }}></div>
                                    </Item>
                                </Grid>
                            </Grid>

                            {/* second row */}
                            <Grid container spacing={1} direction="row" style={{ marginTop: '20px' }}>
                                <Grid item xs={12}>
                                    <Item style={{ backgroundColor: "#fff", color: "black" }}>
                                        <Stack direction="row" spacing={30}>
                                            <Typography>
                                                <div>
                                                    <p>Reported To</p>
                                                    <br></br>
                                                    <h1 style={{fontSize:"25px"}}>--</h1>
                                                </div>
                                            </Typography>
                                            <Typography >
                                                <div>
                                                    <p>Reporting Team</p>
                                                    <br></br>
                                                    <h1 style={{fontSize:"25px"}}>--</h1>
                                                </div>
                                            </Typography>
                                        </Stack>
                                    </Item>
                                </Grid>
                            </Grid>

                            <Grid container spacing={1} direction="row" style={{ marginTop: '20px' }}>
                                <Grid item xs={6}>
                                    <Item style={{ height: '80px', backgroundColor: "#fff" }}>
                                        <Stack direction="row" width="100%" textAlign="center" spacing={2}>
                                            <Typography component="div" variant="body2" style={{ fontSize: "15px" }} color="black">
                                                <p >Late Attendance<QuestionMarkIcon /></p>
                                                <br></br>
                                                <h1 sx={{textAlign:"left"}} >0</h1>
                                            </Typography>
                                            <FmdGoodIcon  sx={{textAlign:"right",color:"grey"}} />
                                        </Stack>
                                    </Item>
                                </Grid>
                                <Grid item xs={6}>
                                    <Item style={{ height: '80px', backgroundColor: "#fff" }}>
                                        <Stack direction="row" width="100%" textAlign="center" spacing={8}>
                                            <Typography component="div" variant="body2" style={{ fontSize: "15px" }} color="black">
                                                <p >Leave Taken<QuestionMarkIcon /></p>
                                                <br></br>
                                                <h1 sx={{textAlign:"left"}} >0</h1>
                                            </Typography>
                                            <ExitToAppIcon  sx={{textAlign:"right",color:"grey"}} />
                                        </Stack>
                                    </Item>
                                </Grid>
                            </Grid>

                            <Grid container spacing={1} direction="row" style={{ marginTop: '20px' }}>
                                <Grid item xs={12}>
                                    <Item style={{ backgroundColor: "#fff", color: "black" }}>
                                        <Typography gutterBottom variant="subtitle1" style={{ textAlign: "left", fontSize: "20px", color: "#161e29" }}>
                                            Tasks
                                        </Typography>
                                        <br></br>
                                        <br></br>
                                        <Typography variant="body2" gutterBottom style={{ textAlign: "center", color: "#4d4f5c" }}>
                                            <PieChartOutlineIcon />
                                        </Typography>
                                        <Typography variant="body2" style={{ textAlign: "center", fontSize: "16px", color: "#4d4f5c" }}>
                                            <Placeholder>-Not Enough Data-</Placeholder>
                                        </Typography>
                                        <div style={{ marginBottom: "50px" }}></div>
                                    </Item>
                                </Grid>
                            </Grid>

                            <Grid container spacing={1} direction="row" style={{ marginTop: '20px' }}>
                                <Grid item xs={12}>
                                    <Item style={{ backgroundColor: "#fff", color: "black" }}>
                                        <Typography gutterBottom variant="subtitle1" style={{ textAlign: "left", fontSize: "20px", color: "#161e29" }}>
                                            Tickets
                                        </Typography>
                                        <br></br>
                                        <br></br>
                                        <Typography variant="body2" gutterBottom style={{ textAlign: "center", color: "#4d4f5c" }}>
                                            <PieChartOutlineIcon />
                                        </Typography>
                                        <Typography variant="body2" style={{ textAlign: "center", fontSize: "16px", color: "#4d4f5c" }}>
                                            <Placeholder>-Not Enough Data-</Placeholder>
                                        </Typography>
                                        <div style={{ marginBottom: "50px" }}></div>
                                    </Item>
                                </Grid>
                            </Grid>


                            {/* <Grid container spacing={1} direction="row" style={{ marginBottom: '10px' }}>
                                <Grid item xs={12}>
                                    <Item style={{ backgroundColor: "#fff", color: "black" }}>
                                        <Typography gutterBottom variant="subtitle1" style={{ textAlign: "left", fontSize: "20px", color: "#161e29" }}>
                                            Tickets <HelpIcon />
                                        </Typography>
                                        <ThemeProvider theme={getTheme()}>
                                            <MUIDataTable
                                                fullScreen={true}
                                                columns={Ticketcolumns}
                                                options={options} />
                                        </ThemeProvider>
                                    </Item>
                                </Grid>
                            </Grid> */}





                        </Grid>


                    </Grid>
                </Grid>
            </Box>
        </Box>
    )
}

export default ProfileView