import * as React from 'react';
import Stack from '@mui/material/Stack';
import AddIcon from '@mui/icons-material/Add';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import { Box, Button, TextField, Grid, Autocomplete,} from '@mui/material';
import DriveFolderUploadIcon from '@mui/icons-material/DriveFolderUpload';


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function AddLeads() {
    const [open, setOpen] = React.useState(false);
    const [name,SetName] = React.useState('');
    const [email,SetEmail] = React.useState('');
    const [phone,SetPhone] = React.useState('');
    const [language,SetLanguage] = React.useState('');
    const [category,Setcategory] = React.useState([]);
    const [agent,Setagent] = React.useState('');
    const [source,Setsource] = React.useState('');
    const [loading, setLoading] = React.useState(true);
    const [selectedOption, setSelectedOption] = React.useState(null);

    const getOptionLabel = (users) => {
        return users; // Assuming the option is already a string (name)
      };


    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };


    React.useEffect(()=>{
        getCategoryData();
    },[]);


    async function getCategoryData(){
    
        await  fetch('https://roadpilot.co.in/server/public/api/GetLeadCategories')
        .then(response => response.json())
        .then(data => {
          const names = data.map(item => item.category).filter(name => category); // Extracting and filtering names from data
          Setcategory(names); // Setting options to an array of names
          setLoading(false);
        })
        .catch(error => {
          console.error('Error fetching data:', error);
          setLoading(false);
        });


    }

    const list = {name,email,phone,language,selectedOption}

    async function savedata(){

        const formData = new FormData();

        formData.append('name', name);
        formData.append('email', email);
        formData.append('phone', phone);
        formData.append('language', language);
        formData.append('category', selectedOption);


        try {
            let response = await fetch(`https://roadpilot.co.in/server/public/api/AddSalesData`, {
            method: "POST",
            body: formData
            });

            if (!response.ok) {
            throw new Error('Failed to update data');
            }

            console.log(response);
            alert("Updated");
            handleClose();


            // Assuming these functions are defined elsewhere in your code
        } catch (error) {
            console.error(error);
            alert("Failed to update data");
        }


    }



    return (
        <div>
            <React.Fragment>
                <Stack direction="row" spacing={2}>
                    <Button variant="contained" onClick={handleClickOpen} sx={{ backgroundColor: 'blue', '&:hover': { backgroundColor: 'orange' }, textAlign: 'center', marginLeft: '10px' }}><AddIcon />Add Lead</Button>
                    <Button variant="contained" onClick={handleClickOpen} sx={{ backgroundColor: 'blue', '&:hover': { backgroundColor: 'orange' }, textAlign: 'center', marginLeft: '10px' }}><AddIcon />Lead Form</Button>
                    <Button variant="contained" onClick={handleClickOpen} sx={{ backgroundColor: 'blue', '&:hover': { backgroundColor: 'orange' }, textAlign: 'center', marginLeft: '10px' }}><DriveFolderUploadIcon/>Import</Button>
                    {/* <Button variant="contained" disabled>
        Disabled
      </Button>
      <Button variant="contained" href="#contained-buttons">
        Link
      </Button> */}
                </Stack>
                <Dialog
                    fullScreen
                    open={open}
                    onClose={handleClose}
                    TransitionComponent={Transition}
                    PaperProps={{
                        style: {
                            backgroundColor: 'white',
                        },
                    }}
                >

                    <AppBar sx={{ position: 'relative' }}>
                        <Toolbar>
                            <IconButton
                                edge="start"
                                color="inherit"
                                onClick={handleClose}
                                aria-label="close"
                            >
                                <CloseIcon />
                            </IconButton>
                            <Typography sx={{ ml: 2, flex: 1 }} component="div">
                                Add New Lead
                            </Typography>

                        </Toolbar>

                    </AppBar>
                    <Box>


                        <Grid container spacing={2} style={{ color: 'black', margin: "10px" }}>
        

                            <Grid item xs={4} sm={3}>
                                <Typography variant="p" gutterBottom style={{ color: 'black' }}>
                                    Name
                                </Typography>
                                <Grid container spacing={2} alignItems="center">
                                    <Grid item xs={12}>
                                        <br></br>
                                        <TextField
                                            
                                            label="Enter name"
                                            variant="outlined"
                                            fullWidth
                                            focused
                                            placeholder="e.g.Name"
                                            onChange={(e) => SetName(e.target.value)}
                                            inputProps={{ style: { color: 'black' } }}
                                            InputLabelProps={{ style: { color: 'black' } }}
                                            sx={{
                                                '& .MuiOutlinedInput-root': {
                                                    
                                                    '&.Mui-focused fieldset': {
                                                        borderColor: 'black',
                                                    },
                                                },
                                                '& .MuiOutlinedInput-input': {
                                                    '&::placeholder': { color: 'gray' }
                                                }
                                            }}
                                        />
                                    </Grid>

                                </Grid>

                            </Grid>
                            <Grid item xs={4} sm={3}>
                                <Typography variant="p" gutterBottom style={{ color: 'black' }}>
                                    Email
                                </Typography>
                                <Grid container spacing={2} alignItems="center">
                                    <Grid item xs={12}>
                                        <br></br>
                                        <TextField
                                            label="enter email"
                                            variant="outlined"
                                            fullWidth
                                            focused
                                            placeholder="eg@example.com"
                                            type="email"
                                            onChange={(e)=>SetEmail(e.target.value)}
                                            inputProps={{ style: { color: 'black' } }}
                                            InputLabelProps={{ style: { color: 'black' } }}
                                            sx={{
                                                '& .MuiOutlinedInput-root': {
                                                    borderColor: 'gray',
                                                    '&:hover fieldset': {
                                                        borderColor: 'gray',
                                                    },
                                                    '&.Mui-focused fieldset': {
                                                        borderColor: 'gray',
                                                    },
                                                },
                                                '& .MuiOutlinedInput-input': {
                                                    '&::placeholder': { color: 'gray' }
                                                }
                                            }}
                                        />
                                    </Grid>

                                </Grid>

                            </Grid>

                        </Grid>

                        <Grid container spacing={2} style={{ color: 'black', margin: "10px" }}>
                        <Grid item xs={8} sm={2}>
                                <Typography variant="p" gutterBottom style={{ color: 'black' }}>
                                    Phone Number
                                </Typography>
                                <Grid container spacing={2} alignItems="center">
                                    <Grid item xs={12}>
                                        <br></br>
                                        <TextField
                                            label="Enter password"
                                            variant="outlined"
                                            fullWidth
                                            focused
                                            onChange={(e)=>SetPhone(e.target.value)}
                                            placeholder="e.g.+91 12345689"
                                            inputProps={{ style: { color: 'black' } }}
                                            InputLabelProps={{ style: { color: 'black' } }}
                                            sx={{
                                                '& .MuiOutlinedInput-root': {
                                                    borderColor: 'gray',
                                                    '&:hover fieldset': {
                                                        borderColor: 'gray',
                                                    },
                                                    '&.Mui-focused fieldset': {
                                                        borderColor: 'gray',
                                                    },
                                                },
                                                '& .MuiOutlinedInput-input': {
                                                    '&::placeholder': { color: 'gray' }
                                                }
                                            }}
                                        />
                                    </Grid>

                                </Grid>

                            </Grid>
                            <Grid item xs={8} sm={2}>
                                <Typography variant="p" gutterBottom style={{ color: 'black' }}>
                                    Languages
                                </Typography>
                                <Grid container spacing={2} alignItems="center">
                                    <Grid item xs={12}>
                                        <br></br>
                                        <TextField
                                            label="Enter Department"
                                            variant="outlined"
                                            fullWidth
                                            placeholder="e.g. HR"
                                            focused
                                            onChange={(e)=>SetLanguage(e.target.value)}
                                            inputProps={{ style: { color: 'black' } }}
                                            InputLabelProps={{ style: { color: 'black' } }}
                                            sx={{
                                                '& .MuiOutlinedInput-root': {
                                                    borderColor: 'gray',
                                                    '&:hover fieldset': {
                                                        borderColor: 'gray',
                                                    },
                                                    '&.Mui-focused fieldset': {
                                                        borderColor: 'gray',
                                                    },
                                                },
                                                '& .MuiOutlinedInput-input': {
                                                    '&::placeholder': { color: 'gray' }
                                                }
                                            }}
                                        />
                                    </Grid>

                                </Grid>

                            </Grid>
                            <Grid item xs={8} sm={2}>
                                <Typography variant="p" gutterBottom style={{ color: 'black' }}>
                                   Add Category
                                </Typography>
                                <Grid container spacing={2} alignItems="center">
                                <Grid item xs={12}>
                                <br></br>
                                <Autocomplete
                                    options={category}
                                    loading={loading}
                                    getOptionLabel={getOptionLabel}
                                    onChange={(event, value) => setSelectedOption(value)}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Select Name"
                                            variant="outlined"
                                            focused
                                            InputProps={{
                                                ...params.InputProps,
                                                type: 'search',
                                                style: { color: 'black' }
                                            }}
                                            InputLabelProps={{ style: { color: 'black' } }}
                                            sx={{
                                                width: 300,
                                                '&.Mui-focused fieldset': {
                                                    borderColor: 'gray',
                                                }
                                            }}
                                        />
                                    )}
                                />
                              </Grid>
                              </Grid>
                            </Grid>

                        </Grid>


                        <Grid container spacing={2} style={{ color: 'black', margin: "10px" }}>
                        <Grid item xs={8} sm={3}>
                                <Typography variant="p" gutterBottom style={{ color: 'black' }}>
                                    Mobile No
                                </Typography>
                                <Grid container spacing={2} alignItems="center">
                                    <Grid item xs={12}>
                                        <br></br>
                                        <TextField
                                            label="enter Mobile No"
                                            variant="outlined"
                                            fullWidth
                                            focused
                                            placeholder="e.g.+91 12345689"
                                            inputProps={{ style: { color: 'black' } }}
                                            InputLabelProps={{ style: { color: 'black' } }}
                                            sx={{
                                                '& .MuiOutlinedInput-root': {
                                                    borderColor: 'gray',
                                                    '&:hover fieldset': {
                                                        borderColor: 'gray',
                                                    },
                                                    '&.Mui-focused fieldset': {
                                                        borderColor: 'gray',
                                                    },
                                                },
                                                '& .MuiOutlinedInput-input': {
                                                    '&::placeholder': { color: 'gray' }
                                                }
                                            }}
                                        />
                                    </Grid>

                                </Grid>

                            </Grid>
                            <Grid item xs={8} sm={2.5}>
                                <Typography variant="p" gutterBottom style={{ color: 'black' }}>
                                    Country
                                </Typography>
                                <Grid container spacing={2} alignItems="center">
                                    <Grid item xs={12}>
                                        <br></br>
                                        <TextField
                                            label="enter Mobile No"
                                            variant="outlined"
                                            fullWidth
                                            focused
                                            placeholder="e.g.India"
                                            inputProps={{ style: { color: 'black' } }}
                                            InputLabelProps={{ style: { color: 'black' } }}
                                            sx={{
                                                '& .MuiOutlinedInput-root': {
                                                    borderColor: 'gray',
                                                    '&:hover fieldset': {
                                                        borderColor: 'gray',
                                                    },
                                                    '&.Mui-focused fieldset': {
                                                        borderColor: 'gray',
                                                    },
                                                },
                                                '& .MuiOutlinedInput-input': {
                                                    '&::placeholder': { color: 'gray' }
                                                }
                                            }}
                                        />
                                    </Grid>

                                </Grid>

                            </Grid>
                            <Grid item xs={8} sm={2.5}>
                                <Typography variant="p" gutterBottom style={{ color: 'black' }}>
                                    Language
                                </Typography>
                                <Grid container spacing={2} alignItems="center">
                                    <Grid item xs={12}>
                                        <br></br>
                                        <TextField
                                            label="enter Mobile No"
                                            variant="outlined"
                                            fullWidth
                                            focused
                                            placeholder="e.g.+91 12345689"
                                            inputProps={{ style: { color: 'black' } }}
                                            InputLabelProps={{ style: { color: 'black' } }}
                                            sx={{
                                                '& .MuiOutlinedInput-root': {
                                                    borderColor: 'gray',
                                                    '&:hover fieldset': {
                                                        borderColor: 'gray',
                                                    },
                                                    '&.Mui-focused fieldset': {
                                                        borderColor: 'gray',
                                                    },
                                                },
                                                '& .MuiOutlinedInput-input': {
                                                    '&::placeholder': { color: 'gray' }
                                                }
                                            }}
                                        />
                                    </Grid>

                                </Grid>

                            </Grid>
                            <Grid item xs={8} sm={3}>
                                <Typography variant="p" gutterBottom style={{ color: 'black' }}>
                                    User Role
                                </Typography>
                                <Grid container spacing={2} alignItems="center">
                                    <Grid item xs={12}>
                                        <br></br>
                                        <TextField
                                            label="enter Mobile No"
                                            variant="outlined"
                                            fullWidth
                                            focused
                                            placeholder="e.g.+91 12345689"
                                            inputProps={{ style: { color: 'black' } }}
                                            InputLabelProps={{ style: { color: 'black' } }}
                                            sx={{
                                                '& .MuiOutlinedInput-root': {
                                                    borderColor: 'gray',
                                                    '&:hover fieldset': {
                                                        borderColor: 'gray',
                                                    },
                                                    '&.Mui-focused fieldset': {
                                                        borderColor: 'gray',
                                                    },
                                                },
                                                '& .MuiOutlinedInput-input': {
                                                    '&::placeholder': { color: 'gray' }
                                                }
                                            }}
                                        />
                                    </Grid>

                                </Grid>

                            </Grid>

                            <Grid item xs={10} sm={10}>
                                <Typography variant="p" gutterBottom style={{ color: 'black' }}>
                                    Address
                                </Typography>
                                <Grid container spacing={2} alignItems="center">
                                    <Grid item xs={12}>
                                        <br></br>
                                        <TextField
                                            label="Enter Address"
                                            variant="outlined"
                                            fullWidth
                                            focused
                                            placeholder="e.g.No:2468,Street, Nagar, City"
                                            inputProps={{ style: { color: 'black' } }}
                                            InputLabelProps={{ style: { color: 'black' } }}
                                            sx={{
                                                '& .MuiOutlinedInput-root': {
                                                    borderColor: 'gray',
                                                    '&:hover fieldset': {
                                                        borderColor: 'gray',
                                                    },
                                                    '&.Mui-focused fieldset': {
                                                        borderColor: 'gray',
                                                    },
                                                },
                                                '& .MuiOutlinedInput-input': {
                                                    '&::placeholder': { color: 'gray' }
                                                }
                                            }}
                                        />
                                    </Grid>

                                </Grid>

                            </Grid>
                            <Grid item xs={10} sm={10}>
                                <Typography variant="p" gutterBottom style={{ color: 'black' }}>
                                    About
                                </Typography>
                                <Grid container spacing={2} alignItems="center">
                                    <Grid item xs={12}>
                                        <br></br>
                                        <TextField
                                            label="About"
                                            variant="outlined"
                                            fullWidth
                                            focused
                                            placeholder="Type here..."
                                            inputProps={{ style: { color: 'black' } }}
                                            InputLabelProps={{ style: { color: 'black' } }}
                                            sx={{
                                                '& .MuiOutlinedInput-root': {
                                                    borderColor: 'gray',
                                                    '&:hover fieldset': {
                                                        borderColor: 'gray',
                                                    },
                                                    '&.Mui-focused fieldset': {
                                                        borderColor: 'gray',
                                                    },
                                                },
                                                '& .MuiOutlinedInput-input': {
                                                    '&::placeholder': { color: 'gray' }
                                                }
                                            }}
                                        />
                                    </Grid>

                                </Grid>

                            </Grid>


                        </Grid>
                        
                       

                        <Button variant="contained" autoFocus color="inherit" onClick={savedata} sx={{ backgroundColor: 'blue', '&:hover': { backgroundColor: 'orange' }, textAlign: 'center', margin: '25px' }}>
                            Save
                        </Button>

                    </Box>
                </Dialog>

            </React.Fragment>

        </div>


    );
}
