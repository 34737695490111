import * as React from 'react';
import { useState } from 'react';
import Stack from '@mui/material/Stack';
import AddIcon from '@mui/icons-material/Add';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import { Box, Button, Grid } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function AddLeaves() {
    const [open, setOpen] = React.useState(false);

    const [member, setMember] = React.useState('');
    const [leaveType, setLeaveType] = React.useState('');
    const [status, setStatus] = React.useState('');
    const [duration, setDuration] = React.useState('');
    const [date, setDate] = useState(null); // Initialize state for the date
    const [memberError, setMemberError] = React.useState(false);
    const [reason, setReason] = useState('');
    const [fileName, setFileName] = useState('');

    const Andrew = 'Andrew';
    const Bohr = 'Bohr';
    const Ceaser = 'Ceaser';
    const Casual = 'Casual';
    const Sick = 'Sick';
    const Earned = 'Earned';
    const Approved = 'Approved';
    const Pending = 'Pending';


    // const handleFileUpload = (event) => {
    //     const file = event.target.files[0];
    //     if (file) {
    //         setFileName(file.name);
    //         // You can also upload the file to a server if needed
    //     }
    // };

    // const handleMember = (event) => {
    //     setMember(event.target.value);
    // };
    // const handleLeaveType = (event) => {
    //     setLeaveType(event.target.value);
    // };
    // const handleStatus = (event) => {
    //     setStatus(event.target.value);
    // };

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        setFileName(file.name); // Update file name
        console.log(file.name); // Print file name to console
    };



    // Event handler to update the date state and log the date to the console
    const handleDateChange = (newDate) => {
        setDate(newDate); // Update the date state
        console.log(newDate); // Log the new date to the console
    };


    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
    const handleSave = () => {
        const list = { member, leaveType, status, duration, date }
        console.log(list)
        if (member === '') {
            setMemberError(true);
        } else {
            const list = { member, leaveType, status };
            console.log(list);
            setMemberError(false); // Reset the error state
            handleClose(); // Close the dialog
        }
    };

    return (
        <div>
            <React.Fragment>
                <Stack direction="row" spacing={2}>
                    <Button variant="contained" onClick={handleClickOpen} sx={{ backgroundColor: 'blue', '&:hover': { backgroundColor: 'orange' }, textAlign: 'center', marginLeft: '10px' }}><AddIcon />Add Leaves</Button>
                </Stack>
                <Dialog
                    fullScreen
                    open={open}
                    onClose={handleClose}
                    TransitionComponent={Transition}
                    PaperProps={{
                        style: {
                            backgroundColor: 'grey',
                        },
                    }}
                >

                    <AppBar sx={{ position: 'relative' }}>
                        <Toolbar>
                            <IconButton
                                edge="start"
                                color="inherit"
                                onClick={handleClose}
                                aria-label="close"
                            >
                                <CloseIcon />
                            </IconButton>
                            <Typography sx={{ ml: 2, flex: 1 }} component="div">
                                New Leave
                            </Typography>

                        </Toolbar>

                    </AppBar>
                    <Box sx={{ padding: "30px", margin: "20px", backgroundColor: "white" }}>

                        <Typography sx={{ ml: 2, flex: 1, color: 'black', fontSize: "20px" }} component="div">
                            Assign Leave
                        </Typography>
                        <hr sx={{ color: 'grey'}} />

                        <Grid container spacing={2} sx={{ marginTop: "20px", padding: "20px" }}>
                            <Stack direction="row" sx={{ width: '100%' }}>
                                <Grid item xs={12} md={4} sm={4} sx={{ paddingRight: { xs: 0, sm: 2 } }}>
                                    <Stack direction="column">
                                        <Typography variant="p" gutterBottom style={{ color: 'black' }}>
                                            Choose Member <span style={{ color: 'red' }}>*</span>
                                        </Typography>
                                        <Select
                                            value={member}
                                            autoFocus
                                            onChange={(e) => setMember(e.target.value)}
                                            displayEmpty
                                            inputProps={{ 'aria-label': 'Without label' }}
                                            sx={{
                                                border: '1px solid black',
                                                boxSizing: 'border-box',
                                                '& .MuiOutlinedInput-root': {
                                                    borderColor: 'black !important',
                                                    '&:hover fieldset': {
                                                        borderColor: 'black !important',
                                                    },
                                                    '&.Mui-focused fieldset': {
                                                        borderColor: 'black !important',
                                                    },
                                                },
                                                color: "black"
                                            }}
                                        >
                                            <MenuItem disabled value="">
                                                <em>---</em>
                                            </MenuItem>
                                            <MenuItem value={Andrew}>Andrew</MenuItem>
                                            <MenuItem value={Bohr}>Bohr</MenuItem>
                                            <MenuItem value={Ceaser}>Ceaser</MenuItem>
                                        </Select>
                                        {memberError && <Typography variant="caption" style={{ color: 'red' }}>Please select a member</Typography>}
                                    </Stack>
                                </Grid>
                                <Grid item xs={12} md={4} sm={4} sx={{ paddingRight: { xs: 0, sm: 2 } }}>
                                    <Stack direction="column">
                                        <Typography variant="p" gutterBottom style={{ color: 'black' }}>
                                            Leave Type<span style={{ color: 'red' }}>*</span>
                                        </Typography>
                                        <Select
                                            value={leaveType}
                                            autoFocus
                                            onChange={(e) => setLeaveType(e.target.value)}
                                            displayEmpty
                                            inputProps={{ 'aria-label': 'Without label' }}
                                            sx={{
                                                border: '1px solid black',
                                                boxSizing: 'border-box',
                                                '& .MuiOutlinedInput-root': {
                                                    borderColor: 'black !important',
                                                    '&:hover fieldset': {
                                                        borderColor: 'black !important',
                                                    },
                                                    '&.Mui-focused fieldset': {
                                                        borderColor: 'black !important',
                                                    },
                                                },
                                                color: "black"
                                            }}
                                        >
                                            <MenuItem disabled value="">
                                                <em>---</em>
                                            </MenuItem>
                                            <MenuItem value={Casual}>Casual</MenuItem>
                                            <MenuItem value={Sick}>Sick</MenuItem>
                                            <MenuItem value={Earned}>Earned</MenuItem>
                                        </Select>
                                    </Stack>
                                </Grid>
                                <Grid item xs={12} md={4} sm={4} sx={{ paddingRight: { xs: 0, sm: 2 } }}>
                                    <Stack direction="column">
                                        <Typography variant="p" gutterBottom style={{ color: 'black' }}>
                                            Status
                                        </Typography>
                                        <Select
                                            value={status}
                                            autoFocus
                                            onChange={(e) => setStatus(e.target.value)}
                                            displayEmpty
                                            inputProps={{ 'aria-label': 'Without label' }}
                                            sx={{
                                                border: '1px solid black',
                                                boxSizing: 'border-box',
                                                '& .MuiOutlinedInput-root': {
                                                    borderColor: 'black !important',
                                                    '&:hover fieldset': {
                                                        borderColor: 'black !important',
                                                    },
                                                    '&.Mui-focused fieldset': {
                                                        borderColor: 'black !important',
                                                    },
                                                },
                                                color: "black"
                                            }}
                                        >
                                            <MenuItem disabled value="">
                                                <em>---</em>
                                            </MenuItem>
                                            <MenuItem value={Approved}>Approved</MenuItem>
                                            <MenuItem value={Pending}>Pending</MenuItem>
                                        </Select>
                                    </Stack>
                                </Grid>
                            </Stack>
                        </Grid>

                        <Grid container sx={{ padding: "20px" }}>
                            <Stack direction="row" spacing={2}>
                                <Grid item xs={12} md={4} sm={4} sx={{ paddingRight: { xs: 0, sm: 2 } }}>
                                    <Stack direction="column">
                                        <Typography variant="p" gutterBottom style={{ color: 'black' }}>
                                            Select Duration
                                        </Typography>
                                        <FormControl>
                                            <RadioGroup
                                                onChange={(e) => setDuration(e.target.value)}
                                                row
                                                aria-labelledby="demo-row-radio-buttons-group-label"
                                                name="row-radio-buttons-group"
                                            >
                                                <FormControlLabel value="fullDay" control={<Radio sx={{ color: "grey", '&.Mui-checked': { color: "#430A5D", }, }} />} label="full Day" style={{ color: "black" }} />
                                                <FormControlLabel value="multiple" control={<Radio sx={{ color: "grey", '&.Mui-checked': { color: "#430A5D", }, }} />} label="Multiple" style={{ color: "black" }} />
                                                <FormControlLabel value="halfDay" control={<Radio sx={{ color: "grey", '&.Mui-checked': { color: "#430A5D", }, }} />} label="Half Day" style={{ color: "black" }} />
                                                <FormControlLabel value="secondDay" control={<Radio sx={{ color: "grey", '&.Mui-checked': { color: "#430A5D", }, }} />} label="Second Half" style={{ color: "black" }} />
                                            </RadioGroup>
                                        </FormControl>
                                    </Stack>
                                </Grid>
                                <Grid item xs={12} md={4} sm={4} sx={{ paddingRight: { xs: 0, sm: 2 } }}>
                                    <Stack direction="column">
                                        <Typography variant="p" gutterBottom style={{ color: 'black' }}>
                                            Date
                                        </Typography>
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DemoContainer
                                                components={['DatePicker']}
                                                onChange={(e) => handleDateChange(e.target.value)}
                                                sx={{ marginTop: "-10px" }}
                                            >
                                                <DatePicker
                                                    value={date}
                                                    onChange={handleDateChange}
                                                    sx={{
                                                        border: '1px solid black',
                                                        '& .MuiOutlinedInput-root': {
                                                            borderColor: 'black !important',
                                                            color: "black !important",
                                                            '&:hover fieldset': {
                                                                borderColor: 'black !important',
                                                            },
                                                            '&.Mui-focused fieldset': {
                                                                borderColor: 'black !important',
                                                            },
                                                        },
                                                        '& .MuiSvgIcon-root': {
                                                            color: 'black',
                                                        },
                                                    }}
                                                >
                                                    {/* TextField component now becomes a child of DatePicker */}
                                                    {(params) => (
                                                        <TextField
                                                            {...params}
                                                            variant="outlined"
                                                        // Add any additional TextField props if needed
                                                        />
                                                    )}
                                                </DatePicker>
                                            </DemoContainer>

                                        </LocalizationProvider>
                                    </Stack>
                                </Grid>
                            </Stack>
                        </Grid>
                        <Grid container sx={{ padding: "20px" }}>
                            <Stack direction="column" sx={{ width: "100%" }}>
                                <Typography variant="p" gutterBottom style={{ color: 'black' }}>
                                    Reason for Absence<span style={{ color: 'red' }}>*</span>
                                </Typography>
                                <textarea
                                    id="reason"
                                    value={reason}
                                    onChange={(e) => setReason(e.target.value)}
                                    style={{
                                        border: '1px solid black',
                                        borderRadius: '4px',
                                        padding: '10px',
                                        width: '100%',
                                        boxSizing: 'border-box',
                                        minHeight: '100px' // Set your desired minimum height
                                    }}
                                />
                            </Stack>
                        </Grid>
                        <Grid container sx={{ padding: "20px" }}>
                            <Stack direction="column" sx={{ width: "100%" }}>
                                <Typography variant="p" gutterBottom style={{ color: 'black' }}>
                                    Add File<span style={{ color: 'red' }}>*</span>
                                </Typography>
                                <label htmlFor="file-upload"
                                    style={{
                                        cursor: 'pointer', color: 'black',
                                        border: '1px solid black',
                                        borderRadius: '4px',
                                        padding: '10px',
                                        width: '100%',
                                        boxSizing: 'border-box',
                                        minHeight: '100px', // Set your desired minimum height
                                        backgroundColor: 'lightgray', // Change background color to light gray
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                    }}>
                                    Upload File
                                </label>
                                <input
                                    onChange={handleFileChange} // Call handleFileChange when file changes
                                    type="file"
                                    id="file-upload"
                                    style={{ display: 'none' }}
                                />
                                {fileName && <div style={{ color: 'black' }}>{fileName}</div>}
                            </Stack>
                        </Grid>


                        <Button variant="contained" autoFocus color="inherit" onClick={handleSave} sx={{ backgroundColor: 'blue', '&:hover': { backgroundColor: 'orange' }, textAlign: 'center', margin: '25px' }}>
                            Save
                        </Button>

                    </Box>
                </Dialog>

            </React.Fragment>

        </div >


    );
}

